import { getBenefitVersion } from '@/api/coupon/index.js'

export default {
  path: '/c/coupon',
  name: 'coupon',
  meta: {
    requiresAuth: true,
    title: '券包'
  },
  component: () => import(/* webpackChunkName: "coupon" */ '@/page/c/coupon/index.vue'),
  children: [
    {
      path: 'activity',
      name: 'activity',
      meta: {
        requiresAuth: true,
        title: '活动页'
      },
      component: () => import(/* webpackChunkName: "coupon" */ '@/page/c/coupon/activity.vue')
    },
    // 老的优惠券包
    {
      path: 'detail',
      name: 'detail',
      meta: {
        requiresAuth: true,
        title: '券包详情页'
      },
      beforeEnter: async (to, from, next) => {
        const res = await getBenefitVersion()
        if (res && res.data && res.data.version === '2') {
          // 进入新的券包
          next({
            path: '/c/coupon/newCoupon',
            query: to.query,
            replace:true 
          })
        } else {
          next()
        }
      },
      component: () => import(/* webpackChunkName: "coupon" */ '@/page/c/coupon/detail.vue')
    },
    {
      path: 'buy',
      name: 'couponBuy',
      component: () => import(/* webpackChunkName: "coupon" */ '@/page/c/coupon/buy.vue')
    },
    {
      path: 'payment',
      name: 'payment',
      component: () => import(/* webpackChunkName: "coupon" */ '@/page/c/coupon/payment.vue')
    },
    {
      path: 'newCoupon',
      name: 'newCoupon',
      meta: {
        title: '券包'
      },
      component: () => import(/* webpackChunkName: "newCoupon" */ '@/page/c/newCoupon/index.vue')
    }
  ]
}
