function UPDATE_IS_TOPGUN (state, payload) {
  state.isTopGun = payload
}

function UPDATE_SHOW_SHARE_BUTTON (state, payload) {
  state.showShareBtn = payload
}

function UPDATE_IS_SUPPORT_HURDLE (state, payload) {
  state.isSupportHurdle = payload
}

function UPDATE_GLOBALPARAMS (state, payload) {
  state.globalParams = payload
}

export default {
  UPDATE_IS_TOPGUN,
  UPDATE_SHOW_SHARE_BUTTON,
  UPDATE_IS_SUPPORT_HURDLE,
  UPDATE_GLOBALPARAMS
}
