import didiaxios from '@didi/dajax/dist/dajax'
import apiConfig from '@/common/js/apiConfig'

const ACT_API = apiConfig.BASE_API_ACT
const C_API = apiConfig.BASE_API_C

const RANK_INDEX = C_API + '/feed/topicIndexV4'

export const topicIndexV4 = function (data) {
  // return requestPOST("https://mock.xiaojukeji.com/mock/12440/feed/rankIndex_1681465246265", data)
  // return requestPOST("http://pre-c.didi-food.com/feed/rankIndex", data)
  return requestPOST(RANK_INDEX, data)
}

async function requestPOST (url, data) {
  let globalParams = await SodaBridge('getGlobalParams', 'badcase') || {}
  let paramsData = globalParams.data || {}
  return didiaxios.request({
    url: url,
    method: 'POST',
    emulateJSON: true,
    needEncrypt: true,
    params: { ...paramsData, ...data.data },
    body: { ...paramsData, ...data.data }
  }).then(res => (res.data)).catch((err) => console.log(err))
}