class Sim {
  isSim() {
    return location.host.startsWith('sim')
  }

  getLocationOrigin() {
    return location.origin
  }

  getBaseUrl() {
    return this.getLocationOrigin().replace('.act', '')
  }

  getSimCApi() {
    return `${this.getBaseUrl()}/c-api`
  }

  getSimActApi() {
    return `${this.getBaseUrl()}/act-api`
  }

  getSimDApi() {
    return `${this.getBaseUrl()}/d-api`
  }

  getSimFulfillmentApi() {
    return `${this.getBaseUrl()}/fulfillment-api`
  }
}

export default new Sim()
