import i18n from '@/common/js/i18n'

export default {
  path: '/d',
  name: 'xtd',
  meta: {
    requiresAuth: true,
    title: i18n.gettext('X推D')
  },
  component: () => import(/* webpackChunkName: "xtd" */ '@/page/d/xtd/index.vue'),
  children: [
    {
      path: '/d',
      redirect: '/d/index'
    },
    {
      path: 'btdlandingpage',
      name: 'btdLandingPage',
      meta: {
        requiresAuth: true,
        title: i18n.gettext('推荐骑手')
      },
      component: () => import(/* webpackChunkName: "xtd" */ '@/page/d/xtd/btdLandingPage.vue')
    },
    {
      path: 'landingPage',
      name: 'landingPage',
      meta: {
        requiresAuth: true,
        title: i18n.gettext('推荐骑手')
      },
      component: () => import(/* webpackChunkName: "xtd" */ '@/page/d/xtd/landingPageV2.vue')
    },
    {
      path: 'noActivity/',
      name: 'noActivity',
      meta: {
        requiresAuth: true,
        title: i18n.gettext('推荐骑手')
      },
      component: () => import(/* webpackChunkName: "xtd" */ '@/page/d/xtd/noActivity.vue')
    },
    {
      path: 'rewardList',
      name: 'rewardList',
      meta: {
        requiresAuth: true,
        title: i18n.gettext('我的奖励')
      },
      component: () => import(/* webpackChunkName: "xtd" */ '@/page/d/xtd/rewardList.vue')
    },
    {
      path: 'detailRules',
      name: 'detailRules',
      meta: {
        requiresAuth: true,
        title: i18n.gettext('推荐骑手')
      },
      component: () => import(/* webpackChunkName: "xtd" */ '@/page/d/xtd/detailRules.vue')
    },
    {
      path: 'rewardDetail',
      name: 'rewardDetail',
      meta: {
        requiresAuth: true,
        title: i18n.gettext('我的奖励')
      },
      component: () => import(/* webpackChunkName: "xtd" */ '@/page/d/xtd/rewardDetail.vue')
    }
  ]
}
