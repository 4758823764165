<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
import '@didi/soda-c-coupon/lib/coupon.css';
import i18n from '@/common/js/i18n'
import { getLocaleBrandText } from '@/common/js/utils'
import { sprintf } from '@didi/gettext'

import { GET_ENTRY_URL_INFO } from '@/common/js/tools'
import trackEvent from '@/common/js/track'

// 设置页面meta标签的content属性
const descriptionProp = document.querySelector("meta[property='og:description']")
const descriptionName = document.querySelector("meta[name='description']")
const descriptionItem = document.querySelector("meta[itemprop='description']")
const title = document.querySelector("meta[property='og:title']")

descriptionProp.setAttribute('content', i18n.gettext('为你的第一单领取优惠'))
descriptionName.setAttribute('content', i18n.gettext('为你的第一单领取优惠'))
descriptionItem.setAttribute('content', i18n.gettext('为你的第一单领取优惠'))
title.setAttribute('content', sprintf(i18n.gettext('%s'), getLocaleBrandText('brand_txt_2')))

export default {
  name: 'App',
  mounted () {
    this.init()
  },
  methods: {
    init () {
      const attrs = GET_ENTRY_URL_INFO()
      trackEvent({
        eventId: 'sailing_c_x_entry_sw',
        eventLabel: 'act项目入口埋点',
        attrs
      })
    }
  }
}
</script>

<style lang="scss">
@import "~@/assets/styles/main.scss";
// @import "~@/assets/styles/var";
// img[lazy=loaded],img[lazy=loading],img[lazy=error] {
//   @include big-head-pic-placeholder();
//   background-size: 60% auto!important;
// }
  
.pad-header {
  padding: 23.4375px 35.15625px 17.578125px 35.15625px;
  border-bottom: 0.5859375px solid #E5E5E5;
  font-size: 14.0625px;
  color: #333;
  display: flex;
  justify-content: space-between;
}
html{
  height: 100%;
}
body{
  height: 100%;
  margin: 0;
}
.full-page{
  width: 100vw;
  height: 100vh;
}
#app{
  height: 100%;
  /* overflow: hidden; */
  /* background-color: #f3f4f5; */
}
.loading-wrap {
  width: 100%;
  height: 100%;
  overflow: hidden;
  
}
.loading-wrap .rich-loading {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%)
}
</style>
