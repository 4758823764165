
<template>
    <div class="no-login">
      <img src="@/assets/images/abnormal/noLogin.png" alt="" />
      <div class="title">{{ $t("FoodC_c_Login_for_puGR") }}</div>
      <div class="desc">{{ $t("FoodC_c_Please_log_pWNK") }}</div>
      <div class="btn" @click="goLogin">
        <rich-button size="medium">{{ $t("FoodC_c_Sign_in_gRWz") }}</rich-button>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    methods:{
      goLogin() {
        this.SodaBridge('requestLogin').then((response) => {
          // 登录后回调
          this.$emit('update-login')
        })
      }
    }
  };
  </script>
  
  <style lang="scss" scoped>
  .no-login {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    img {
      width: 300px;
      height: 300px;
      margin-bottom: 4px;
    }
    .title {
      font-family: "Aspira Demi";
      font-size: 32px;
      color: #000000;
      text-align: center;
      line-height: 45px;
      font-weight: 700;
      margin: 0 75px;
      margin-bottom: 13px;
    }
    .desc {
      margin: 0 75px;
      font-family: "Aspira Regular";
      font-size: 24px;
      color: #000000;
      text-align: center;
      font-weight: 400;
      margin-bottom: 32px;
    }
  }
  </style>
  