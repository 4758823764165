import { getGlobalParams } from '@/common/js/bridge'
import { isEmpty } from 'lodash-es'

// 涉及到版本校验的 各种情况 map列表 （后续过多抽离文件）
export const CHECK_VERSION_MAP = {
  // 支持收银台2.0的版本 global独立端2.0.42  global嵌入端7.3.80
  CASHIER_DESK_VERSION: {
    // DiDi
    Global_Food: '2.0.42',
    Global_Travel: '7.3.80',
    // 99
    BR_Food: '2.0.12',
    BR_Travel: '6.26.6',
    // key
    KEY: 'CASHIER_DESK_VERSION',
  },
  CHANGE_PAYMENT_VERSION: {
    // DiDi
    Global_Food: '2.0.58',
    Global_Travel: '7.4.12',
    // 99
    BR_Food: '2.0.12',
    BR_Travel: '6.26.6',
    // key
    KEY: 'CHANGE_PAYMENT_VERSION',
  },
  // 能订阅的版本，
  CAN_SUBSCRIBE_VERSION: {
    Global_Food: '2.0.52',
    Global_Travel: '7.4.0',
    // key
    KEY: 'CAN_SUBSCRIBE_VERSION',
  },
  // 能支持跳转的版本
  CAN_JUMP_VERSION: {
    Global_Food: '3.0.6',
    Global_Travel: '7.4.52',
    // key
    KEY: 'CAN_JUMP_VERSION',
  },
  CAN_PLAN_JUMP_VERSION: {
    Global_Food: '3.0.04',
    Global_Travel: '7.4.48',
    // key
    KEY: 'CAN_PLAN_JUMP_VERSION',
  },
  // 兼容安卓非SA侧边栏进入会员的直跳
  RIDE_SIDE_BAR_JUMP_VERSION: {
    Global_Food: '3.0.22',
    Global_Travel: '7.4.84',
    // key
    KEY: 'RIDE_SIDE_BAR_JUMP_VERSION',
  },
}
function checkAppType(globalParams) {
  const typeMap = {
    brand: {
      2: 'Global',
      3: 'BR',
    },
    terminal: {
      1: 'Food',
      2: 'Travel',
    },
  }
  if (globalParams) {
    const { brand, terminalType } = globalParams
    return `${typeMap.brand[brand]}_${typeMap.terminal[terminalType]}`
  }
  return 'unknown'
}

export function judgeVersion(globalParams, checkType) {
  const appType = checkAppType(globalParams)
  if (appType === 'unknown') {
    console.error('unknown app type')
    return false
  }
  const { appVersion } = globalParams
  return !checkIsOldVersion(appVersion, checkType[appType])
}

/**
 * 检查当前版本是否为旧版本，版本号需符合 x.x.x 格式
 * @param {*} curVersion 当前版本号
 * @param {*} baseVersion 基准版本号
 */
function checkIsOldVersion(curVersion, baseVersion) {
  const curVersionArr = curVersion?.split('.')
  const baseVersionArr = baseVersion?.split('.')

  const minL = Math.min(curVersionArr?.length, baseVersionArr?.length)
  let pos = 0
  let diff = 0
  while (pos < minL) {
    diff = parseInt(curVersionArr[pos]) - parseInt(baseVersionArr[pos])
    if (diff !== 0) {
      break
    }
    pos++
  }

  return diff < 0
}

export async function isVersionSupport(checkType) {
  // 获取当前设备的公共参数
  const res = await getGlobalParams()
  const globalParams = res || {}
  if (isEmpty(globalParams)) {
    return false
  }
  return judgeVersion(globalParams, checkType)
}

export async function isVersionSupportMap(checkVersionMap, isDev) {
  // 获取当前设备的公共参数
  const res = await getGlobalParams()
  const globalParams = res || {}
  // 如果是非开发环境，且有无值的时候，返回false
  if (isEmpty(globalParams) && !isDev) {
    return false
  }
  const allVersionJudge = {}
  // eslint-disable-next-line array-callback-return
  Object.keys(checkVersionMap).map(item => {
    // 开发环境直接放开所有版本限制
    allVersionJudge[item] = isDev ? true : judgeVersion(globalParams, checkVersionMap[item])
  })
  return allVersionJudge
}
