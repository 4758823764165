<!--
 * @Author: your name
 * @Date: 2021-09-16 17:50:03
 * @LastEditTime: 2022-01-17 16:10:06
 * @LastEditors: your name
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /soda-act/src/page/index.vue
-->
<template>
    <router-view></router-view>
</template>

<script>
export default {
  name: 'index'
}
</script>
