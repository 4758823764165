import { util } from '@didi/soda-common'
import OmegaTracker from '@didi/omega-tracker'
const { env } = util

const hostname = location.hostname
const is99App = !!hostname.match(/99app/)
const country = is99App ? 'BR' : 'MX'
let appKey

if (country === 'BR') {
  appKey = 'omegabc6aa63ce8'
} else if (country === 'MX') {
  appKey = 'omegaaf2e629a88'
} else {
  appKey = 'omegaaf2e629a88'
}

const Omega = OmegaTracker.getTracker({
  appKey,
  uploadHost: 'omgup.didiglobal.com',
  browserRouterEnable: true,
  autoClick: false,
  autoPosition: false,
  // jsErrorFilters: [
  //   {
  //     message: /^Script error.$/,
  //   },
  // ],
})

window.Omega = Omega

// bridge获取埋点公共参数
async function getCommonParams () {
  let globalParams = {}
  // 端内请求从bridge获取埋点公共参数
  if (env.inNative) {
    // 检查getGlobalParam是否可用，在旧版嵌入端是不可用的
    if (Soda.getGlobalParams) {
      const res = await SodaBridge('getGlobalParams', 'badcase') || {}
      globalParams = res.data
    }
  }
  return {
    'user_uid': globalParams.uid,
    'user_phone': globalParams.phone,
    'user_osVersion': globalParams.osVersion,
    'user_clientType': globalParams.clientType,
    'user_deviceType': globalParams.deviceType,
    'pub_relation_id': globalParams.relationId || -999
  }
}

export default async function trackEvent ({eventId = '', eventLabel = '', attrs = {}, callback = () => {}}) {
  let globalParams = await getCommonParams()
  if (eventId === 'tone_p_x_login_success') {
    try {
      window.Omega.setTelephone && window.Omega.setTelephone(attrs.phone)
    } catch (e) {}
  }
  window.Omega.trackEvent && window.Omega.trackEvent(
    eventId,
    eventLabel,
    {
      ...attrs,
      ...globalParams
    },
    null,
    res => {
      callback(res)
    }
  )
}
