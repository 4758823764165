<template>
     <Popup :isShow="shows" :title="$t(title)" @clickMask="shows = false" class="popup-box" :zIndex="zIndex">
      <div class="popup-wrapper" slot="content">
        <div class="rules-wrapper">
            <div v-for="(item, index) in rules" :key="index" class="rule-list">
              <div class="rule-title" v-if="item.title">{{ item.title }}</div>
              <div class="rule-desc" v-for="(desc, idx) in item.content" :key="idx">{{ desc }}</div>
            </div>
        </div>
       <div class="rule-btn">
        <rich-button @click="closeDialog">{{ getBtnName }}</rich-button>
       </div>
      </div>
    </Popup>
</template>

<script>
import Popup from '@/components/vant-popup'

export default {
    name:'rules',
    model: {
        prop: 'showTips',
        event: 'showsChange',
    },
    computed:{
        shows: {
            get: function() {
                return this.showTips;
            },
            set: function(value) {
                this.$emit('showsChange', value);
            },
        },
        getBtnName() {
          if(this.isJumpLink) {
            return this.$t('FoodC_url_reaV_LXaO')
          }
          return this.$t('FoodC_shoprank_Qwab_yWBb')
        }
    },
    components: {
        Popup,
    },
    props:{
        zIndex:{
          type: String,
          default: '100'
        },
        title:{
          type:String,
          default:'FoodC_page_eUPd_sDHg'
        },
        rules:{
            type:Array,
            default:()=>[]
        },
        isJumpLink:{
            type: Boolean,
            default: false,
        },
        showTips: {
            type: Boolean,
            default: false,
            required: false,
        },
    },
    methods:{
      closeDialog(){
        this.shows = false
        this.$emit('closeDialog')
      }
    }
 }
</script>

<style lang="scss" scoped>

// 规则样式开始------------
.popup-wrapper{
  height: 100%;
  overflow-y :auto;
  display: flex;
  flex-direction: column;
  margin-top :32px
}
.rules-wrapper{
  overflow:auto;
  flex:1
}
.rule-list {
  .rule-title{
    padding: 40px 0;
    font-family: DiDiSans-Pro-Bold;
    font-size: 36px;
    color: #000000;
    line-height: 36px;
  }
  .rule-desc{
    opacity: 0.6;
    font-family: DiDiSans-Pro-Regular;
    font-size: 28px;
    color: #000000;
    line-height: 34px;
    margin-bottom: 32px;
  }
}
.rule-btn{
  padding: 48px 0  20px 0
}
// 规则样式结束------------
::v-deep .wrapper {
  .half-title{
    span {
      margin-left: 0;
    }
  }
}
</style>