<template>
  <div class="subscribe-loading">
    <ImmersionHeader
      :isShowBackBtn="isShowBackBtn"
      :isShowRightButton="true"
      :rightText="$t('FoodC_subscription_Wlpe_bBkf')"
      :title="brandName"
      :blackTheme="true"
      :titleStyle="titleStyle"
      @BackCK="BackCK"
      @rightBtnCK="rightBtnCK"
    />
    <div class="loading-tips">
      {{ $t('FoodC_subscription_Jnyy_Zokk', { didipackbrand: brandName }) }}
    </div>
  </div>
</template>

<script>
import ImmersionHeader from '@/components/immersionHeader/index.vue'
import { mapState } from 'vuex'
import { HISTORY_PAGE } from '../../common/enumsUtil.js'
import { membershipLandingCk } from '../../trackEvent.js'
export default {
  components: {
    ImmersionHeader,
  },
  data() {
    return {
      titleStyle: {
        color: '#000000',
        alpha: 1,
        queryData: {},
      },
    }
  },
  computed: {
    ...mapState(['brandName']),
    isShowBackBtn() {
      return !this.isFromTab
    },
    isFromTab() {
      // 从底部tab页进入
      return (
        this.params?.from === 'tab' ||
        this.params?.from === 'C_Bottom_Nav_A_1_0' ||
        this.params?.from === 'C_Bottom_Nav_B_1_0'
      )
    },
  },
  mounted() {
    this.queryData = this.$route.query
  },
  methods: {
    BackCK() {
      this.SodaBridge('closePage')
    },
    rightBtnCK() {
      membershipLandingCk({
        benefit_id: this.queryData?.benefitId,
        coupon_package_status: this.queryData?.couponPackageStatus,
        click_type: this.queryData?.clickType,
        benefit_type: this.queryData?.benefitType,
        add_coupon_list: this.queryData?.addCouponList,
        from: this.queryData?.from,
      })
      const origin = window.location.origin
      const url = origin + HISTORY_PAGE
      window.SodaBridge('openPage', {
        target: 'blank',
        url,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.subscribe-loading {
  height: 100%;
  background-image: url('../../../../assets/images/member/loading_bg.png');
  background-size: 750px 859px;
  background-repeat: no-repeat;

  .loading-tips {
    padding-top: 939px;
    font-family: DiDiSans-Pro-Bold;
    font-size: 48px;
    color: #000000;
    line-height: 56px;
    margin: 0 40px;
  }
}
</style>
