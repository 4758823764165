/**
 * 品牌区分占位图
 */
export const PLACEHOLDER_BG = {
  '99': {
    C_COUPON_PLACEHOLDER: 'https://img0.didiglobal.com/static/gstar/img/ePOAOeCMCu1617018080124.png'
  },
  'didi': {
    C_COUPON_PLACEHOLDER: 'https://img0.didiglobal.com/static/gstar/img/9gL6Dn8yqU1617018075562.png'
  }
}

// 品牌区分分享红包背景图
export const SHAREBOX_BG = {
  '99': 'https://img0.didiglobal.com/static/gstar/img/wzC2QtJJ001617037238810.png',
  'didi': 'https://img0.didiglobal.com/static/gstar/img/4IW1or29Yv1617037238793.png'
}
