// 品牌文案
const brandTextObj = {
  brand_txt_1: {
    'zh-CN': '滴滴',
    'en-US': 'DiDi',
    'es-MX': 'DiDi',
    'pt-BR': 'DiDi',
    'ja-JP': 'DiDi',
    'es-419': 'DiDi',
    'es-CO': 'DiDi'
  },
  brand_txt_2: {
    'zh-CN': '滴滴外卖',
    'en-US': 'DiDi Food',
    'es-MX': 'DiDi Food',
    'pt-BR': 'DiDi Food',
    'ja-JP': 'DiDi Food',
    'es-419': 'DiDi Food',
    'es-CO': 'DiDi Food'
  },
  brand_txt_3: {
    'zh-CN': '滴滴出行',
    'en-US': 'Didi Chuxing',
    'es-MX': 'Didi Chuxing',
    'pt-BR': 'Didi Chuxing',
    'ja-JP': 'Didi Chuxing',
    'es-419': 'Didi Chuxing',
    'es-CO': 'Didi Chuxing'
  },
  brand_txt_4: {
    'zh-CN': '滴滴出行app / 滴滴外卖app',
    'en-US': 'Didi Chuxing / DiDi Food app',
    'pt-BR': 'Didi Chuxing / DiDi Food app',
    'ja-JP': 'Didi Chuxing / DiDi Food app',
    'es-419': 'Didi Chuxing / DiDi Food app',
    'es-CO': 'Didi Chuxing / DiDi Food app',
    'es-MX': 'Didi Chuxing / DiDi Food app',
  }
}
export const brandText = {
  JP: brandTextObj,
  MX: brandTextObj,
  BR: {
    brand_txt_1: {
      'zh-CN': '99',
      'en-US': '99',
      'es-MX': '99',
      'pt-BR': '99',
      'ja-JP': '99',
      'es-419': '99',
      'es-CO': '99'
    },
    brand_txt_2: {
      'zh-CN': '99外卖',
      'en-US': '99 Food',
      'es-MX': '99 Food',
      'pt-BR': '99 Food',
      'ja-JP': '99 Food',
      'es-419': '99 Food',
      'es-CO': '99 Food'
    },
    brand_txt_3: {
      'zh-CN': '99',
      'en-US': '99',
      'es-MX': '99',
      'pt-BR': '99',
      'ja-JP': '99',
      'es-419': '99',
      'es-CO': '99'
    },
    brand_txt_4: {
      'zh-CN': '99 app/ 99外卖app',
      'en-US': '99 / 99 Food',
      'es-MX': '99 / 99 Food',
      'pt-BR': '99 / 99 Food',
      'ja-JP': '99 / 99 Food',
      'es-419': '99 / 99 Food',
      'es-CO': '99 / 99 Food'
    }
  },
  CR: brandTextObj,
  CO: brandTextObj,
  DO: brandTextObj,
  CL: brandTextObj
}
