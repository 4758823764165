import i18n from '@/common/js/i18n'
export default {
  path: '/unsubscribe',
  name: 'unsubscribe',
  meta: {
    requiresAuth: true,
    title: i18n.gettext('退订')
  },
  component: () => import(/* webpackChunkName: "unsubscribe" */ '@/page/unsubscribe/index.vue'),
  children: [
    {
      path: '/unsubscribe',
      redirect: '/unsubscribe/index'
    },
    {
      path: '/unsubscribe/index',
      name: 'unsubscribeIndex',
      meta: {
        requiresAuth: true,
        title: i18n.gettext('邮件退订')
      },
      component: () => import(/* webpackChunkName: "unsubscribe" */ '@/page/unsubscribe/index.vue')
    },
    {
      path: '/unsubscribe/result',
      name: 'unsubscribeResult',
      meta: {
        requiresAuth: true,
        title: i18n.gettext('邮件退订结果')
      },
      component: () => import(/* webpackChunkName: "unsubscribe" */ '@/page/unsubscribe/result.vue')
    }
  ]
}
