import Vue from 'vue'
import Vuex from 'vuex'
import c from '@/store/c'
import d from '@/store/d'
import landingpage from '@/store/landingpage'
import newCouponsCenter from '@/store/newCouponsCenter'
import common from '@/store/common'
import createLogger from 'vuex/dist/logger'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production' && !process.env.ONLINE

export default new Vuex.Store({
  state: {
    ...c.state,
    ...d.state,
    ...landingpage.state,
    ...newCouponsCenter.state,
    ...common.state
  },
  getters: {
    ...c.getters,
    ...d.getters,
    ...common.getters
  },
  mutations: {
    ...c.mutations,
    ...d.mutations,
    ...landingpage.mutations,
    ...newCouponsCenter.mutations,
    ...common.mutations
  },
  actions: {
    ...c.actions,
    ...d.actions,
    ...landingpage.actions,
    ...common.actions
  },
  strict: debug,
  plugins: debug ? [createLogger()] : []
})
