/**
 * @Description: schema compare  递归判断一个obj是否满足定义的schema的定义要求  => 假装版强类型检查
 *               TODO:  array compare
 * @Author: zhangyugegeliuchen@didichuxing.com
 * @usage:
 * let demo = {
          token: '123',
          men: {
            color: 'pink',
            class: ['111', '2222'],
            type: ''

          }
        }
        let schema = {
          token: '',
          men: {
            age: 0,
            color: '',
            class: [],
            type: ''
          }
        }
        let res = Schema.compare(demo, schema)
        => false
 */

let Schema = {
  compare (itm, schema) {
    if (!itm || !Object.prototype.toString.call(itm) === '[object Object]') return false
    return this._objPassenger(schema, itm)
  },
  _objPassenger (obj, obj2) {
    let res = true
    for (let i in obj) {
      let left = obj[i]
      // 剪枝 无属性
      if (!obj2.hasOwnProperty(i)) return false
      let right = obj2[i]
      if (Object.prototype.toString.call(left) !== Object.prototype.toString.call(right)) {
        res = false
      } else {
        if (Object.prototype.toString.call(left) === '[object Object]') {
          res = res ? this._objPassenger(left, right) : res
        }
      }
      // 剪枝 存在false
      if (!res) return false
    }
    return res
  }
}
export default Schema
