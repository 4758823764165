import didiaxios from '@didi/dajax/dist/dajax'
import apiConfig from '@/common/js/apiConfig'

const BASE_URL = apiConfig.BASE_API_ACT
// 获取用户任务
const GET_RECOMMEND_ACTIVITY = BASE_URL + '/act-api/api/Mission/getRecommendActivity'
// 获取推荐活动
const GET_MISSION_AGGREGATION = BASE_URL + '/act-api/api/Mission/GetMissionAggregation'
// 领取任务奖励
const RECEIVE_MISSION_REWARD = BASE_URL + '/act-api/api/Mission/receiveMissionReward'
// 获取规则接口
const GET_RULE_DESCRIPTION = BASE_URL + '/act-api/api/Mission/getRuleDescription'
// 获取规则接口
const GET_POPUP = BASE_URL + '/act-api/api/Mission/getPopup'

export const getRecommendActivity = function (data) {
  return requestPOST(GET_RECOMMEND_ACTIVITY, data)
}
export const getMissionAggregation = function (data) {
  return requestPOST(GET_MISSION_AGGREGATION, data)
}
export const receiveMissionReward = function (data) {
  return requestPOST(RECEIVE_MISSION_REWARD, data)
}
export const getRuleDescription = function (data) {
  return requestPOST(GET_RULE_DESCRIPTION, data)
}
export const getPopup = function (data) {
  return requestPOST(GET_POPUP, data)
}
async function requestPOST (url, data) {
  let globalParams = await SodaBridge('getGlobalParams', 'badcase') || {}
  let paramsData = globalParams.data || {}
  return didiaxios.request({
    url: url,
    method: 'POST',
    emulateJSON: true,
    needEncrypt: true,
    params: '',
    body: { ...paramsData, ...data }
  }).then(res => (res.data)).catch((err) => console.log(err))
}
