import didiaxios from '@didi/dajax/dist/dajax'
import apiConfig from '@/common/js/apiConfig'

const BASE_URL = apiConfig.BASE_API_C
const BASE_ACT_URL = apiConfig.BASE_API_ACT
// 券包列表
const POST_PRODUCT_LIST = BASE_URL + '/memberBenefit/productList'
// 券包购买创建订单
const CREAT_ORDER = BASE_URL + '/memberBenefit/create'
// const CREAT_ORDER = 'https://mock.xiaojukeji.com/mock/776/memberBenefit/createOrder'
// 订单详情
const ORDER_DETAIL = BASE_URL + '/order/detail'
// 获取卡信息
const GET_PAY_INFO = BASE_URL + '/pay/getPayInfo'
// 获取是去新券包还是老券包
const GET_BENEFIT_VERSION = BASE_ACT_URL + '/act-api/api/benefitgrowth/getBenefitVersion'

const GET_BENEFITS_FOR_C = BASE_ACT_URL + '/act-api/api/benefitgrowth/getBenefitsForC'

export const getBenefitsForC = function (data) {
  return requestPOST(GET_BENEFITS_FOR_C, data)
}

export const getBenefitVersion = function (data) {
  return requestPOST(GET_BENEFIT_VERSION, data)
}

export const getProductList = function (data) {
  return requestPOST(POST_PRODUCT_LIST, data)
}

export const createOrder = function (data) {
  return requestPOST(CREAT_ORDER, data)
}

export const getPayInfo = function (data) {
  return requestPOST(GET_PAY_INFO, data)
}

export const queryOrderStatus = function (data) {
  return requestPOST(ORDER_DETAIL, data)
}

async function requestPOST (url, data) {
  let globalParams = await SodaBridge('getGlobalParams', 'badcase') || {}
  let paramsData = globalParams.data || {}
  return didiaxios.request({
    url: url,
    method: 'POST',
    emulateJSON: true,
    needEncrypt: true,
    params: '',
    body: { ...paramsData, ...data }
  }).then(res => (res.data)).catch((err) => console.log(err))
}
