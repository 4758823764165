<template>
  <div>
    <van-popup v-model="shows" :close-on-click-overlay="false">
      <div class="box">
        <div class="head-img" :style="headImgStyle"></div>
        <div class="content">
          <div v-if="isShowPause" class="title">{{ $t('FoodC_pause_LiWX_icgn') }}</div>
          <div v-else-if="isShowJoin" class="title">{{ $t('FoodC_unsubscription_PWOD_DyeO') }}</div>
          <div v-else class="title">
            {{ $t('FoodC_V2_SvHk_RdVM', { didipackbrand: brandName }) }}
          </div>
          <!-- <div class="desc" v-html="descText"></div> -->
          <rich-button @click="shows = !shows">{{ $t('FoodC_V2_SSNq_tsZF') }}</rich-button>
        </div>
      </div>
    </van-popup>
  </div>
</template>
<script>
import Vue from 'vue'
import { Popup } from 'vant'
import { mapState } from 'vuex'
Vue.use(Popup)
export default {
  name: 'PaySuccessDialog',
  model: {
    prop: 'showTips',
    event: 'showsChange',
  },
  props: {
    showTips: {
      type: Boolean,
      default: false,
      required: false,
    },
    dialogData: {
      type: Object,
      default: () => {},
    },
    isShowJoin: {
      type: Boolean,
      default: false,
      required: false,
    },
    isShowPause: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  computed: {
    ...mapState(['brandName']),
    shows: {
      get: function () {
        return this.showTips
      },
      set: function (value) {
        this.$emit('showsChange', value)
      },
    },
    descText() {
      const date = `<span class="desc-bold">${this.dialogData?.date}</span>`
      const money = `<span class="desc-bold">${this.dialogData?.price}</span>`
      return this.$t('FoodC_V2_RTeI_mEFs', { date, money })
    },
    headImgStyle() {
      if (!this.dialogData?.iconUrl) return
      return `
            background-image: url(${this.dialogData?.iconUrl}); background-repeat:no-repeat
            `
    },
  },
}
</script>

<style lang="scss" scoped>
.van-popup {
  background: none;
}
.box {
  width: 580px;
}
.head-img {
  width: 100%;
  height: 330px;
  background: url('../../../../assets/images/member/pay_success_dialog_bg.png') no-repeat;
  background-size: 100% 100%;
  position: relative;
  z-index: 1;
}
.head-img-join {
  width: 100%;
  height: 330px;
  background: url('../../../../assets/images/member/join_success_new.png') no-repeat;
  background-size: 100% 100%;
  position: relative;
  z-index: 1;
}
.content {
  padding: 200px 32px 48px 32px;
  background: linear-gradient(#ffffff, #fff5f0);
  border-radius: 40px;
  position: relative;
  left: 0;
  top: -188px;
}
.title {
  font-family: DiDiSans-Pro-Bold;
  font-size: 40px;
  color: #000000;
  line-height: 56px;
  margin-bottom: 24px;
}
.desc {
  font-family: DiDiSans-Pro-Regular;
  font-size: 22px;
  color: #000000;
  line-height: 32px;
  margin-bottom: 44px;
  .desc-bold {
    font-family: DiDiSans-Pro-Bold;
  }
}
</style>
