<template>
  <div class="pauseRestoreBtn">
    <div class="btn-container">
      <rich-button @click="restoreSubscribe"> {{ $t('FoodC_pause_qsCr_nszu') }} </rich-button>
    </div>
  </div>
</template>
<script>
export default {
  name: 'PauseFooter',
  props: {},
  computed: {},
  methods: {
    restoreSubscribe() {
      this.$emit('goPauseRestore')
    },
  },
}
</script>
<style lang="scss" scoped>
.pauseRestoreBtn {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 8;
  width: 100%;
  background: #ffffff;
  padding: 36px 24px 45px 24px;
  box-sizing: border-box;
  border-radius: 40px 40px 0 0;
  box-shadow: 0px -10px 48px 0px rgba(0, 0, 0, 0.06);
  .rich-btn {
    height: 108px;
  }
}
</style>
