<template>
  <div class="step-box">
    <div class="step-time">
      <div class="time box1">{{ $t("FoodC_update_YSxw_ZNzh") }}</div>
      <div class="step-icon"></div>
      <div class="time box2">{{ $t("FoodC_update_PJJg_EEDD") }}</div>
      <div class="step-icon"></div>
      <div class="time box3">{{ $t("FoodC_update_VTVc_ZtNA") }}</div>
    </div>
    <div class="step-content">
      <div class="content box1">{{ $t("FoodC_update_lgoe_CKtm") }}</div>
      <div class="step-icon">
        <i class="icon-v3_outlined_pickup"></i>
      </div>
      <div class="content box2" v-if="upgradeRenewPrice.money1">
        {{ $t('FoodC_update_QaEe_FhNn', { money: upgradeRenewPrice.money1 }) }}
      </div>
      <div class="step-icon">
        <i class="icon-v3_outlined_pickup"></i>
      </div>
      <div class="content box3" v-if="upgradeRenewPrice.money2">
        {{ $t('FoodC_update_QaEe_FhNn', { money: upgradeRenewPrice.money2 }) }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["upgradeRenewPrice"],
};
</script>

<style lang="scss" scoped>
.step-box {
  padding: 28px 0;
}

.step-time {
  display: flex;
  margin-bottom: 4px;
}
.time {
  opacity: 0.4;
  font-family: DiDiSans-Pro-Medium;
  font-size: 22px;
  color: #000000;
}
.step-content {
  display: flex;
  align-items: center;
}
.content {
  font-family: DiDiSans-Pro-Bold;
  font-size: 28px;
  color: #000000;
}
.step-icon {
  width: 24px;
  transform: scaleX(-1) rotate(90deg);;

  i {
    opacity: 0.2;
    font-size: 24px;
  }
}
.box1{
    width: 148px;
    padding-left: 44px;
}
.box2{
    width: 213px;
    padding-left: 36px;
}

.box3{
    padding-left: 36px;
}
</style>
