<template>
  <transition name="fade">
    <van-overlay :show="isShow" @click.self="onMask" 
    :z-index="zIndex" :lock-scroll="false">
      <div class="wrapper" @click.stop>
        <transition name="slide-fade">
          <div :class="isInput ? 'hale-content-min' : 'half-content'" v-show="isShow">
            <div class="half-title">
              <i class="c-common_icon_closed" @click.self="onMask" />
              <span>{{ title }}</span>
            </div>
            <slot name="content"></slot>
          </div>
        </transition>
      </div>
    </van-overlay>
  </transition>
</template>
 
<script>
import Vue from 'vue';
import { Overlay } from 'vant';

Vue.use(Overlay);

export default {
  name: 'vantPopup',
  props: {
    zIndex:{
      type: String,
      default: '100'
    },
    isShow: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    isInput: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onMask () {
      this.$emit('clickMask')
    },
  }
}
</script>
 
<style lang="scss" scoped>
  .fade-enter, 
  .fade-leave-active{
    opacity: 1;
  }
  .fade-enter-active, 
  .fade-leave-active{
    transition: all .1s ease;
  }
  .slide-fade-enter-active,
  .slide-fade-leave-active {
    transition: all 0.3s ease;
  }
  .slide-fade-enter,
  .slide-fade-leave-to {
    transform: translateY(100%);
    transition: all 0.3s ease;
  }
   .mask {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9;
  }
  .half-content {
    box-sizing: border-box;
    position: fixed;
    width: 100%;
    left: 0;
    bottom: 0;
    // min-height: 500px;
    max-height: 92%;
    background: #fff;
    border-radius: 24px 24px 0 0;
    z-index: 10;
    padding: 48px 40px;
    display: flex;
    flex-direction: column;
  }
  .hale-content-min{
    height: 516px;
    box-sizing: border-box;
    position: fixed;
    width: 100%;
    left: 0;
    bottom: 0;
    background: #fff;
    border-radius: 24px 24px 0 0;
    z-index: 10;
    padding: 48px 40px;
  }
  .half-title{
    font-family: 'Aspira Medium';
    font-size: 36px;
    text-align: center;
    line-height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    
    i{
      position: absolute;
      left: 40px;
      font-size: 48px;
    }
    span {
      margin-left: 60px;
      flex: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis
    }
  }
</style>
