<template>
  <div class="specialty-pro" :style="{ 'padding-top': headerHeight + 'px' }">
    <div class="header-box">
      <div class="title">{{ pageInfo.headInfo.title }}</div>
      <div class="pack-enter" @click="goOldPack">
        <div class="text">
          {{ $t("FoodC_update_TDwr_XZVd", { brand2: DIDI_PACK }) }}
        </div>
        <i class="icon-v3_outlined_entrance"></i>
      </div>
    </div>
    <BenefitList
      :benefitInfo="pageInfo.benefitInfo"
      :noBuy="true"
      @handleClick="goDetailed"
      @goSettingBir="goSettingBir"
      @getBirthdayCoupons="getBirthdayCoupons"
      @goCoupons="goCoupons"
    />
    <div class="line-grey"></div>
    <SaleInfo :noBuy="true" :saleInfo="pageInfo.saleInfo" />
    <div class="rules" v-html="getRuleDesc" @click="openRule($event)"></div>
    <div class="footer">
      <FooterStep :upgradeRenewPrice="pageInfo.membership.upgradeRenewPrice"/>
      <div class="footer-btn" @click="freeUpgrade">
        {{ $t("FoodC_update_VNPd_BxNP", { brand1: this.brandName }) }}
      </div>
    </div>
  </div>
</template>

<script>
import BenefitList from "../benefitList/index.vue";
import SaleInfo from "../saleInfo/index.vue";
import FooterStep from "../footerStep/index.vue";
import { membershipLandingSw } from "../../trackEvent.js";
import { mapState } from "vuex";
import {
  BUY_COUPON_PACKAGE,
  MODULE_TYPE,
  DIDI_PACK,
  DIDI_FOOD_BRAND,
} from "../../common/enumsUtil";

export default {
  components: {
    BenefitList,
    SaleInfo,
    FooterStep
  },
  data() {
    return {
      DIDI_PACK,
    };
  },
  computed: {
    ...mapState(["brandName"]),
    getRuleDesc() {
      return this.$t("FoodC_update_wBxB_qtoB", {
        brand2: this.brandName,
        didifoodbrand: DIDI_FOOD_BRAND,
        money1: this.pageInfo?.membership?.upgradeRenewPrice?.money1,
        money2: this.pageInfo?.membership?.upgradeRenewPrice?.money2,
        rules: `<span class="rule">${this.$t(
          "FoodC_subscription_VPMP_seXT"
        )}</span>`,
      });
    },
  },
  props: {
    pageInfo: {
      type: Object,
      default: () => {},
    },
    headerHeight: {
      default: 0,
    },
    couponPackageStatus: {
      default: 0,
    },
  },
  mounted() {
    membershipLandingSw({
      coupon_package_status: this.couponPackageStatus, //  0：未开通； 1：已开通-且订阅；2:已开通-未订阅
      benefit_id: this.pageInfo?.membership?.benefitID,
      module_type: MODULE_TYPE.UPGRADE_BUTTON, // 1-右上角的历史记录,2-累计已省模块,3-会员权益购买按钮,4-加量包购买模块
      from: this.params?.sourceEntry || this.params?.fromPoint || "未知",
    });
  },
  methods: {
    // 详情弹窗
    goDetailed(data) {
      this.$emit("goDetailed", data);
    },
    // 设置生日
    goSettingBir() {
      this.$emit("goSettingBir");
    },
    // 领取生日券
    getBirthdayCoupons() {
      this.$emit("getBirthdayCoupons");
    },
    // 跳转到优惠券中心
    goCoupons() {
      this.$emit("goCoupons");
    },
    goOldPack() {
      this.$emit("goOldPack");
    },
    openRule(el) {
      const isRule = el.target.className === "rule";
      if (isRule) {
        this.$emit("openRule");
      }
    },
    freeUpgrade() {
      this.$emit("freeUpgrade");
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  font-family: DiDiSans-Pro-Bold;
  font-size: 64px;
  color: #000000;
  line-height: 68px;
}

.line-grey {
  height: 12px;
  background: #f7f7f7;
}

.header-box {
  padding: 36px 32px 48px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.desc-box {
  display: flex;
  justify-content: space-between;
}

.pack-enter {
  opacity: 0.6;
  font-family: DiDiSans-Pro-Regular;
  font-size: 24px;
  color: #000000;
  line-height: 32px;
  display: flex;

  .text {
    margin-right: 4px;
  }

  i {
    font-size: 28px;
  }
}

.rules {
  padding: 47px 32px 300px 32px;
  font-family: DiDiSans-Pro-Regular;
  font-size: 24px;
  color: rgba(0, 0, 0, 0.6);
  line-height: 32px;
}

::v-deep .rule {
  font-family: DiDiSans-Pro-Medium;
  font-size: 24px;
  color: #000000;
  line-height: 24px;
  text-decoration: underline;
}

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 268px;
  background: #ffffff;
  box-shadow: 0px -10px 40px 0px rgba(0, 0, 0, 0.04);
  border-radius: 40px 40px 0 0;
}

.footer-btn {
  width: 702px;
  height: 108px;
  background: #ff8040;
  border-radius: 32px;
  font-size: 32px;
  color: #ffffff;
  text-align: center;
  line-height: 108px;
  text-align: center;
  font-family: DiDiSans-Pro-Bold;
  margin: 0 auto;
}
</style>
