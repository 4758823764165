import i18n from '@/common/js/i18n'
export default {
  path: '/c',
  name: 'xtc',
  meta: {
    requiresAuth: true,
    title: i18n.gettext('X推C')
  },
  component: () => import(/* webpackChunkName: "xtc" */ '@/page/c/xtc/index.vue'),
  children: [
    {
      path: '/c',
      redirect: '/c/index'
    },
    {
      path: 'landingPage',
      name: 'landingPage',
      meta: {
        requiresAuth: true,
        title: i18n.gettext('落地页')
      },
      component: () => import(/* webpackChunkName: "xtc" */ '@/page/c/xtc/landingPage.vue')
    },
    {
      path: 'rewardList',
      name: 'rewardList',
      meta: {
        requiresAuth: true,
        title: i18n.gettext('推荐记录')
      },
      component: () => import(/* webpackChunkName: "xtc" */ '@/page/c/xtc/rewardList.vue')
    },
    {
      path: 'participate',
      name: 'participate',
      meta: {
        requiresAuth: true,
        title: i18n.gettext('推荐记录')
      },
      component: () => import(/* webpackChunkName: "xtc" */ '@/page/c/xtc/participate.vue')
    },
    {
      path: 'participateShare',
      name: 'participate',
      meta: {
        requiresAuth: true,
        title: i18n.gettext('推荐记录')
      },
      component: () => import(/* webpackChunkName: "xtc" */ '@/page/c/xtc/participate.vue')
    },
    {
      path: 'promoResult',
      name: 'promoResult',
      meta: {
        requiresAuth: true,
        title: '兑换结果'
      },
      component: () => import(/* webpackChunkName: "xtc" */ '@/page/c/xtc/promoResult.vue')
    }
  ]
}
