import * as xtc from './xtc/index'
import * as xtd from './xtd/index'
import * as landingpage from './landingpage/index'
import * as common from './common/index'
import * as coupon from './coupon/index'
import * as sharebox from './sharebox/index'
import * as newCouponsCenter from './newCouponsCenter/index'
import * as oldCustomer from './oldCustomer/index'
import * as couponCenter from './couponCenter/index'
import * as trending from './trending/index'
import * as topShop from './topShop/index'

const API = {
  ...common,
  ...xtc,
  ...xtd,
  ...landingpage,
  ...coupon,
  ...sharebox,
  ...newCouponsCenter,
  ...oldCustomer,
  ...couponCenter,
  ...trending,
  ...topShop
}

export default API
