<template>
    <div>
        <van-popup v-model="shows" :close-on-click-overlay="false">
            <div class="img" @click="goUrl">
                <img :src="dialogData.imgUrl" alt="">
            </div>
            <div class="close" @click="shows = !shows"><svg xmlns="http://www.w3.org/2000/svg" overflow="hidden" viewBox="0 0 1024 1024" style="width:100%;height:100%;vertical-align:middle;fill:#fff"><path fill-rule="evenodd" d="M557 523l86 85q8 8 7.5 21t-10 22q-9.5 9-22 10t-21.5-8l-85-85-85 85q-9 9-21.5 8t-22-10q-9.5-9-10-22t7.5-21l86-85-86-86q-8-8-7.5-20.5t10-22q9.5-9.5 22-10T427 392l85 85 85-85q9-8 21.5-7.5t22 10q9.5 9.5 10 22T643 437zm-45 437q-119 0-221-60-99-58-157-157-59-102-59-220.5T134 302q58-99 157-157 102-60 221-60t221 60q99 58 157 157 59 102 59 220.5T890 743q-58 99-157 157-102 60-221 60zm0-64q101 0 188-51 85-49 134-134 51-87 51-188.5T834 334q-49-84-134-134-87-51-188-51t-188 51q-85 50-134 134-51 87-51 188.5T190 711q49 85 134 134 87 51 188 51z"/></svg></div>
        </van-popup>
    </div>
</template>
<script>
import Vue from 'vue';
import { Popup } from 'vant';

Vue.use(Popup);
export default {
    name: 'MemberDialog',
    model: {
        prop: 'showTips',
        event: 'showsChange',
    },
    computed: {
        shows: {
            get: function () {
                return this.showTips;
            },
            set: function (value) {
                this.$emit('showsChange', value);
            },
        },
    },
    props: {
        showTips: {
            type: Boolean,
            default: false,
            required: false,
        },
        dialogData: {
            type: Object,
            default: () => { }
        }
    },
    methods:{
        goUrl() {
            if(this.dialogData?.redirect) {
                this.SodaBridge('openPage', {
                    target: 'blank',
                    url: this.dialogData?.redirect
                })
            }
        }
    }

}
</script>

<style lang="scss" scoped>
.van-popup{
    background: none;
}
.img{
    width: 580px;
    height: 774px;
    margin-bottom: 48px;
    img {
        width: 100%;
        height: 100%;
    }
}
.close{
    width: 64px;
    height: 64px;
    margin: 0 auto;
}
</style>