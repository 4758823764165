import didiaxios from '@didi/dajax/dist/dajax'
import i18nUtil from '@didi/i18n-util'
import { getLocale } from '@didi/gettext'
import apiConfig from '@/common/js/apiConfig'
const BASE_URL = apiConfig.BASE_API_D + '/eco-reward'
// X推D首页接口
const POST_ACQUISTION_INDEX = BASE_URL + '/acquisition/referral/xtdIndex'
// const POST_ACQUISTION_INDEX = 'https://mock.xiaojukeji.com/mock/12914/acquisition/referral/xtdIndex'
// X推D我参与的推荐活动信息接口
const POST_ACTIVITY_INFO = BASE_URL + '/acquisition/referral/xtdOverview'
// const POST_ACTIVITY_INFO = 'https://mock.xiaojukeji.com/mock/12914/acquisition/referral/xtdOverview'
// X推D进行中的推荐活动接口
const POST_ACTIVITY_GOING = BASE_URL + '/acquisition/referral/xtdOngoing'
// const POST_ACTIVITY_GOING = 'https://mock.xiaojukeji.com/mock/12914/acquisition/referral/xtdOngoing'
// X推D列表页已结束活动列表接口
const POST_ACTIVITY_FINISH_LIST = BASE_URL + '/acquisition/referral/xtdFinished'
// const POST_ACTIVITY_FINISH_LIST = 'https://mock.xiaojukeji.com/mock/12914/acquisition/referral/xtdOngoing_1650956227826'
// X推D活动详情接口
const POST_ACTIVITY_DETAIL = BASE_URL + '/acquisition/referral/xtdDetail'
// const POST_ACTIVITY_DETAIL = 'https://mock.xiaojukeji.com/mock/12914/acquisition/referral/xtdDetail'
// X推D规则详情
const POST_ACQUISTION_RULES = BASE_URL + '/acquisition/referral/xtdActivity'
// 我的奖励列表
const POST_ACQUISTION_REFERRAL_LIST = BASE_URL + '/acquisition/referral/xtdList'
// X推D首页接口 V2
const POST_ACQUISTION_INDEX_V2 = BASE_URL + '/acquisition/referral/xtdIndex2'
// const POST_ACQUISTION_INDEX_V2 = 'https://mock.xiaojukeji.com/mock/12914/acquisition/referral/xtdIndex2'
// X推D规则详情(23.03.28新)
const POST_ACQUISTION_RULES_DETAIL = BASE_URL + '/acquisition/referral/xtdRule'
// const POST_ACQUISTION_RULES_DETAIL = 'https://mock.xiaojukeji.com/mock/12914/acquisition/referral/xtdRule'
// 灰度接口控制新旧版本
const POST_VERSION_INFO = BASE_URL + '/common/version/info'

export const xtdAcquisitionConfig = function (data) {
  return requestPOST(POST_ACQUISTION_INDEX, data)
}
export const xtdActivityInfo = function (data) {
  return requestPOST(POST_ACTIVITY_INFO, data)
}
export const xtdActivityGoing = function (data) {
  return requestPOST(POST_ACTIVITY_GOING, data)
}
export const xtdActivityFinishList = function (data) {
  return requestPOST(POST_ACTIVITY_FINISH_LIST, data)
}
export const xtdActivityDetail = function (data) {
  return requestPOST(POST_ACTIVITY_DETAIL, data)
}
export const xtdVersionInfo = function (data) {
  return requestPOST(POST_VERSION_INFO, data)
}
export const xtdAcquisitionRules = function (data) {
  // 预处理 rules填充time和amount，再传给页面
  return requestPOST(POST_ACQUISTION_RULES, data).then((res) => {
    if (res && res.errno === 0 && res.data) {
      let tmp = objPassenger(res.data, [{
        propName: 'isAmountShow',
        callback: function (prop, elm) {
          if (prop === 1) {
            elm.subText += elm.amount_display && elm.amount_display.split('.')[0]
          }
          return elm
        }
      }, {
        propName: 'time_display',
        callback: function (prop, elm) {
          if (prop && Object.keys(prop).length > 0) {
            console.log(i18nUtil.getTimeFormat)
            const startTimeStr = i18nUtil.getTimeFormat({
              timestamp: prop.startTime && prop.startTime.timestamp * 1000,
              offset: prop.startTime && prop.startTime.offset,
              locale: getLocale(),
              format: 'YYYY.MM.DD'
            })
            const endTimeStr = i18nUtil.getTimeFormat({
              timestamp: prop.endTime && prop.endTime.timestamp * 1000,
              offset: prop.endTime && prop.endTime.offset,
              locale: getLocale(),
              format: 'YYYY.MM.DD'
            })
            elm.subText = `${startTimeStr} - ${endTimeStr}`
          }
          return elm
        }
      }])
      return tmp
    } else {
      throw res
    }
  })
}
//   obj递归遍历器，支持遍历时obj处理，数组传入
//   @format ： propsWthCallbackArr : {
//              propName: 'currency',
//              callback: () => {}
//            }
function objPassenger (obj, propsWthCallbackArr = []) {
  for (var index in obj) {
    let o = obj[index]
    propsWthCallbackArr.forEach((e) => {
      if (o && o.hasOwnProperty(e.propName)) {
        o = e.callback ? e.callback(o[e.propName], o) : o
      }
    })
    if (o && o.hasOwnProperty('children') && o['children'] !== []) o = {...o, children: objPassenger(o.children, propsWthCallbackArr)}
    obj[index] = o
  }
  return obj
}
export const xtdAcquisitionReferralList = function (data) {
  return requestPOST(POST_ACQUISTION_REFERRAL_LIST, data)
}

export const xtdAcquisitionConfigV2 = function (data) {
  return requestPOST(POST_ACQUISTION_INDEX_V2, data)
}

export const getXtdRulesDetail = function (data) {
  return requestPOST(POST_ACQUISTION_RULES_DETAIL, data)
}

function requestPOST (url, data) {
  return didiaxios.request({
    url: url,
    method: 'POST',
    emulateJSON: true,
    needEncrypt: true,
    params: '',
    body: data
  }).then(res => (res.data)).catch((err) => console.log(err))
}
