<template>
  <div
    class="footer-container"
    :class="{ 'can-subscribe': isCanSubscribe, 'footer-container-tab': fromTab }"
  >
    <div v-if="isShowStemPrice" class="step-price">
      <div v-html="getFirstStepPriceText"></div>
      <i class="icon-outlined_pickup"></i>
      <div v-html="getSecondStepPriceText"></div>
    </div>
    <div class="btn-container">
      <!-- 支付时显示气泡 -->
      <div class="bubble">
        <Bubble :bubbleContent="bubbleContent" />
      </div>
      <rich-button @click="goBuy">
        <div class="price">
          <div class="price-box">
            {{ btnText }}
            <div v-if="isShowDelPrice" class="del">{{ delPrice }}</div>
          </div>
        </div>
      </rich-button>
    </div>
  </div>
</template>
<script>
import Bubble from '@/components/bubble/index.vue'
import { mapState } from 'vuex'
import { memberShipCommOptV3 } from '../../common/enumsUtil.js'

export default {
  // eslint-disable-next-line vue/no-reserved-component-names
  name: 'Footer',
  components: {
    Bubble,
  },
  props: [
    // eslint-disable-next-line vue/require-prop-types
    'priceText',
    // eslint-disable-next-line vue/require-prop-types
    'productId',
    // eslint-disable-next-line vue/require-prop-types
    'price',
    // eslint-disable-next-line vue/require-prop-types
    'bubbleContent',
    // eslint-disable-next-line vue/require-prop-types
    'delPrice',
    // eslint-disable-next-line vue/require-prop-types
    'isCanSubscribe',
    // eslint-disable-next-line vue/require-prop-types
    'noSubscriptionStatus',
    // eslint-disable-next-line vue/require-prop-types
    'fromTab',
    // eslint-disable-next-line vue/require-prop-types
    'membership',
    // eslint-disable-next-line vue/require-prop-types
    'hasMarketingPrice',
    // eslint-disable-next-line vue/require-prop-types
    'pauseSubscriptionStatus',
  ],
  computed: {
    ...mapState(['brandName']),
    btnText() {
      // 能订阅
      if (this.isCanSubscribe) {
        // 未订阅
        if (this.noSubscriptionStatus || this.pauseSubscriptionStatus) {
          if (this.isExperiment1) {
            // 实验一
            if (this.hasMarketingPrice) {
              // 有营销价格
              return this.$t('FoodC_words_bshC_QpQz', {
                brand: this.brandName,
                money: ' ' + this.priceText,
              })
            }
            return this.$t('FoodC_words_vHza_gIgE', {
              brand: this.brandName,
              money: ' ' + this.priceText,
            })
          } else if (this.isExperiment2) {
            // 实验二
            return this.$t('FoodC_words_ikBq_XDxq', {
              brand: this.brandName,
              money: ' ' + this.priceText,
            })
          } else {
            // 对照组
            return (
              this.$t('FoodC_subscription_YJLt_vjMk', {
                didipackbrand: this.brandName,
              }) +
              ' ' +
              this.priceText
            )
          }
        } else {
          return (
            this.$t('FoodC_subscription_YJLt_vjMk', {
              didipackbrand: this.brandName,
            }) +
            ' ' +
            this.priceText
          )
        }
      }
      return this.$t('FoodC_C_Only_money_ZEKz', { money: this.priceText })
    },
    getMemberShipCommOptV3() {
      return this.membership?.testConfig?.memberShipCommOptV3 || 0
    },
    isExperiment0() {
      return this.getMemberShipCommOptV3 === memberShipCommOptV3.experiment0
    },
    isExperiment1() {
      return this.getMemberShipCommOptV3 === memberShipCommOptV3.experiment1
    },
    isExperiment2() {
      return this.getMemberShipCommOptV3 === memberShipCommOptV3.experiment2
    },
    isShowDelPrice() {
      if (this.isCanSubscribe) {
        return this.delPrice && this.isExperiment0
      }
      return this.delPrice
    },
    isShowStemPrice() {
      // 有营销价格，且是实验1或者实验2
      return this.hasMarketingPrice && (this.isExperiment1 || this.isExperiment2)
    },
    getFirstStepPriceText() {
      return this.$t('FoodC_words_MBwE_Wmhg', {
        money: `<span class="overbold">${this.priceText || ''}</span>`,
      })
    },
    getSecondStepPriceText() {
      return this.$t('FoodC_words_TYXi_ntMY', {
        money: `<span class="overbold">${this.membership?.renewPriceSplit?.display || ''}</span>`,
      })
    },
  },
  methods: {
    goBuy() {
      this.$emit('goBuy', { type: 'MEMBER' })
    },
  },
}
</script>
<style lang="scss" scoped>
.footer-container {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 8;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px -10px 48px 0px rgba(0, 0, 0, 0.06);
  padding: 36px 24px 71px 24px;
  box-sizing: border-box;
  border-radius: 40px 40px 0 0;
}

.btn-container {
  position: relative;
  .rich-btn {
    height: 108px;
  }
}

.bubble {
  position: absolute;
  right: 0;
  top: -20px;
}
.price {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  .price-box {
    position: relative;
    width: fit-content;
    height: fit-content;
    .del {
      position: absolute;
      left: 100%;
      bottom: 0;
      height: 22px;
      opacity: 0.8;
      font-family: DiDiSans-Pro-Bold;
      font-size: 22px;
      color: #ffffff;
      line-height: 22px;
      text-decoration: line-through;
      margin-left: 8.5px;
    }
  }
}

.footer-container-tab {
  padding: 32px 24px;
  .btn-container {
    .rich-btn {
      height: 92px;
    }
  }
}
.step-price {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 24px;

  .icon-outlined_pickup {
    font-size: 24px;
    margin: 0 12px;
    opacity: 0.4;
    transform: scaleX(-1) rotate(90deg);
  }
  div {
    font-family: DiDiSans-Pro-Regular;
    font-size: 28px;
    color: rgba(0, 0, 0, 0.6);
  }
  ::v-deep .overbold {
    font-family: DiDiSans-Pro-Bold;
    font-size: 28px;
    color: #000000;
  }
}
</style>
