<template>
  <div class="member">
    <!-- 登录 -->
    <ImmersionHeader
      ref="ImmersionHeader"
      :isShowBackBtn="isShowBackBtn"
      :isShowRightButton="isShowRightButton"
      :rightText="rightText"
      :title="brandName"
      :leftIconClassName="leftIconClassName"
      :blackTheme="blackTheme"
      @BackCK="headerBackCK"
      @rightBtnCK="rightBtnCK"
    />
    <div v-if="isLogin">
      <template v-if="!noPageInfo">
        <template v-if="isUpgradesPro">
          <!-- 特性升级页面 -->
          <UpgradeWarnDialog
            v-model="isShowUpgradeWarn"
            :brandName="brandName"
            :money="saleInfo.estimatedSavingSplit && saleInfo.estimatedSavingSplit.display"
            @closeUpgradeWarn="closeUpgradeWarn"
          />
          <SpecialtyPro
            :pageInfo="pageInfo"
            :headerHeight="headerHeight"
            :couponPackageStatus="couponPackageStatus"
            @goDetailed="goDetailed"
            @goSettingBir="goSettingBir"
            @getBirthdayCoupons="getBirthdayCoupons"
            @goCoupons="goCoupons"
            @goOldPack="goOldPack"
            @openRule="openRule"
            @freeUpgrade="freeUpgrade"
          />
        </template>
        <template v-else>
          <div
            class="data-container"
            :style="{ 'padding-bottom': footerHeight, 'min-height': minHeight }"
          >
            <div class="coupon-header-box" :style="{ 'padding-top': headerHeight + 'px' }">
              <CouponHeader
                :headInfo="headInfo"
                :benefitInfo="benefitInfo"
                :alreadySavedDisplay="alreadySavedDisplay"
                :noBuy="noBuy"
                :isBuyAndNoSubscriptionStatus="isBuyAndNoSubscriptionStatus"
                :saleInfo="saleInfo"
                :flowVersion="flowVersion"
                :isPauseFlowVersion="isPauseFlowVersion"
                :pauseSubscriptionStatus="pauseSubscriptionStatus"
                @goDetailed="goDetailed"
                @goSettingBir="goSettingBir"
                @getBirthdayCoupons="getBirthdayCoupons"
                @goCoupons="goCoupons"
                @getShowExpectContent="getShowExpectContent"
                @pausedClick="pausedClick"
              />
            </div>
            <!-- 在没有命中实验，且符合原来规则的时候展示该模块，通过saleInfo.showStyle判断 -->
            <SaleInfo :noBuy="noBuy" :saleInfo="saleInfo" />
            <template v-if="isShowSubscriptionText">
              <SubscriptionText
                :hasMarketingPrice="hasMarketingPrice"
                :money1="getMoney1"
                :money2="getMoney2"
                :didipackbrand="brandName"
                :didifoodbrand="DIDI_FOOD_BRAND"
                :membership="membership"
                @openRule="openRule"
              />
            </template>
            <!-- 加量包 -->
            <template v-if="!noBuy">
              <ExtraCouponPackages
                :extraInfo="extraInfo"
                :isShowBuyBtn="!isFromBill"
                @goBuy="goBuy"
              />
              <!-- 1.已开通订阅状态 -->
              <!-- 2.订阅版本 -->
              <TakeExplain
                v-if="isCanSubscribeVersionApp && !noBuy && inSubscriptionStatus"
                :membership="membership"
                @goManage="goManage"
                @openRule="openRule"
              />
              <SubscribeFooter
                v-if="isShowSubscriptionBtn"
                ref="subscriptionBtn"
                :headInfo="headInfo"
                :flowVersion="flowVersion"
                @joinSubscribe="joinSubscribe"
              />
              <pauseFooter
                v-if="isShowPauseBtn"
                ref="pauseBtn"
                :pauseSubscriptionStatus="pauseSubscriptionStatus"
                @goPauseRestore="goPauseRestore"
              />
            </template>
            <Footer
              v-if="isShowBuyBtn"
              ref="payBtn"
              :fromTab="isFromTab"
              :bubbleContent="membership.bubbleContent"
              :priceText="membership.priceSplit && membership.priceSplit.display"
              :delPrice="delPrice"
              :price="membership.price"
              :productId="membership.saleID"
              :isCanSubscribe="isCanSubscribe"
              :noSubscriptionStatus="noSubscriptionStatus"
              :pauseSubscriptionStatus="pauseSubscriptionStatus"
              :membership="membership"
              :hasMarketingPrice="hasMarketingPrice"
              @goBuy="goBuy"
            />
            <BillFooter
              v-if="isFromBill"
              ref="billFooterRef"
              :isCanSubscribe="isCanSubscribe"
              :noSubscriptionStatus="noSubscriptionStatus"
              @goBack="BackCK"
              @openRule="openRule"
            />
          </div>
        </template>
        <!-- 订阅的规则 -->
        <Rules
          v-model="isShowTakeRuleDialog"
          :rules="membership.subscriptionRules"
          title="FoodC_subscription_VPMP_seXT"
          zIndex="9999999"
        />
        <!-- 会员的规则 -->
        <Rules v-model="isShowRuleDialog" :rules="membership.rules" zIndex="9999999" />
        <!-- 商家券权益弹窗 -->
        <Rules
          v-model="isShowDetailedDialog"
          :rules="detailedDesc"
          :isJumpLink="isJumpLink"
          title="FoodB_Part2_Detailed_description_mxuF"
          @closeDialog="closeDialog"
        />
        <!-- 预计可省模块说明 -->
        <Rules v-model="isShowExpectContent" :rules="saleInfoRules" @closeDialog="closeDialog" />
        <!-- 命中实验时订阅浮层 -->
        <joinSubscribePopup
          v-model="isShowJoinSubscribe"
          @getJoinSuccess="getJoinSuccess"
          @getJoinRules="getJoinRules"
          @closeDialog="closeDialog"
        />
        <!-- 暂停&已开通 弹窗 -->
        <pauseRulesPopup
          v-model="isShowPauseRules"
          @getJoinRules="getJoinRules"
          @closeDialog="closeDialog"
        />
        <!-- 暂停&未开通 弹窗 -->
        <renewalManage
          v-model="isShowPauseSubscribe"
          :isChangPaymentBridgeVersion="isChangPaymentBridgeVersion"
          @getJoinRules="getJoinRules"
          @closeDialog="closeDialog"
        />
      </template>
      <div v-else-if="loading">
        <LoadingMask :type="loadingType" />
      </div>
      <div v-else-if="isShowRetryPage">
        <RetryPage @retry="checkLogin" />
      </div>
      <template v-else-if="!isNewVersion">
        <ActivityEnd text="FoodB_V2_dLkl_pbiF" :isShowButton="false" />
      </template>
      <template v-else-if="noPageInfo">
        <!-- 活动结束 -->
        <ActivityEnd />
      </template>
      <LoadingMask v-if="requestLoading" :type="loadingType" />
      <MemberDialog v-model="isShowDialog" :dialogData="dialogData" />
      <couponSuccessDialog v-model="isShowCouponSuccessDialog" @goCoupons="goCoupons" />
      <paySuccessDialog v-model="isShowPaySuccessDialog" :dialogData="paySuccessDialogData" />
      <!-- 订阅成功弹窗 ----isShowJoin 展示订阅文案 -->
      <paySuccessDialog
        v-model="isJoinSuccessPopup"
        :isShowJoin="true"
        :dialogData="joinSuccessDialogData"
      />
      <!-- 恢复订阅弹窗 -->
      <paySuccessDialog
        v-model="isShowPauseSuccessDialog"
        :dialogData="pauseSuccessDialogData"
        :isShowPause="true"
      />
      <!-- 集成收银台弹窗 -->
      <template v-if="isMemberInteRegister">
        <IntegratedPayDialog
          ref="IntegratedPayDialog"
          v-model="isShowIntegratedPayDialog"
          :priceText="membership.priceSplit && membership.priceSplit.display"
          :hasMarketingPrice="hasMarketingPrice"
          :money1="getMoney1"
          :money2="getMoney2"
          :didipackbrand="brandName"
          :didifoodbrand="DIDI_FOOD_BRAND"
          :membership="membership"
          :payBtnLoading="payBtnLoading"
          :bubbleContent="membership.bubbleContent"
          :isCanSubscribe="isCanSubscribe"
          :noSubscriptionStatus="noSubscriptionStatus"
          :pauseSubscriptionStatus="pauseSubscriptionStatus"
          :delPrice="delPrice"
          :noBuy="noBuy"
          :isCanSubscribeVersion="isCanSubscribeVersion"
          @openRule="getJoinRules"
          @goBuy="fastGoBuy"
        />
        <PayFailedReason
          v-model="isShowIntegratedPayFailedDialog"
          :rules="PayFailedReason"
          :title="$t('FoodC_register_cCpr_KUUG')"
          @closeDialog="closePayFailedDialog"
        />
      </template>
    </div>
    <!-- 未登录 -->
    <LoadingMask v-else-if="loading" :type="loadingType" />
    <LogOut v-else @update-login="updateLogin" />
  </div>
</template>

<script>
import { get, isEmpty } from 'lodash-es'
import {
  getMembershipDetail,
  getBenefitsCreateV2,
  getBenefitsCreateV2Fulfillment,
  getPopDialog,
  getFintechParams,
  memberBuyFree,
  updateUserInfo,
  getBirthdayBenefit,
  sendMembershipStoreCouponByShopID,
  benefitUpgrade,
  getCheckBuyMember,
  resumeMembershipSubscription,
} from '@/api/member/index.js'

import Rules from './components/rules/index.vue'
import LogOut from './components/logOut/index.vue'
import Footer from './components/footer/index.vue'
import SubscribeFooter from './components/subscribeFooter/index.vue'
import TakeExplain from './components/takeExplain/index.vue'
import ActivityEnd from './components/activityEnd/index.vue'
import MemberDialog from './components/memberDialog/index.vue'
import paySuccessDialog from './components/paySuccessDialog/index.vue'
import CouponHeader from './components/couponHeader/index.vue'
import LoadingMask from '@/components/loading/loading-mask.vue'
import ImmersionHeader from '@/components/immersionHeader/index.vue'
import ExtraCouponPackages from './components/extraCouponPackages/index.vue'
import SaleInfo from './components/saleInfo/index.vue'
import SubscriptionText from './components/subscriptionText/index.vue'
import BillFooter from './components/billFooter/index.vue'
import couponSuccessDialog from './components/couponSuccessDialog/index.vue'
import SpecialtyPro from './components/specialtyPro/index.vue'
import RetryPage from './components/retryPage/index.vue'
import UpgradeWarnDialog from './components/upgradeWarnDialog/index.vue'
import joinSubscribePopup from './components/joinSubscribePopup/index.vue'
import pauseRulesPopup from './components/pauseRulesPopup/index.vue'
import renewalManage from './components/renewalManage/index.vue'
import pauseFooter from './components/pauseFooter/index.vue'
import IntegratedPayDialog from './components/integratedPayDialog/index.vue'
import PayFailedReason from './components/PayFailedReason/index.vue'
import {
  membershipLandingSw,
  membershipLandingCk,
  membershipLandingBirthdayCk,
} from './trackEvent.js'

import {
  MODULE_TYPE,
  PAY_STATUS,
  POP_DIALOG_POSITION,
  HISTORY_PAGE,
  DIDI_FOOD_BRAND,
  BACK_ICON_NAME,
  OLD_PACK_PAGE,
  SALE_CHANNEL,
  UPGRADES_CHANNEL,
  COUPON_URL_DIDI,
  BENEFIT_OPEN_DIALOG_TYPE,
  BENEFIT_JUMP_TYPE,
  mobilityCouponItem,
  GLOBAL_EMBED_CUSTOMER,
  IS_FIRST_ENTRY,
  entregaCouponItem,
  JOIN_RULES_PAGE,
  lockSalesChannelNum,
  subscriptionStatusNum,
  SCENARIO_CODE,
} from './common/enumsUtil.js'

import { CHECK_VERSION_MAP, isVersionSupportMap } from '@/common/js/checkAppVersion.js'
import {
  pullUpPayment,
  selectPayMethod,
  switchToRider,
  autoSelectAddress,
  doPayV2,
} from '@/common/js/bridge.js'
import { mapMutations, mapActions, mapState } from 'vuex'
import apiConfig from '@/common/js/apiConfig'
import { debounce } from '@/common/js/utils'
import { upgradeSwitch } from './common/utils.js'
import { util } from '@didi/soda-common'
const { env } = util
export default {
  components: {
    Rules,
    // eslint-disable-next-line vue/no-reserved-component-names
    Footer,
    LogOut,
    SaleInfo,
    RetryPage,
    BillFooter,
    TakeExplain,
    LoadingMask,
    ActivityEnd,
    CouponHeader,
    MemberDialog,
    SpecialtyPro,
    SubscribeFooter,
    ImmersionHeader,
    paySuccessDialog,
    SubscriptionText,
    UpgradeWarnDialog,
    ExtraCouponPackages,
    couponSuccessDialog,
    joinSubscribePopup,
    pauseRulesPopup,
    renewalManage,
    pauseFooter,
    IntegratedPayDialog,
    PayFailedReason,
  },
  data() {
    return {
      DIDI_FOOD_BRAND,
      isLogin: false,
      loading: false,
      isShowDialog: false,
      isShowPaySuccessDialog: false,
      requestLoading: false,
      isShowRuleDialog: false,
      isShowTakeRuleDialog: false,
      isShowExpectContent: false, // 是否展示预计可省模块
      isShowJoinSubscribe: false, // 命中实验展示订阅浮层
      isJoinSuccessPopup: false, // 是否展示订阅成功的弹窗
      pageInfo: {},
      membership: {},
      headInfo: {},
      benefitInfo: {},
      saleInfo: {},
      extraInfo: {},
      alreadySavedDisplay: {},
      dialogData: {
        imgUrl: '',
        redirect: '',
      },
      paySuccessDialogData: {
        date: '', // 时间
        price: '', // 价格
        iconUrl: '',
      },
      extraCouponPackages: [],
      bigImageNum: 4,
      footerHeight: 0,
      minHeight: 0,
      headerHeight: 0,
      allVersionJudge: {},
      isNewVersion: true,
      isChangPaymentBridgeVersion: false,
      isShowDetailedDialog: false,
      detailedDesc: [
        {
          content: [],
        },
      ],
      isShowCouponSuccessDialog: false,
      bridgeInfo: {},
      toast: null,
      loadingType: '', // 预计3.0.2版本下线，到之后直接将loadingType置为空即可
      isShowUpgradeWarn: false,
      isShowRightButton: false,
      isShowBackBtn: false,
      leftIconClassName: BACK_ICON_NAME,
      blackTheme: true,
      clickUrl: '',
      itemType: '',
      isShowRetryPage: false,
      isCheckBuyMember: true, // 是否可以继续下面的购买，true的时候继续执行下面流程
      flowVersion: 0, // 会员开启取消流程优化实验
      pauseFlowVersion: 0, // 会员 暂停订阅实验 1-开启实验 0-对照组
      isShowPauseRules: false, // 已开通&暂停状态-说明弹窗
      isShowPauseSubscribe: false, // 未开通&暂停状态-管理弹窗
      isShowPauseSuccessDialog: false, // 恢复订阅弹窗
      isShowIntegratedPayDialog: false, // 显示集成收银台弹窗
      isShowIntegratedPayFailedDialog: false, // 支付失败弹窗
      PayFailedReason: [
        {
          content: [this.$t('FoodC_register_qELy_skhp')],
        },
      ],
      payBtnLoading: false, // 快捷支付按钮loading
      // 恢复订阅成功弹窗
      pauseSuccessDialogData: {
        date: '', // 时间
        price: '', // 价格
        iconUrl: '',
      },
      // 订阅成功弹窗
      joinSuccessDialogData: {
        date: '', // 时间
        price: '', // 价格
        iconUrl: '',
      },
    }
  },
  computed: {
    ...mapState(['globalParams', 'brandName']),
    isCanSubscribeVersionApp() {
      return this.allVersionJudge?.[CHECK_VERSION_MAP.CAN_SUBSCRIBE_VERSION.KEY]
    },
    // >=52版本且canSubscribe=true  就是订阅版本
    isCanSubscribeVersion() {
      return this.isCanSubscribeVersionApp && this.isCanSubscribe
    },
    getMoney1() {
      return this.membership?.priceSplit?.display || ''
    },
    getMoney2() {
      return this.membership?.renewPriceSplit?.display || ''
    },
    // 订阅灰度判断
    isCanSubscribe() {
      return this.membership?.canSubscribe
    },
    // 订阅状态：1-未订阅  2-已订阅 3-暂停订阅
    // 会员-未订阅 1
    noSubscriptionStatus() {
      return +this.membership?.subscriptionStatus === subscriptionStatusNum.noSubscription
    },
    // 会员-已订阅 2
    inSubscriptionStatus() {
      return +this.membership?.subscriptionStatus === subscriptionStatusNum.inSubscription
    },
    // 会员-暂停订阅 3
    pauseSubscriptionStatus() {
      return +this.membership?.subscriptionStatus === subscriptionStatusNum.pauseSubscription
    },
    // 购买后，且未开通订阅
    isBuyAndNoSubscriptionStatus() {
      return !this.noBuy && this.noSubscriptionStatus
    },
    // 右侧文案逻辑
    rightText() {
      return this.$t('FoodC_subscription_Wlpe_bBkf')
    },
    noPageInfo() {
      return isEmpty(this.pageInfo)
    },
    noBuy() {
      return this.membership?.status === 1
    },
    // 会员用户状态 0：未开通； 1：已开通-且订阅；2:已开通-未订阅 3:会员锁定状态 4:未开通-订阅暂停 5: 已开通-订阅暂停
    couponPackageStatus() {
      // lockStatus=2 的时候为锁定状态
      if (+this.membership?.lockStatus === 2) {
        return 3
      }
      if (this.membership?.status === 1) {
        if (this.pauseSubscriptionStatus) {
          return 4
        } else {
          return 0
        }
      } else {
        // 订阅状态：1-未订阅  2-已订阅 3-订阅暂停
        if (this.pauseSubscriptionStatus) {
          return 5
        } else if (this.inSubscriptionStatus) {
          return 1
        } else {
          return 2
        }
      }
    },
    delPrice() {
      if (this.membership?.displayPrice > 0) {
        return this.membership?.displayPriceSplit?.display
      } else {
        return false
      }
    },
    isFromBill() {
      // 从提单页进入
      return this.params?.sourceEntry === 'bill' || this.params?.fromPoint === 'bill'
    },
    isFromTab() {
      // 从底部tab页进入
      return (
        this.params?.sourceEntry === 'tab' ||
        this.params?.fromPoint === 'C_Bottom_Nav_A_1_0' ||
        this.params?.fromPoint === 'C_Bottom_Nav_B_1_0'
      )
    },
    isFromRPage() {
      // 从商家店铺进入
      return this.params?.sourceEntry === 'Rpage'
    },
    // 对照组：未购买&未订阅
    // 实验组：未购买&未订阅  未购买&暂停
    isShowBuyBtn() {
      return !this.isFromBill && this.noBuy
    },
    isShowPauseBtn() {
      // 命中暂停实验 & 已购买 & 订阅暂停
      return this.isPauseFlowVersion && !this.noBuy && this.pauseSubscriptionStatus
    },
    // 购买后、未开通订阅、且是订阅版本
    isShowSubscriptionBtn() {
      return !this.noBuy && this.isCanSubscribeVersion && this.noSubscriptionStatus
    },
    getBizId() {
      // 在ImmersionHeader组件内进行过一次获取公参的操作，所以这里直接使用了
      return this.$store?.state?.globalParams?.data?.bizId
    },
    getNoSubscribe() {
      const noSubscribe = this.params?.noSubscribe || 0
      return noSubscribe * 1
    },
    isChristmas() {
      return this.loadingType === 'Christmas'
    },
    isUpgradesPro() {
      return this.membership?.status === 3
    },
    getShopID() {
      return this.params?.shopID || ''
    },
    isBuy() {
      return this.membership?.status === 2
    },
    // 可以支持跳转的版本
    isJumpVersionApp() {
      return this.allVersionJudge?.[CHECK_VERSION_MAP.CAN_JUMP_VERSION.KEY]
    },
    isDeliveryBenefitJump() {
      // 闪送权益的跳转行为和出行一致，所以将该逻辑收敛到这里
      return this.itemType === mobilityCouponItem || this.itemType === entregaCouponItem
    },
    isJumpLink() {
      // 跳转限制
      // 如果是出行券，限制1、2、4
      // 如果不是，限制1、2
      // 1、返回跳转链接，2、是已购买状态，，4、新版本
      if (this.isDeliveryBenefitJump) {
        return Boolean(this.clickUrl && this.isBuy && this.isJumpVersionApp)
      }
      return Boolean(this.clickUrl && this.isBuy)
    },
    isGlobalEmbedCustomer() {
      // 在ImmersionHeader组件内进行过一次获取公参的操作，所以这里直接使用了
      return this.$store?.state?.globalParams?.data?.clientType === GLOBAL_EMBED_CUSTOMER
    },
    getSourceEntry() {
      return this.params?.sourceEntry || '未知'
    },
    isPlanJump() {
      return (
        this.getSourceEntry === 'RideSidebar' ||
        this.getSourceEntry === 'SAAccount' ||
        this.getSourceEntry === 'rideside'
      )
    },
    hasMarketingPrice() {
      // 无营销价格  售价=订阅价
      // 有营销价格  售价 < 订阅价
      // 订阅价：renewPrice
      // 售价：price
      const renewPrice = this.membership?.renewPrice || 0
      const price = this.membership?.price || 0
      return price < renewPrice
    },
    saleInfoRules() {
      const arr = [
        {
          content: [],
        },
      ]
      arr[0].content[0] = this.saleInfo?.content
      return arr
    },
    // 判断是不是在嵌入端有出行订单上的锁
    isGlobalJumpTravelLink() {
      return (
        this.isGlobalEmbedCustomer &&
        +this.membership?.lockSalesChannel == lockSalesChannelNum.channelTravel // eslint-disable-line eqeqeq
      )
    },
    // 判断是否需要展示重复购买弹窗
    isShowRepeatBuyAlert() {
      // 订单状态上锁=2 && 锁定渠道!=续费
      return (
        +this.membership?.lockStatus === 2 &&
        +this.membership?.lockSalesChannel !== lockSalesChannelNum.renew
      )
    },
    // 兼容安卓嵌入端非SA侧边栏直跳问题的版本
    isAndroidBarJumpVersionApp() {
      return this.allVersionJudge?.[CHECK_VERSION_MAP.RIDE_SIDE_BAR_JUMP_VERSION.KEY]
    },
    // 是不是Android
    isAndroid() {
      return env.isAndroid
    },
    // 从出行非SA的侧边栏进入
    isFromRideSidebar() {
      return this.params?.sourceEntry === 'RideSidebar'
    },
    // 老版本 & 嵌入端 & 出行订单 (展示一个按钮)
    isOldGlobalTravel() {
      return !this.isJumpVersionApp && this.isGlobalJumpTravelLink
    },
    // 版本<7.4.84 & 安卓 & 嵌入端 & 出行订单 & 在非SA的侧边栏进入 (展示一个按钮)
    isOldAndroidSidebarGlobalTravel() {
      return (
        !this.isAndroidBarJumpVersionApp &&
        this.isAndroid &&
        this.isGlobalJumpTravelLink &&
        this.isFromRideSidebar
      )
    },
    // 判断是否命中暂停订阅实验 pauseFlowVersion=1 & flowVersion =1
    isPauseFlowVersion() {
      return this.pauseFlowVersion && this.flowVersion
    },
    // 命中集成收银台的实验
    isMemberInteRegister() {
      return this.membership?.testConfig?.memberInteRegister === 2
    },
    // 注：在非购买条件下展示
    // 且只对有订阅的版本才展示
    // 且非实验才展示
    isShowSubscriptionText() {
      return this.noBuy && this.isCanSubscribeVersion && !this.isMemberInteRegister
    },
  },
  watch: {
    isShowBuyBtn: function (newV, oldV) {
      if (newV) {
        this.$nextTick(() => {
          this.membershipLandingSwFn(MODULE_TYPE.BUY_BUTTON)
          this.setBoxPaddingBottom('payBtn')
        })
      }
    },
    isShowSubscriptionBtn: function (newV, oldV) {
      if (newV) {
        this.$nextTick(() => {
          this.setBoxPaddingBottom('subscriptionBtn')
        })
      }
    },
    isShowPauseBtn: function (newV, oldV) {
      if (newV) {
        this.$nextTick(() => {
          this.setBoxPaddingBottom('pauseBtn')
        })
      }
    },
  },
  updated() {
    if (this.isFromBill) {
      this.setBoxPaddingBottom('billFooterRef')
    }
  },
  async created() {
    if (apiConfig.isDev) {
      // 有登录验证，所以在开发环境将登录校验去掉
      this.isLogin = true
      this.isChangPaymentBridgeVersion = true
      this.allVersionJudge = await isVersionSupportMap(CHECK_VERSION_MAP, apiConfig.isDev)
      this.requestPageInfo()
    } else {
      this.checkLogin()
    }
    // 监听tab切换
    this.tabChangeMonitor()
  },
  async mounted() {
    await this.getGlobalParams()
    this.setHeaderHeight()
  },
  methods: {
    ...mapActions({
      getGlobalParams: 'getGlobalParams',
    }),
    ...mapMutations(['SET_MEMBERSHIP_DETAIL']),
    styleRedrawing() {
      this.$nextTick(() => {
        window.scrollTo(0, 1)
      })
    },
    setHeaderHeight() {
      if (this.isFromTab) {
        this.headerHeight = this.$refs?.ImmersionHeader?.$refs?.headerBoxTab?.offsetHeight || 0
        return
      }
      this.headerHeight = this.$refs?.ImmersionHeader?.$el?.offsetHeight || 0
    },
    checkLogin() {
      this.loading = true
      Soda.getUserInfo({}, async data => {
        this.loading = false
        // 判断是否有用户token
        if (data && get(data, 'data.token', '')) {
          this.isLogin = true
          // 更改支付方式的bridge进入版本
          this.allVersionJudge = await isVersionSupportMap(CHECK_VERSION_MAP)
          this.isChangPaymentBridgeVersion =
            this.allVersionJudge?.[CHECK_VERSION_MAP.CHANGE_PAYMENT_VERSION.KEY]
          this.loading = true
          const success = await this.coincidenceJump()
          this.loading = false
          if (!success) {
            // 出现重试兜底页面
            this.isShowRetryPage = true
            return
          }

          // 如果是提单页不进行版本判断控制
          if (this.isFromBill) {
            this.requestPageInfo()
            return
          }
          // 版本判断 如果小于某个版本兼容版本，不进行投放
          this.isNewVersion = this.allVersionJudge?.[CHECK_VERSION_MAP.CASHIER_DESK_VERSION.KEY]
          if (!this.isNewVersion) return

          this.requestPageInfo()
        }
      })
    },
    // 是否符合乘客端直跳条件
    async coincidenceJump() {
      const conformingVersion = this.allVersionJudge?.[CHECK_VERSION_MAP.CAN_PLAN_JUMP_VERSION.KEY]
      // 1、是否为嵌入端 2、版本是否符合要求，
      if (this.isPlanJump && conformingVersion) {
        const isFirstEntry = !sessionStorage.getItem(IS_FIRST_ENTRY)
        // 3、是否调用过该bridge
        if (isFirstEntry) {
          const res = await this.autoSelectAddressBridge()
          if (res) sessionStorage.setItem(IS_FIRST_ENTRY, true)
          return res
        } else {
          return true
        }
      }
      return true
    },
    // bridge调用
    autoSelectAddressBridge() {
      return new Promise(resolve => {
        autoSelectAddress(
          {
            scene: 1,
          },
          res => {
            if (res?.data?.addressInfo?.address?.poi?.poiId) {
              resolve(true)
            } else {
              resolve(false)
            }
          },
        )
      })
    },
    // 请求页面数据
    requestPageInfo() {
      this._initData()
      this.getPopDialogRequest()
    },
    async _initData() {
      this.loading = true
      const res = await getMembershipDetail({
        noSubscribe: this.getNoSubscribe,
      })
      if (res?.errno === 0) {
        this.pageInfo = res?.data
        this.flowVersion = this.pageInfo?.flowVersion || 0
        this.pauseFlowVersion = this.pageInfo?.pauseFlowVersion || 0
        this.membership = this.pageInfo?.membership || {}
        this.headInfo = this.pageInfo?.headInfo || {}
        this.benefitInfo = this.pageInfo?.benefitInfo || {}
        this.saleInfo = this.pageInfo?.saleInfo || {}
        this.extraInfo = this.pageInfo?.extraInfo || {}
        this.alreadySavedDisplay = this.membership?.alreadySavedDisplay || {}
        const upgradePublicityFrequency = this.pageInfo?.upgradePublicityFrequency || 0
        if (this.isUpgradesPro && upgradePublicityFrequency > 0) {
          this.isShowUpgradeWarn = upgradeSwitch(upgradePublicityFrequency)
        }

        this.setIsShowRightButton()
        this.setIsShowBackBtn()
        // 存储在store 订阅页面会使用到
        this.SET_MEMBERSHIP_DETAIL(this.membership)
        // 续订页面: 未购买&订阅中&订单状态上锁=2&&锁定渠道=续费(15天续费周期中,扣费节点)
        if (
          this.isCanSubscribe &&
          this.noBuy &&
          this.inSubscriptionStatus &&
          +this.membership?.lockStatus === 2 &&
          +this.membership?.lockSalesChannel === lockSalesChannelNum.renew
        ) {
          this.loading = false
          this.goSubscribeLoading()
          return
        }
        this.extraCouponPackages = this.pageInfo?.extraCouponPackages || []
        // showStyle=1的时候为命中实验，使用新的埋点上报逻辑
        if (+this.saleInfo?.showStyle === 1) {
          // module_type中的1改为预计可省模块。（其余不变，顺便验证一下2累计已省模块
          // showTemplate=1 预计可省，showTemplate=2 累计已省
          if (+this.saleInfo?.showTemplate === 1) {
            this.membershipLandingSwFn(MODULE_TYPE.RULE)
          } else if (+this.saleInfo?.showTemplate === 2) {
            this.membershipLandingSwFn(MODULE_TYPE.DETAIL)
          }
        } else {
          this.membershipLandingSwFn(MODULE_TYPE.RULE)
        }
        if (!this.noBuy) {
          // 有加量包的时候上报 module_type =4
          if (Object.keys(this.extraInfo).length > 0) {
            this.membershipLandingSwFn(MODULE_TYPE.PACKAGING)
          }
        }
        // 锁定状态展示弹窗
        if (this.isShowRepeatBuyAlert) {
          // 展示一个按钮弹窗，不支持跳转
          if (this.isOldGlobalTravel || this.isOldAndroidSidebarGlobalTravel) {
            this.getShowBuyMemberOnly()
          } else {
            // 展示两个按钮弹窗
            this.getShowBuyMemberToUrl()
          }
        }
      } else {
        this.createErrorToast(res?.errmsg || '')
      }
      this.loading = false
      this.styleRedrawing()
    },

    // 重复购买弹窗展示跳转按钮
    getShowBuyMemberToUrl() {
      this.$createAlert({
        type: 'confirm',
        title: this.$t('FoodC_member_rIZz_EbdA'),
        content: this.$t('FoodC_member_wEQc_Appv', { brand: this.brandName }),
        confirmBtn: {
          text: this.$t('FoodC_member_qXYs_kKZA'),
          active: true,
          disabled: false,
          href: 'javascript:;',
        },
        cancelBtn: {
          text: this.$t('FoodC_member_fKsy_UJNP'),
          active: false,
          disabled: false,
          href: 'javascript:;',
        },
        onConfirm: () => {},
        onCancel: () => {
          // 在嵌入端的外卖容器，跳出行首页(对应场景：因为出行订单上锁的场景)
          if (this.isJumpVersionApp && this.isGlobalJumpTravelLink) {
            switchToRider({
              url: this.membership?.lockClickUrl,
            })
            return
          }
          window.SodaBridge('openPage', {
            target: 'blank',
            url: this.membership?.lockClickUrl,
          })
        },
      }).show()
    },
    // 重复购买弹窗不展示购买按钮
    getShowBuyMemberOnly() {
      this.$createAlert({
        type: 'alert',
        title: this.$t('FoodC_member_rIZz_EbdA'),
        content: this.$t('FoodC_member_wEQc_Appv', { brand: this.brandName }),
        confirmBtn: {
          text: this.$t('FoodC_member_qXYs_kKZA'),
          active: true,
          disabled: false,
          href: 'javascript:;',
        },
        onConfirm: () => {},
      }).show()
    },
    // 是否是正在购买中，如果是跳转购买加载页面
    goSubscribeLoading() {
      this.$router?.push({
        path: '/subscribeLoading',
        query: {
          nativeWebUIType: 2,
          benefitId: this.membership?.benefitID,
          couponPackageStatus: this.couponPackageStatus,
          clickType: MODULE_TYPE.RULE,
          benefitType: +this.membership?.status,
          addCouponList: this.getAddCouponList(),
          from: this.params?.sourceEntry || this.params?.fromPoint || '未知',
        },
      })
    },
    // 运营位弹窗
    async getPopDialogRequest() {
      const res = await getPopDialog({
        position: POP_DIALOG_POSITION.MEMBER,
      })
      if (res?.errno === 0 && res.data?.needPopup && res?.data?.info?.imgUrl) {
        // 接口返回数据正常
        this.isShowDialog = true
        this.dialogData.imgUrl = res?.data?.info?.imgUrl
        this.dialogData.redirect = res?.data?.info?.redirect
      }
    },
    updateLogin() {
      this.isLogin = true
      this._initData()
    },
    BackCK() {
      this.SodaBridge('closePage')
    },
    rightBtnCK() {
      // 1-右上角的历史记录点击
      this.membershipLandingCkFn(MODULE_TYPE.RULE)
      const origin = window?.location?.origin
      const url = origin + HISTORY_PAGE
      setTimeout(() => {
        window.SodaBridge('openPage', {
          target: 'blank',
          url,
        })
      }, 300)
    },
    setBoxPaddingBottom(val) {
      this.$nextTick(() => {
        if (val) {
          this.footerHeight = get(this.$refs?.[val], '$el.offsetHeight', 0) + 40 + 'px'
        }
        this.minHeight = `calc(100% - ${this.footerHeight})`
      })
    },
    // 打开订阅的规则
    openRule() {
      this.isShowTakeRuleDialog = true
    },
    async goBuy(val) {
      let productId = ''
      let price = ''
      let canSubscribe = false
      if (val?.type === 'MEMBER') {
        // 底部购买按钮
        this.membershipLandingCkFn(MODULE_TYPE.BUY_BUTTON)
        if (this.isMemberInteRegister) {
          this.isShowIntegratedPayDialog = true
          return
        }
        productId = this.membership?.saleID
        price = this.membership?.price
        canSubscribe = this.isCanSubscribe
        // 如果价格是0的时候，走0元支付逻辑
        // eslint-disable-next-line eqeqeq
        if (price == 0) {
          // 只有在0元购的时候获取是否重复购买
          this.requestLoading = true
          const res = await getCheckBuyMember()
          this.isCheckBuyMember = res?.data?.canPurchase
          this.requestLoading = false
          // 判断是不是有锁定的会员，只有当canPurchase=false，才刷新页面
          if (this.isCheckBuyMember === false) {
            this._initData()
            return
          }
          this.getChangePaymentMethod()
          return
        }
      } else if (val?.type === 'DOSING_PACK') {
        // 加量包购买按钮
        this.membershipLandingCkFn(MODULE_TYPE.PACKAGING)
        productId = val?.data?.saleID
        price = val?.data?.price
      }
      this.payRequest(productId, price, canSubscribe, val?.type)
    },
    closeIntegratedPayDialogAndLoading() {
      // 按钮loading关闭
      this.payBtnLoading = false
      // 关闭弹窗
      this.isShowIntegratedPayDialog = false
    },
    closePayFailedDialog() {
      this.isShowIntegratedPayDialog = true
    },
    async fastGoBuy(val) {
      const productId = this.membership?.saleID
      const price = this.membership?.price
      const canSubscribe = this.isCanSubscribe
      this.payBtnLoading = true
      if (price * 1 === 0) {
        // 只有在0元购的时候获取是否重复购买
        const res = await getCheckBuyMember()
        this.isCheckBuyMember = res?.data?.canPurchase
        // 判断是不是有锁定的会员，只有当canPurchase=false，才刷新页面
        if (this.isCheckBuyMember === false) {
          this.closeIntegratedPayDialogAndLoading()
          this.requestLoading = true
          this._initData()
          this.requestLoading = false
          return
        }
        const channelID = val?.channelID * 1
        const cardIndex = val?.cardIndex * 1 || 0
        await this.zeroPayment(channelID, cardIndex)
        this.closeIntegratedPayDialogAndLoading()
        return
      }
      const res = await getBenefitsCreateV2Fulfillment({
        productId,
        price,
        canSubscribe,
        scenarioCode: SCENARIO_CODE,
      })

      if (res?.errno === 0) {
        const res1 = await getFintechParams({ renewPrice: 0 })
        if (res1.errno === 0) {
          const resource_id = res1?.data?.resource_id
          // 调用金融的bridge
          const out_trade_id = res?.data?.transId
          const { cityId, clientType, dataType, locationType, osType, osVersion, appId } =
            this.globalParams?.data || {}
          // 客户端要求传string
          doPayV2(
            {
              bizDeviceInfo: JSON.stringify({
                osType: osType + '',
                osVersion: osVersion + '',
                dataType: dataType + '',
                clientType: clientType + '',
                locationType: locationType + '',
                cityId: cityId + '',
              }),
              outTradeId: out_trade_id + '',
              useSodaRandomVerify: true,
              productId: this.getBizId + '',
              appId: appId + '',
              selectedItems: [
                {
                  channelId: val?.channelID + '',
                  extraInfo: { cardIndex: val?.cardIndex + '' },
                },
              ],
              resourceId: resource_id + '',
            },
            async res => {
              this.closeIntegratedPayDialogAndLoading()
              if (res?.data?.payStatus === PAY_STATUS.SUCCESS) {
                // 刷新当前页面
                this.requestLoading = true
                await this._initData()
                this.requestLoading = false
                this.openPaySuccessDialog()
                this.sendMembershipStoreCouponByShopIDApi()
              } else {
                // 展示失败原因的弹窗
                this.isShowIntegratedPayFailedDialog = true
              }
            },
          )
        } else {
          this.payBtnLoading = false
          this.createErrorToast(res1.errmsg || '')
          this._initData()
        }
      } else {
        this.payBtnLoading = false
        this.createErrorToast(res.errmsg || '')
        this._initData()
      }
    },
    // 零元支付的逻辑
    async zeroPayment(channelID, cardIndex) {
      this.requestLoading = true
      const data = await memberBuyFree({
        saleId: this.membership?.saleID,
        saleChannel: SALE_CHANNEL,
        isSubscribe: this.membership?.canSubscribe ? 1 : 0,
        channelID,
        cardIndex,
      })
      this.requestLoading = false
      if (data?.errno === 0) {
        // 重新请求数据
        this.requestLoading = true
        await this._initData()
        this.requestLoading = false
        // 显示支付成功的弹窗
        this.openPaySuccessDialog()
        this.sendMembershipStoreCouponByShopIDApi()
      } else {
        this.createErrorToast(data?.errmsg || '')
      }
    },
    // 拉起更改支付方sdk。
    // 拿到数据成功之后调用memberBuyFree
    async getChangePaymentMethod(type) {
      await this.initGetFintechParams()
      this.changePayment(type)
    },
    // 获取更改支付的部分入参
    async initGetFintechParams() {
      this.requestLoading = true
      const res = await getFintechParams({
        renewPrice: this.membership?.renewPrice || 0,
      })
      this.requestLoading = false
      if (res?.errno === 0) {
        this.bridgeInfo = res?.data
      }
    },
    // 拉起更改支付方式的sdk
    changePayment(type) {
      const { product_id, app_id } = JSON.parse(this.bridgeInfo?.biz_content || {})
      const { cityId, clientType, dataType, locationType, osType, osVersion } =
        this.globalParams?.data || {}
      const biz_device_info = JSON.stringify({
        cityId: cityId.toString(),
        clientType: clientType.toString(),
        dataType: dataType.toString(),
        locationType: locationType.toString(),
        osType: osType.toString(),
        osVersion: osVersion.toString(),
      })
      const bridgeParams = {
        biz_content: this.bridgeInfo?.biz_content,
        sign: this.bridgeInfo?.sign,
        sign_type: this.bridgeInfo?.sign_type,
        resource_id: this.bridgeInfo?.resource_id,
        product_id,
        app_id,
        pay_method: [],
        biz_device_info,
      }
      selectPayMethod(bridgeParams, async res => {
        if (res.changed === 1) {
          // 更新当前视图
          const payMethod = res.pay_method[0] || {}
          if (type === UPGRADES_CHANNEL) {
            this.requestLoading = true
            await benefitUpgrade({
              saleChannel: UPGRADES_CHANNEL,
              channelID: payMethod?.channel_id * 1,
              cardIndex: payMethod.extra_info?.card_index * 1 || 0,
            })
            await this._initData()
            this.requestLoading = false
            return
          }
          const channelID = payMethod?.channel_id * 1
          const cardIndex = payMethod.extra_info?.card_index * 1 || 0
          this.zeroPayment(channelID, cardIndex)
        }
      })
    },
    openPaySuccessDialog() {
      this.paySuccessDialogData.date = this.membership?.renewDate
      this.paySuccessDialogData.price = this.membership?.renewPriceSplit?.display
      this.paySuccessDialogData.iconUrl = this.pageInfo?.paySuccessInfo?.iconUrl
      this.isShowPaySuccessDialog = true
    },
    async payRequest(productId, price, canSubscribe, type) {
      // 调用创建订单
      this.requestLoading = true
      const res = await getBenefitsCreateV2({
        productId,
        price,
        canSubscribe,
      })
      this.requestLoading = false
      if (res?.errno === 0) {
        // 调用金融的bridge
        const out_trade_id = res?.data?.transId
        pullUpPayment(
          {
            out_trade_id,
            product_id: this.getBizId,
          },
          async res => {
            if (res?.status === PAY_STATUS.SUCCESS) {
              // 刷新当前页面
              this.requestLoading = true
              await this._initData()
              this.requestLoading = false
              if (type === 'MEMBER') {
                this.openPaySuccessDialog()
                this.sendMembershipStoreCouponByShopIDApi()
              }
            }
          },
        )
      } else {
        this.createErrorToast(res.errmsg || '')
        this._initData()
      }
    },
    createErrorToast(errmsg = '', defaultMsg = this.gettext('网络繁忙，请稍后再试')) {
      const errorToast = this.$createToast({
        txt: errmsg || defaultMsg,
        time: 2000,
        mask: false,
        type: 'error',
      })
      errorToast.show()
    },
    membershipLandingSwFn(module_type) {
      membershipLandingSw({
        coupon_package_status: this.couponPackageStatus,
        benefit_id: this.membership?.benefitID,
        module_type, // 1-右上角的历史记录,2-累计已省模块,3-会员权益购买按钮,4-加量包购买模块
        from: this.params?.sourceEntry || this.params?.fromPoint || '未知',
      })
    },
    membershipLandingCkFn(click_type) {
      membershipLandingCk({
        coupon_package_status: this.couponPackageStatus,
        benefit_id: this.membership?.benefitID,
        click_type,
        benefit_type: +this.membership?.status,
        add_coupon_list: this.getAddCouponList(),
        from: this.params?.sourceEntry || this.params?.fromPoint || '未知',
      })
    },
    membershipLandingBirthdayCkFn(birString) {
      membershipLandingBirthdayCk({
        birthday: birString,
      })
    },
    getAddCouponList() {
      return this.extraCouponPackages
        ?.map(item => {
          return item.benefitID
        })
        .join()
    },
    goManage() {
      this.$router?.push({
        path: '/cancelTake',
        query: {
          expireDate: this.membership?.expireDate,
          nativeWebUIType: 2,
          isChangPaymentBridgeVersion: this.isChangPaymentBridgeVersion ? 1 : 2,
          tagType: this.membership?.tagType,
          flowVersion: this.flowVersion,
          isPauseFlowVersion: this.isPauseFlowVersion,
        },
      })
    },
    joinSubscribe() {
      // 命中实验的时候，展示订阅浮层
      if (+this.flowVersion === 1) {
        this.isShowJoinSubscribe = true
      } else {
        // 订阅didiclub
        // 价格：getMoney2
        // date: renewDate
        this.$router?.push({
          path: '/joinSubscription',
          query: {
            nativeWebUIType: 2,
            benefitId: this.membership?.benefitID,
            couponPackageStatus: this.couponPackageStatus,
            isBuyAndNoSubscriptionStatus: this.isBuyAndNoSubscriptionStatus, // 购买后，且未开通订阅
            clickType: 31,
            benefitType: +this.membership?.status,
            addCouponList: this.getAddCouponList(),
            from: this.params?.sourceEntry || this.params?.fromPoint || '未知',
          },
        })
      }
    },
    // 订阅成功刷新页面
    async getJoinSuccess() {
      this.isShowJoinSubscribe = false // 关闭订阅浮层
      await this._initData()
      this.joinSuccessDialogData.date = this.membership?.renewDate
      this.joinSuccessDialogData.price = this.membership?.renewPriceSplit?.display
      this.joinSuccessDialogData.iconUrl = this.pageInfo?.paySuccessInfo?.iconUrl
      this.isJoinSuccessPopup = true // 订阅成功弹窗
    },
    // 点击规则详情进入规则页面
    getJoinRules() {
      const origin = window?.location?.origin
      const url = origin + JOIN_RULES_PAGE
      window.SodaBridge('openPage', {
        target: 'blank',
        url,
      })
    },
    // 吊起详情弹窗
    goDetailed(data) {
      this.detailedDesc[0].content[0] = data?.detailedDesc
      this.clickUrl = data?.clickUrl
      this.itemType = data?.itemType
      this.membershipLandingCkFn(BENEFIT_OPEN_DIALOG_TYPE[this.itemType])
      this.isShowDetailedDialog = true
    },
    // 生日弹窗
    goSettingBir() {
      this.showDatePicker()
    },
    showDatePicker() {
      if (!this.datePicker) {
        this.datePicker = this.$createDatePicker({
          title: this.$t('FoodC_birthday_lxme_nZrh'),
          subtitle: this.$t('FoodC_birthday_JshR_FWPW'),
          confirmTxt: this.$t('FoodC_birthday_bthp_qePu'),
          min: new Date(1900, 1, 1),
          max: new Date(),
          value: new Date(),
          onSelect: this.selectHandle,
          onCancel: this.cancelHandle,
        })
      }

      this.datePicker.show()
    },
    async selectHandle(date) {
      this.requestLoading = true
      const res = await updateUserInfo({
        birthday: this.withoutTime(date),
      })
      const birString = this.withoutTime(date, 'string')
      this.membershipLandingBirthdayCkFn(birString)
      this.requestLoading = false
      if (res.errno === 0) {
        this._initData()
      } else {
        this.createErrorToast(res?.errmsg || '')
      }
    },
    cancelHandle() {},
    // 领取生日礼物
    async getBirthdayCoupons() {
      this.requestLoading = true
      const res = await getBirthdayBenefit()
      this.requestLoading = false
      if (res.errno === 0) {
        this.isShowCouponSuccessDialog = true
        this._initData()
      } else {
        const toast = this.$createToast({
          txt: this.$t('FoodC_birthday_UhuO_lUDL'),
          time: 2000,
          type: 'warn',
          mask: false,
        })
        toast.show()
      }
    },

    // 转换时间格式
    withoutTime(dateString, type = '') {
      // 创建日期对象
      const date = new Date(dateString)
      // 获取年、月、日
      const year = date.getFullYear()
      const month = date.getMonth() + 1 // 月份从0开始，需要加1
      const day = date.getDate()
      let formattedDate = ''
      if (type === 'string') {
        // 格式化日期
        formattedDate =
          year.toString() +
          this.addLeadingZero(month).toString() +
          this.addLeadingZero(day).toString()
        return formattedDate
      } else {
        // 格式化日期
        formattedDate = year + '-' + this.addLeadingZero(month) + '-' + this.addLeadingZero(day)
        return formattedDate
      }
    },
    // 添加前导零
    addLeadingZero(number) {
      return number < 10 ? '0' + number : number
    },
    goCoupons() {
      window.SodaBridge('openPage', {
        target: 'blank',
        url: COUPON_URL_DIDI,
      })
    },
    tabChangeMonitor() {
      if (this.isFromTab && Soda.customer) {
        if (!this.isChristmas) {
          this.toast = this.$createToast({
            type: 'loading',
            effect: 'light',
            time: 0,
          })
        }
        Soda.customer.triggerTabShowEvent = debounce(async () => {
          // 更新接口数据
          if (this.isChristmas) {
            this.requestLoading = true
          } else {
            this.toast.show()
          }
          await this._initData()
          this.subscribeLoadingGoBack()
          if (this.isMemberInteRegister) {
            this.$refs?.IntegratedPayDialog?.initData()
          }
          if (this.isChristmas) {
            this.requestLoading = false
          } else {
            this.toast.hide()
          }
        }, 300)
      }
    },
    // 判断当前状态是否非续订中，如果不是，返回上一个页面
    subscribeLoadingGoBack() {
      // 如果当前route是subscribeLoading 且当前状态不对，则返回会员页面
      const { canSubscribe, status, subscriptionStatus } = this.membership
      // 未购买
      const noBuy = status === 1
      // 已订阅
      const inSubscriptionStatus = +subscriptionStatus === subscriptionStatusNum.inSubscription

      const subscribeStatus = canSubscribe && noBuy && inSubscriptionStatus

      const subscribeLoadingPath = '/subscribeLoading'
      // 当前路径是订阅中的路径
      const isSubscribeLoadingPath = this.$route.path === subscribeLoadingPath

      if (isSubscribeLoadingPath && !subscribeStatus) {
        this.$router.go(-1)
      }
    },
    setIsShowRightButton() {
      // 如果是升级pro页面，不展示购买记录
      if (this.isUpgradesPro) {
        this.isShowRightButton = false
        return
      }
      this.isShowRightButton = true
    },
    setIsShowBackBtn() {
      this.isShowBackBtn = !this.isFromTab
    },
    headerBackCK() {
      this.BackCK()
    },
    closeUpgradeWarn() {
      this.isShowUpgradeWarn = false
    },
    // 去老的pack页面
    goOldPack() {
      const origin = window?.location?.origin
      const url = origin + OLD_PACK_PAGE
      window.SodaBridge('openPage', {
        target: 'blank',
        url,
      })
    },
    freeUpgrade() {
      this.membershipLandingCkFn(MODULE_TYPE.UPGRADE_BUTTON)
      this.getChangePaymentMethod(UPGRADES_CHANNEL)
    },
    // 根据商家ID发送会员商家券
    sendMembershipStoreCouponByShopIDApi() {
      if (this.isFromRPage && this.getShopID) {
        sendMembershipStoreCouponByShopID({
          shopID: this.getShopID,
        })
      }
    },
    // 预计可省模块说明弹窗
    getShowExpectContent() {
      this.isShowExpectContent = true
    },
    // 恢复订阅
    async goPauseRestore() {
      this.membershipLandingCkFn(MODULE_TYPE.PAUSE_BUTTON)
      this.requestLoading = true
      const res = await resumeMembershipSubscription()
      this.requestLoading = false
      if (+res?.errno === 0) {
        await this._initData()
        this.pauseSuccessDialogData.date = this.membership?.renewDate
        this.pauseSuccessDialogData.price = this.membership?.renewPriceSplit?.display
        this.pauseSuccessDialogData.iconUrl = this.pageInfo?.paySuccessInfo?.iconUrl
        this.isShowPauseSuccessDialog = true
      } else {
        this._initData()
      }
    },
    // 暂停状态弹窗
    pausedClick() {
      // 命中实验 & 暂停 & 已购买 -- 说明弹窗
      // 暂停 & 未购买 -- 管理弹窗(已经走到暂停态，不需要去看是否命中实验)
      if (this.pauseSubscriptionStatus) {
        if (this.noBuy) {
          this.membershipLandingCkFn(MODULE_TYPE.PAUSE_MANAGE)
          this.isShowPauseSubscribe = true
        } else {
          this.isShowPauseRules = true
        }
      }
    },
    closeDialog() {
      if (this.isJumpLink) {
        this.membershipLandingCkFn(BENEFIT_JUMP_TYPE[this.itemType])
        setTimeout(() => {
          // 如果是嵌入端，调用bridge方法进行跳转
          if (this.isGlobalEmbedCustomer && this.isDeliveryBenefitJump) {
            switchToRider({
              url: this.clickUrl,
            })
            return
          }
          window.SodaBridge('openPage', {
            target: 'blank',
            url: this.clickUrl,
          })
        }, 300)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.coupon-header-box {
  background: #fff;
  margin-bottom: 12px;
}
.member {
  background-color: #fff;
  min-height: 100%;
  // will-change: transform; // css性能优化
}
</style>
