
<template>
  <div class="empty-state">
    <img src="../../assets/images/empty_icon.png" alt="" class="empty-img" />
    <p class="empty-desc">{{ $t(text) }}</p>
    <div class="go-home" @click="goHome" v-if="isShowButton">
      <rich-button :outline="true" size="medium">{{$t('FoodC_C_Back_Home_opQY')}}</rich-button>
    </div>
  </div>
</template>

<script>
import {
  getScheme
} from '@/common/js/utils'
export default {
  name: 'EmptyState',
  props:{
    text:{
      default:'FoodC_C_No_suitable_UnyN',
      type: String
    },
    isShowButton:{
      default:true,
      type:Boolean
    }
  },
  methods: {
    goHome () {
      location.href = `${getScheme()}://soda/homePage?closeSideMenu=1`
    }
  }
}
</script>
<style lang="scss" scoped>
.empty-state {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .empty-img {
    width: 300px;
    height: 300px;
    margin-bottom: 7px;
  }
  .empty-desc {
      font-family: DiDiSans-Pro-Bold;
      font-size: 32px;
      color: #000000;
      text-align: center;
      line-height: 40px;
      width:590px;
      margin: 0 auto 32px;
  }
  .go-home {
    width: 400px;
  }
 
}
</style>
