<template>
    <div class="extraCouponPackages" v-if="extraInfo.content && extraInfo.content.length>0">
        <div class="title ellipsis2">{{ extraInfo.title }}</div>
        <div class="desc ellipsis2">{{ extraInfo.validDateDesc }}</div>
        <div class="list-content">
            <div v-for="(item, index) in extraInfo.content" :key="index" class="list">
                <div class="list-box">
                    <div class="list-box-desc">
                        <div class="list-box-title ellipsis2" v-if="item.title">{{ item.title }}</div>
                    </div>
                    <div class="btn" v-if="isShowBuyBtn" @click="goBuy(item)">
                        <rich-button size="medium">{{ item.priceSplit.display }}</rich-button>
                    </div>
                </div>
                <div>
                    <div class="list-box-line"></div>
                </div>
            </div>
        </div>
        <div class="line-grey"></div>
    </div>
</template>

<script>
export default {
    name: 'extraCouponPackages',
    props: ['extraInfo', 'isShowBuyBtn'],
    methods: {
        goBuy(val) {
            this.$emit('goBuy', { type: 'DOSING_PACK', data: val })
        }
    }
}
</script>

<style lang="scss" scoped>
.ellipsis2 {
    // word-break: break-all;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    /* 这里是超出几行省略 */
    overflow: hidden;
}

.title {
    font-family: DiDiSans-Pro-Bold;
    font-size: 36px;
    color: #000000;
    line-height: 36px;
    padding: 48px 32px 0 32px;
    margin-bottom: 8px;
}

.desc {
    opacity: 0.6;
    font-family: DiDiSans-Pro-Regular;
    font-size: 24px;
    color: #000000;
    line-height: 32px;
    padding: 0 32px 0 32px;
    margin-bottom: 24px;
}

.list {
    margin: 0 32px;
}

.list-box {
    display: flex;
    align-items: center;
    padding: 36px 0;
}

.list-box-desc {
    flex: 1;

    .list-box-title {
        font-family: DiDiSans-Pro-Bold;
        font-size: 32px;
        color: #000000;
        line-height: 40px;
        margin-right: 64px;
    }
}

.btn {
    width: 172px;
    .rich-btn {
        // font-family: DiDiSans-Pro-Bold;
        font-size: 32px;
    }
}

.list-box-line {
    height: 1px;
    margin: 23px 0;
    background: rgba($color: #000000, $alpha: 0.1);
}
.line-grey{
        height: 12px;
        background: #F7F7F7;
    }
.list {
    &:nth-last-child(1) {
        .list-box-line {
            background: #fff;
        }
    }
}</style>