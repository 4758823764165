import trackEvent from '@/common/js/track.js'

// 新版券包落地页页面曝光
export function membershipLandingSw(attrs, callback) {
  trackEvent({
    eventId: 'sailing_c_x_membership_landing_sw',
    eventLabel: '新版券包落地页页面曝光',
    attrs,
    callback,
  })
}

// 新版券包落地页点击
export function membershipLandingCk(attrs, callback) {
  trackEvent({
    eventId: 'sailing_c_x_membership_landing_ck',
    eventLabel: '新版券包落地页点击',
    attrs,
    callback,
  })
}
// 会员落地页生日日期提交按钮点击
export function membershipLandingBirthdayCk(attrs, callback) {
  trackEvent({
    eventId: 'sailing_c_x_membership_landing_birthday_ck',
    eventLabel: '会员落地页生日日期提交按钮点击',
    attrs,
    callback,
  })
}
// 会员取消订阅填写原因页-展现
export function membershipSubscribeReasonSw(attrs, callback) {
  trackEvent({
    eventId: 'sailing_c_x_membership_subscribe_reason_sw',
    eventLabel: '会员取消订阅填写原因页-展现',
    attrs,
    callback,
  })
}

// 会员取消订阅填写原因页点击
export function membershipSubscribeReasonCk(attrs, callback) {
  trackEvent({
    eventId: 'sailing_c_x_membership_subscribe_reason_ck',
    eventLabel: '会员取消订阅填写原因页点击',
    attrs,
    callback,
  })
}

// 会员取消订阅的挽留页展现
export function membershipSubscribeKeepSw(attrs, callback) {
  trackEvent({
    eventId: 'sailing_c_x_membership_subscribe_keep_sw',
    eventLabel: '会员取消订阅的挽留页展现',
    attrs,
    callback,
  })
}

// 会员取消订阅挽留页点击
export function membershipSubscribeKeepCk(attrs, callback) {
  trackEvent({
    eventId: 'sailing_c_x_membership_subscribe_keep_ck',
    eventLabel: '会员取消订阅挽留页点击',
    attrs,
    callback,
  })
}

// 暂停时间选择页面曝光
export function membershipPauseSw(attrs, callback) {
  trackEvent({
    eventId: 'sailing_c_x_membership_pause_sw',
    eventLabel: '会员暂停页面展现',
    attrs,
    callback,
  })
}
// 暂停页面点击
export function membershipPauseCk(attrs, callback) {
  trackEvent({
    eventId: 'sailing_c_x_membership_pause_ck',
    eventLabel: '会员暂停页面点击',
    attrs,
    callback,
  })
}
// 支付弹窗展现
export function membershipPayPopupSw(attrs, callback) {
  trackEvent({
    eventId: 'sailing_c_x_membership_pay_popup_sw',
    eventLabel: '支付弹窗展现',
    attrs,
    callback,
  })
}

// 支付弹窗点击
export function membershipPayPopupCk(attrs, callback) {
  trackEvent({
    eventId: 'sailing_c_x_membership_pay_popup_ck',
    eventLabel: '支付弹窗点击',
    attrs,
    callback,
  })
}
