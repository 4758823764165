import didiaxios from '@didi/dajax/dist/dajax'
import apiConfig from '@/common/js/apiConfig'

const ACT_API = apiConfig.BASE_API_ACT
const C_API = apiConfig.BASE_API_C
// 可用优惠券列表
const GET_WALLET_COUPONS = ACT_API + '/act-api/api/sidebar/validCouponsV2'
// banner和背景数据
const GET_BANNER = ACT_API + '/act-api/api/banner/getUserCenterBannerInfo'
// 兑换优惠券
const EXCHANGE_COUPON = ACT_API + '/act-api/api/code/sidebarRedeem'
// 兑换成功的优惠券
const SIDEBAR_EXCHANGE_RESULT = ACT_API + '/act-api/api/code/redeemResult'
// 通过cityId获取券包信息
const POST_PRODUCT_LIST = C_API + '/memberBenefit/getProductListByCityId'

export const getAllCoupons = function (data) {
  return requestPOST(GET_WALLET_COUPONS, data)
}
export const getBanner = function (data) {
  return requestGET(GET_BANNER, data)
}
export const exchangeCoupon = function (data) {
  return requestPOST(EXCHANGE_COUPON, data)
}
export const getCouponSuccessInfo = function (data) {
  return requestPOST(SIDEBAR_EXCHANGE_RESULT, data)
}
export const getProductListByCityId = function (data) {
  return requestPOST(POST_PRODUCT_LIST, data)
}
async function requestPOST (url, data) {
  let globalParams = await SodaBridge('getGlobalParams', 'badcase') || {}
  let paramsData = globalParams.data || {}
  return didiaxios.request({
    url: url,
    method: 'POST',
    emulateJSON: true,
    needEncrypt: true,
    params: '',
    body: { ...paramsData, ...data }
  }).then(res => (res.data)).catch((err) => console.log(err))
}
async function requestGET (url, data) {
  let globalParams = await SodaBridge('getGlobalParams', 'badcase') || {}
  let paramsData = globalParams.data || {}
  return didiaxios.request({
    url: url,
    method: 'GET',
    emulateJSON: true,
    needEncrypt: true,
    params: {
      ...paramsData,
      ...data
    }
  })
}
