import { getGlobalParams } from '@/common/js/bridge'
import store from '@/store'
import API from '@/api'
import Schema from '@/common/js/schema'
import { globalParamsSchema, acquisitionInfoSchema } from './schema'
function getGlobalInfo () {
  if (Schema.compare(store.getters.globalParams, globalParamsSchema)) {
    return Promise.resolve(store.getters.globalParams)
  } else {
    return getGlobalParams().then(globalParams => {
      store.commit('UPDATE_GLOBAL_PARAMS', globalParams)
      return globalParams
    })
  }
}
// TODO: schema compare middleware
function getAcquisitionInfo (state, data) {
  if (Schema.compare(store.getters.acquisitionInfo, acquisitionInfoSchema)) {
    return Promise.resolve({data: store.getters.acquisitionInfo, errno: 0})
  } else {
    return API.acquisitionInfo(data).then(result => {
      store.commit('UPDATE_ACQUISITIOIN_INFO', result.data)
      return result
    }).catch(err => console.log(err))
  }
}
function getHorseLampData (state, data) {
  return API.getHorseLampData(data).then(result => {
    return result.data
  }).catch(err => console.log(err))
}

function getParticipateInfo (state, data) {
  return API.participateInfo(data).then(result => {
    store.commit('UPDATE_PARTICIPATE_INFO', result.data)
    return result
  }).catch(err => console.log(err))
}

function getParticipateRedeem (state, data) {
  return API.participateRedeem(data).then(result => {
    return result
  }).catch(err => console.log(err))
}

function getPromoResult (state, data) {
  return API.promoResult(data).then(result => {
    store.commit('UPDATE_PROMO_RESULT', result.data)
    return result
  }).catch(err => console.log(err))
}

// 获取券包列表
function getProductList (state, data) {
  return API.getProductList(data).then(result => {
    return result
  }).catch(err => console.log(err))
}

// 创建券包购买订单
function createOrder (state, data) {
  return API.createOrder(data).then(result => {
    return result
  }).catch(err => console.log(err))
}

// 创建券包购买订单
function queryOrderStatus (state, data) {
  return API.queryOrderStatus(data).then(result => {
    return result
  }).catch(err => console.log(err))
}

// 获取用户卡信息
function getPayInfo (state, data) {
  return API.getPayInfo(data).then(result => {
    store.commit('UPDATE_PAYINFO', result.data)
    if (result.data && result.data.payments) {
      const payments = result.data.payments
      const extraInfo = (Array.isArray(payments) && payments.find(item => {
        // payment_mode 为150时为信用卡支付方法，其内部包含用户的所有卡的列表，字段为extraInfoList
        return Number(item.channelID) === 150
      })) || {}
      // 将channelID添加到每个卡信息中。
      Array.isArray(extraInfo.extraInfoList) && extraInfo.extraInfoList.map(elem => {
        elem.channelID = extraInfo.channelID
      })
      store.commit('UPDATE_EXTRAINFO_LIST', extraInfo.extraInfoList)
    }
    return result
  }).catch(err => console.log(err))
}
// 用户所选择的支付方式
function setPayment (state, info) {
  store.commit('UPDATE_SELECTED_PAYMENT', info)
}

export default {
  // cgc-活动页改版-跑马灯数据
  getHorseLampData,
  getGlobalInfo,
  getAcquisitionInfo,
  getParticipateInfo,
  getParticipateRedeem,
  getPromoResult,
  getProductList,
  createOrder,
  getPayInfo,
  setPayment,
  queryOrderStatus
}
