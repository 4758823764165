import didiaxios from '@didi/dajax/dist/dajax'
import apiConfig from '@/common/js/apiConfig'

const BASE_URL = apiConfig.BASE_API_ACT

// 获取活动初始化物料信息
const GET_GET_SHARE_ACTIVITY = BASE_URL + '/act-api/api/sharebox/getActivity'
// 券包购买创建订单
const OPEN_BOX = BASE_URL + '/act-api/api/sharebox/openBox'
// 获取分享内容配置 一期只需分享图片字段，入参为activityId ps：由后端包装实质上读取的是apollo配置
const GET_SHARE_CONF = BASE_URL + '/act-api/api/sharebox/getApolloShareconf'

export const getShareActivity = function (data) {
  return requestPOST(GET_GET_SHARE_ACTIVITY, data)
}

export const openBox = function (data) {
  return requestPOST(OPEN_BOX, data)
}

export const getShareConf = function (params) {
  return requestGet(GET_SHARE_CONF, params)
}

function requestPOST (url, data) {
  return didiaxios.request({
    url: url,
    method: 'POST',
    emulateJSON: true,
    needEncrypt: true,
    params: '',
    body: data
  }).then(res => (res.data)).catch((err) => console.log(err))
}

function requestGet (url, params) {
  return didiaxios.request({
    url,
    method: 'GET',
    emulateJSON: true,
    needEncrypt: true,
    params
  })
}
