<template>
  <Popup
    :isShow="shows"
    :title="$t('FoodC_pause_VaEr_OWMS')"
    class="popup-box"
    :zIndex="zIndex"
    @clickMask="shows = false"
  >
    <div slot="content" class="popup-wrapper">
      <div class="rule-desc" @click="openRule($event)" v-html="getRuleDesc"></div>
    </div>
  </Popup>
</template>

<script>
import Popup from '@/components/vant-popup'
import { mapState } from 'vuex'
export default {
  name: 'PauseRulesPopup',
  components: {
    Popup,
  },
  model: {
    prop: 'showTips',
    event: 'showsChange',
  },
  props: {
    zIndex: {
      type: String,
      default: '100',
    },
    showTips: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  computed: {
    ...mapState(['membership', 'brandName']),
    shows: {
      get: function () {
        return this.showTips
      },
      set: function (value) {
        this.$emit('showsChange', value)
      },
    },
    // 暂停起始日
    getDate1() {
      return this.membership?.pauseStartDate || ''
    },

    // 暂停恢复日
    getDate2() {
      return this.membership?.pauseAutoResumeDate || ''
    },
    // 暂停月数
    brandNum() {
      return this.membership?.pauseMonths || ''
    },
    // 自动扣款钱数
    getMoney() {
      return this.membership?.renewPriceSplit?.display || ''
    },
    getRuleDesc() {
      return this.$t('FoodC_pause_dEKK_qnxf', {
        brand: this.brandName,
        date1: this.getDate1,
        date2: this.getDate2,
        num: this.brandNum,
        money: this.getMoney,
        tc: `<span class="rule">${this.$t('FoodC_subscription_VPMP_seXT')}</span>`,
      })
    },
  },
  methods: {
    closeDialog() {
      this.shows = false
      this.$emit('closeDialog')
    },
    openRule(el) {
      const isRule = el.target.className === 'rule'
      if (isRule) {
        this.$emit('getJoinRules')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
// 规则样式开始------------
.popup-wrapper {
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  margin-top: 40px;
}
// 规则样式结束------------
::v-deep .wrapper {
  .half-title {
    span {
      margin-left: 0;
    }
  }
}
.rule-desc {
  padding-bottom: 220px;
  font-family: DiDiSans-Pro-Regular;
  font-size: 28px;
  color: rgba(0, 0, 0, 0.6);
  text-align: left;
  line-height: 36px;
}
::v-deep .rule {
  font-family: DiDiSans-Pro-Medium;
  font-size: 28px;
  color: #000000;
  text-decoration: underline;
}
</style>
