<template>
  <div class="box">
    <LogOutPage @update-login="updateLogin"/>
  </div>
</template>

<script>
import LogOutPage from '@/components/log-out-page/index.vue'
export default {
    components: {
      LogOutPage
    },
    methods: {
        updateLogin() {
            this.$emit('update-login')
        }
    }
}
</script>

<style lang="scss" scoped>
.box{
  padding-top: 484px;
}
</style>