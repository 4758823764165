<template>
    <div>
        <van-popup v-model="shows" :close-on-click-overlay="false">
            <div class="box" >
                <div class="head-img"></div>
                <div class="content">
                    <div class="title">{{ $t('FoodC_birthday_ZEpi_KbUs') }}</div>
                    <div @click="goCoupons" class="desc">{{ $t('FoodC_birthday_uoAk_Rmzj') }}</div>
                    <rich-button @click="shows = !shows">{{ $t('FoodC_birthday_mwOE_zhiz') }}</rich-button>
                </div>
            </div>
        </van-popup>
    </div>
</template>
<script>
import Vue from 'vue';
import { Popup } from 'vant';

Vue.use(Popup);
export default {
    name: 'couponSuccessDialog',
    model: {
        prop: 'showTips',
        event: 'showsChange',
    },
    computed: {
        brandName(){
            return this.$t('FoodC_subscription_LiqM_lRQw')
        },
        shows: {
            get: function () {
                return this.showTips;
            },
            set: function (value) {
                this.$emit('showsChange', value);
            },
        },
    },
    props: {
        showTips: {
            type: Boolean,
            default: false,
            required: false,
        },
    },
    methods: {
        goCoupons(){
            this.$emit('goCoupons')
        }
    }
}
</script>

<style lang="scss" scoped>
.van-popup{
    background: none;
}
.box{
    width: 580px;
}
.head-img{
    width: 100%;
    height: 330px;
    background: url('../../../../assets/images/member/coupon_success_dialog_bg.png') no-repeat;
    background-size:100% 100% ;
    position: relative;
    z-index: 1;
}
.content{
    padding: 244px 32px 48px 32px;
    background: linear-gradient(#ffffff,#fff5f0);
    border-radius: 40px;
    position: relative;
    left: 0;
    top: -188px;
}
.title{
    font-family: DiDiSans-Pro-Bold;
    font-size: 40px;
    color: #000000;
    text-align: center;
    line-height: 48px;
    font-weight: 700;
    margin-bottom: 32px;
}
.desc {
    font-family: DiDiSans-Pro-Medium;
    text-decoration-line: underline;
    font-size: 24px;
    color: #FF8040;
    text-align: center;
    line-height: 22px;
    font-weight: 500;
    margin-bottom: 80px;
}
</style>