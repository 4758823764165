import trackEvent from '@/common/js/track'
export function GET_URL_COUNTRY (attrs) {
  trackEvent({
    eventId: 'tech_act_x_homepage_enter_get_url_query',
    eventLabel: '进入页面时触发获取国家方法时的URL参数',
    attrs: attrs
  })
}

export function GET_BRIDGE_COUNTRY (attrs) {
  trackEvent({
    eventId: 'tech_act_x_homepage_enter_get_bridge_country',
    eventLabel: '获取进入页面触发getCountry方法时的bridge参数',
    attrs: attrs
  })
}

export function GET_BRIDGE_POSITION (attrs) {
  trackEvent({
    eventId: 'tech_act_x_homepage_enter_get_bridge_position',
    eventLabel: '进入首页面时触发getCountry方法时的bridge定位参数',
    attrs: attrs
  })
}

export function GET_BROWSER_POSITION (attrs) {
  trackEvent({
    eventId: 'tech_act_x_homepage_enter_get_browser_position',
    eventLabel: 'H5能力获取定位信息',
    attrs: attrs
  })
}

export function GET_API_PARSED_COUNTRY (attrs) {
  trackEvent({
    eventId: 'tech_act_x_homepage_enter_get_bridge_api_parsed',
    eventLabel: '进入首页面时触发getCountry方法时的接口反解出的国家',
    attrs: attrs
  })
}

export function GET_FINAL_COUNTRY (attrs) {
  trackEvent({
    eventId: 'tech_act_x_homepage_enter_get_final_country',
    eventLabel: '进入首页面时触发getCountry方法时最终得出的国家',
    attrs: attrs
  })
}
