let whatsappIcon = require('@/assets/images/common/whatsapp@2x.png')
let facebookIcon = require('@/assets/images/common/facebook@2x.png')
let lineIcon = require('@/assets/images/common/line@2x.png')
let messengerIcon = require('@/assets/images/common/messenger@2x.png')
let twitter = require('@/assets/images/common/twitter@2x.png')
let telegramIcon = require('@/assets/images/common/telegram@2x.png')

export const PLATFORM_NAME_MAP = {
  whatsapp: 'WhatsApp',
  line: 'Line',
  facebook: 'Facebook',
  twitter: 'Twitter',
  telegram: 'Telegram'
}

export const PLATFORM_ICON_MAP = {
  whatsapp: whatsappIcon,
  line: lineIcon,
  facebook: facebookIcon,
  messenger: messengerIcon,
  twitter: twitter,
  telegram: telegramIcon
}

export const PLATFORM_BRIDGE_METHOD = {
  whatsapp: 'shareWhatsApp',
  line: 'shareLine',
  facebook: 'shareFacebook',
  messenger: 'shareFBMessenger',
  twitter: 'shareTwitter',
  telegram: 'shareTelegram',
  sms: 'shareMessage',
  email: 'shareEmail'
}

export const COMMON_SHARE_CHANNELS = ['sms', 'email', 'systemEntrance']

export const PLATFORM_POINT = {
  whatsapp: 1,
  facebook: 2,
  twitter: 3,
  systementrance: 4,
  line: 5,
  telegram: 6
}
