<template>
  <div class="benefit-box">
    <div class="benefit-title">{{ benefitInfo.title }}</div>
    <div class="benefit-save-box">
      <div v-if="noBuy">
        <!-- showTemplate=1 预计可省模块 showTemplate=2 累计已省模块  -->
        <div v-if="isShowNewStyle" class="save-price-nobuy">
          <div v-if="isShowTemplate" class="save-price-expect" @click="getShowExpectContent">
            <span v-html="getPriceSubscriptionText"></span>
            <i class="icon-v3_outlined_entrance"></i>
          </div>
          <div v-else class="save-price-total">
            <span class="save-price-nobuy-left">{{ $t('FoodC_center_Ospi_SNsH') }}</span>
            <span class="save-price-nobuy-right">{{ saleInfo.showAmount }}</span>
          </div>
        </div>
      </div>
      <div v-else>
        <div v-if="hasAlreadySavedDisplay" class="save-price-buy">
          <div class="left-text">{{ alreadySavedDisplay.text }}</div>
          <div class="right-text">{{ alreadySavedDisplay.amount }}</div>
        </div>
      </div>
    </div>
    <div v-for="(item, index) of benefitInfo.content" :key="index" @click="goDetailed(item)">
      <div class="list">
        <div class="left"><img :src="item.iconUrl" alt="" /></div>
        <div class="right">
          <div v-if="item.title" class="main-title">
            <div class="title-desc">{{ item.title }}</div>
          </div>
          <div v-if="item.desc" class="main-title">
            <div class="minor-title">{{ item.desc }}</div>
          </div>
          <div v-if="!noBuy && item.totalAmount > 0" class="main-title">
            <div class="minor-title">
              {{ $t('FoodC_birthday_nxQg_feGV', { n: item.availableAmount }) }}
            </div>
          </div>
          <div class="main-title">
            <span v-if="item.birthday" class="birthday-title">{{
              $t('FoodC_birthday_teJB_YsTQ', { date: item.birthday })
            }}</span>
            <div v-if="item.receiveStatus === 0" class="setting-bir" @click.stop="goSettingBir">
              {{ $t('FoodC_birthday_MdbO_KlNx') }}
            </div>
            <div
              v-if="item.receiveStatus === 1 && !noBuy"
              class="setting-bir"
              @click.stop="getBirthdayCoupons"
            >
              {{ $t('FoodC_birthday_hJRO_UaMS') }}
            </div>
            <div
              v-if="item.receiveStatus === 3 && !noBuy"
              class="setting-bir"
              @click.stop="goCoupons"
            >
              {{ $t('FoodC_birthday_vcey_tequ') }}
            </div>
          </div>
        </div>
        <div v-if="item.detailedDesc">
          <i class="right-icon icon-v3_outlined_entrance"></i>
        </div>
      </div>
      <div v-if="index === benefitInfo.content.length - 1" class="list-box"></div>
    </div>
  </div>
</template>

<script>
import { isEmpty } from 'lodash-es'
export default {
  // props: ['benefitInfo', 'noBuy'],
  props: {
    noBuy: {
      type: Boolean,
      default: false,
      required: false,
    },
    benefitInfo: {
      type: Object,
      default: () => {},
    },
    saleInfo: {
      type: Object,
      default: () => {},
    },
    alreadySavedDisplay: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {}
  },
  computed: {
    // 是否命中实验展示新样式，1的时候展示新样式
    isShowNewStyle() {
      return +this.saleInfo?.showStyle
    },
    // 1:展示预计可省 2: 展示累计已省
    isShowTemplateAmount() {
      return +this.saleInfo?.showTemplate === 2
    },
    hasAlreadySavedDisplay() {
      return !isEmpty(this.alreadySavedDisplay)
    },
    // 1:展示预计可省 2: 展示累计已省
    isShowTemplate() {
      return +this.saleInfo?.showTemplate === 1
    },
    // 拼接样式
    getPriceSubscriptionText() {
      const money = `<span class="overbold-price">${this.saleInfo?.showAmount}</span>`
      return this.$t('FoodC_didipack_uwFV_TgLK', {
        money,
      })
    },
  },
  methods: {
    // 跳转到详情弹窗
    goDetailed(data) {
      const { detailedDesc = '' } = data
      if (!detailedDesc) {
        return
      }
      this.$emit('handleClick', data)
    },
    // 跳转到设置生日弹窗
    goSettingBir() {
      this.$emit('goSettingBir')
    },
    // 领取生日券
    getBirthdayCoupons() {
      this.$emit('getBirthdayCoupons')
    },
    // 跳转到优惠券中心
    goCoupons() {
      this.$emit('goCoupons')
    },
    // 预计可省说明-弹窗
    getShowExpectContent() {
      this.$emit('getShowExpectContent')
    },
  },
}
</script>

<style lang="scss" scoped>
.benefit-box {
  background: #fff;
  padding-left: 32px;
  padding-right: 32px;
}
.benefit-title {
  font-family: DiDiSans-Pro-Bold;
  font-size: 36px;
  color: #000000;
  line-height: 36px;
  padding-top: 48px;
  margin-bottom: 24px;
  // word-break: break-all;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; /* 这里是超出几行省略 */
  overflow: hidden;
}
// 累计已省&预计可省模块样式
// 未购买
.save-price-nobuy {
  margin: 40px auto 48px;
  background: url('../../../../assets/images/member/save_bg_new.png') no-repeat;
  background-size: 100% 100%;
  height: 100px;
  width: 686px;
  box-sizing: border-box;
  border-radius: 40px;
  font-family: DiDiSans-Pro-Medium;
  font-size: 28px;
  color: #000000;
  line-height: 32px;
  font-weight: 500;
  // 预计可省模块样式
  .save-price-expect {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    font-size: 24px;
    padding-left: 32px;
    padding-right: 16px;
    // 价格样式处理
    ::v-deep .overbold-price {
      font-family: DiDiSans-Pro-Bold;
      font-size: 40px;
      color: #ff8040;
      letter-spacing: 0;
      text-align: right;
      line-height: 44px;
      font-weight: 700;
    }
  }
  // 累计已省
  .save-price-total {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    padding: 0 32px;
    .save-price-nobuy-left {
      font-family: DiDiSans-Pro-Medium;
      font-size: 28px;
      color: #000000;
      line-height: 32px;
    }

    .save-price-nobuy-right {
      font-family: DiDiSans-Pro-Bold;
      font-size: 40px;
      color: #ff8040;
      line-height: 44px;
    }
  }
}
// 已购买
.save-price-buy {
  margin: 40px auto 48px;
  background: url('../../../../assets/images/member/save_bg_new.png') no-repeat;
  background-size: 100% 100%;
  height: 100px;
  width: 686px;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .left-text {
    margin-left: 32px;
    font-family: DiDiSans-Pro-Medium;
    font-size: 28px;
    color: #000000;
    line-height: 32px;
  }

  .right-text {
    font-family: DiDiSans-Pro-Bold;
    font-size: 40px;
    color: #ff8040;
    line-height: 44px;
    margin-right: 30px;
  }
}
.list {
  display: flex;
  align-items: center;
  padding: 16px 0;
  margin-bottom: 16px;
  .left {
    img {
      width: 100px;
      height: 100px;
      margin-right: 32px;
    }
  }
  .right {
    flex: 1;
    margin-right: 32px;
    .main-title {
      display: flex;
      .title-desc {
        flex: 1;
        font-family: DiDiSans-Pro-Bold;
        font-size: 32px;
        color: #000000;
        line-height: 40px;
        // word-break: break-all;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2; /* 这里是超出几行省略 */
        overflow: hidden;
      }
      .num {
        font-family: DiDiSans-Pro-Bold;
        font-size: 32px;
        color: #000000;
        line-height: 40px;
        width: 80px;
        text-align: right;
        position: relative;
        span {
          position: absolute;
          right: 0;
          bottom: 0;
        }
      }
    }

    .minor-title {
      flex: 1;
      margin-top: 8px;
      opacity: 0.6;
      font-family: DiDiSans-Pro-Regular;
      font-size: 24px;
      color: #000000;
      line-height: 32px;
      // word-break: break-all;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2; /* 这里是超出几行省略 */
      overflow: hidden;
    }
    .setting-bir {
      flex-grow: 1;
      text-decoration-line: underline;
      font-family: DiDiSans-Pro-Bold;
      margin-top: 8px;
      font-size: 24px;
      color: #ff8040;
      line-height: 30px;
      font-weight: 400;
    }
    .birthday-title {
      line-height: 30px;
      white-space: nowrap;
      margin-top: 8px;
      margin-right: 24px;
      opacity: 0.6;
      font-family: DiDiSans-Pro-Regular;
      font-size: 24px;
      color: #000000;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2; /* 这里是超出几行省略 */
      overflow: hidden;
    }
  }
  .right-icon {
    font-size: 24px;
    width: 24px;
    height: 24px;
    opacity: 0.6;
  }
}
.list-box {
  height: 32px;
}
</style>
