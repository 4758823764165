<template>
  <div :class="['pause-subscribe-page', { 'pause-subscribe-page-bg': !loading }]">
    <ImmersionHeader
      ref="ImmersionHeader"
      :isShowBackBtn="true"
      :blackTheme="true"
      @BackCK="BackCK"
    />
    <div v-if="!loading" class="pause-subscribe-box">
      <div class="pause-subscribe-box-content">
        <div class="pause-subscribe-text">
          <div class="pause-subscribe-title">{{ $t('FoodC_pause_izJa_kTkD') }}</div>
          <div class="pause-subscribe-sub">
            {{ $t('FoodC_pause_cYix_reoR', { brand: brandName, date: pauseStartDate }) }}
          </div>
        </div>
        <div class="page-line"></div>
        <div class="pause-subscribe-radio">
          <rich-radio-group
            v-model="unsubscribeTime"
            class="radio-list"
            :options="pauseOption"
            position="right"
          />
        </div>
        <div class="page-line"></div>
        <div class="rule-desc" @click="openRule($event)" v-html="getRuleDesc"></div>
      </div>
      <div class="btn-container">
        <rich-button class="btn-goback-member" @click="goPauseMember">
          {{ $t('FoodC_pause_pfAh_qUrD') }}
        </rich-button>
        <rich-button outline="true" @click="cancelPause">
          {{ $t('FoodC_pause_xeiI_PONf') }}
        </rich-button>
      </div>
    </div>
    <LoadingMask v-if="loading" />
    <!-- 订阅的规则 -->
    <Rules
      v-model="isShowRuleDialog"
      :rules="rules"
      title="FoodC_subscription_VPMP_seXT"
      zIndex="9999999"
    />
  </div>
</template>

<script>
import ImmersionHeader from '@/components/immersionHeader/index.vue'
import LoadingMask from '@/components/loading/loading-mask.vue'
import Rules from '../../components/rules/index.vue'
import { mapState } from 'vuex'
import { membershipPauseSw, membershipPauseCk } from '../../trackEvent.js'
import { getPausePageInfoDetail, pauseMembershipSubscription } from '@/api/member/index.js'
export default {
  components: {
    ImmersionHeader,
    LoadingMask,
    Rules,
  },
  data() {
    return {
      loading: false,
      headerHeight: 0,
      pausePageInfoDetail: {},
      unsubscribeTime: 30,
      isShowRuleDialog: false,
    }
  },
  computed: {
    ...mapState(['membership']),
    rules() {
      return this.membership.subscriptionRules
    },
    getRuleDesc() {
      return this.$t('FoodC_pause_iXVD_owuh', {
        didifoodbrand: this.brandName,
        date: this.pauseAutoResumeDate,
        money: this.renewPriceSplit,
        tc: `<span class="rule-tc">${this.$t('FoodC_subscription_VPMP_seXT')}</span>`,
      })
    },

    // 展示到期的时间
    pauseAutoResumeDate() {
      if (+this.unsubscribeTime === 30) {
        return this.pauseOption[0]?.pauseAutoResumeDate
      } else if (+this.unsubscribeTime === 60) {
        return this.pauseOption[1]?.pauseAutoResumeDate
      }
      return ''
    },
    // 选项
    pauseOption() {
      return this.pausePageInfoDetail?.pauseOption || []
    },
    brandName() {
      return this.pausePageInfoDetail?.brandName || ''
    },
    pauseStartDate() {
      return this.pausePageInfoDetail?.pauseStartDate || ''
    },
    renewPriceSplit() {
      return this.pausePageInfoDetail?.renewPriceSplit?.display || ''
    },
    // 判断是不是从原因选择页面进入的
    isFromSelectedReason() {
      return this.params?.pauseSubscribeFrom === 'selectedReasonPage'
    },
  },
  created() {
    this.getPausePageInfoDetail()
  },
  mounted() {
    this.headerHeight = this.$refs?.ImmersionHeader?.$el?.offsetHeight || 0
    membershipPauseSw()
  },
  methods: {
    async getPausePageInfoDetail() {
      this.loading = true
      const res = await getPausePageInfoDetail()
      this.pausePageInfoDetail = res.data
      this.loading = false
    },
    BackCK() {
      window.history.go(-1)
    },
    // 暂停会员订阅
    async goPauseMember() {
      // 上报埋点
      let pausePeriod = 0
      if (+this.unsubscribeTime === 30) {
        pausePeriod = 1
      } else if (+this.unsubscribeTime === 60) {
        pausePeriod = 2
      }
      this.loading = true
      let res = {}
      try {
        res = await pauseMembershipSubscription({
          pauseDays: this.unsubscribeTime,
        })
      } catch {}
      this.membershipPauseCkFn(1, pausePeriod)
      if (res?.errno === 0) {
        const toast2 = this.$createToast({
          txt: this.$t('FoodC_pause_NJOj_iqnX'),
          time: 1000,
          type: 'warn',
          mask: false,
        })
        toast2.show()
      }
      setTimeout(() => {
        // 「会员页 -> 管理页面 -> 取消原因选择页 -> 暂停页面」 返回会员页面go(-3)
        // 「会员页 -> 管理页面 -> 暂停页面」返回会员页面go(-2)
        if (this.isFromSelectedReason) {
          this.$router.go(-3)
        } else {
          this.$router.go(-2)
        }
      }, 1000)
      this.loading = false
    },
    // 不需要，返回上一级
    cancelPause() {
      this.membershipPauseCkFn(2)
      setTimeout(() => {
        window.history.go(-1)
      }, 300)
    },
    // 跳转到规则页面
    openRule(el) {
      const isRule = el.target.className === 'rule-tc'
      if (isRule) {
        this.isShowRuleDialog = true
      }
    },
    // 挽留页面CK埋点上报
    membershipPauseCkFn(type, pause_period = '') {
      membershipPauseCk({
        click_type: type,
        pause_period,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.pause-subscribe-page {
  height: 100%;
  background-color: #ffffff;
  .pause-subscribe-box {
    padding-top: 480px;
    .pause-subscribe-box-content {
      padding: 48px 32px 0px;
      box-sizing: border-box;
      background-color: #ffffff;
      border-radius: 60px 60px 0 0;
      min-height: calc(100% - 348px);
      padding-bottom: 348px;
      .pause-subscribe-text {
        margin-bottom: 24px;
        .pause-subscribe-title {
          line-height: 60px;
          font-family: DiDiSans-Pro-Bold;
          font-size: 52px;
          color: #000000;
        }
        .pause-subscribe-sub {
          margin-top: 18px;
          line-height: 34px;
          opacity: 0.6;
          font-family: DiDiSans-Pro-Regular;
          font-size: 28px;
          color: #000000;
          letter-spacing: 0;
        }
      }
      // 单选框样式
      .pause-subscribe-radio {
        ::v-deep .radio-list {
          .rich-radio {
            height: 130px;
            .rich-radio-text {
              font-family: DiDiSans-Pro-Regular;
              font-size: 28px;
              color: #000000;
              letter-spacing: 0;
            }
          }
        }
      }
      // 规则样式
      .rule-desc {
        margin-top: 48px;
        line-height: 40px;
        font-family: PingFangSC-Regular;
        font-size: 28px;
        color: rgba(0, 0, 0, 0.6);
        letter-spacing: 0;
      }
      ::v-deep .rule-tc {
        font-family: DiDiSans-Pro-Medium;
        font-size: 28px;
        color: #000000;
        line-height: 28px;
        text-decoration: underline;
      }
    }
  }

  .page-line {
    width: 100%;
    height: 1px;
    background: rgba(0, 0, 0, 0.08);
  }
}
// 头部背景图
.pause-subscribe-page-bg {
  background-image: url('../../../../assets/images/member/pause_header.png');
  background-size: 750px 540px;
  background-repeat: no-repeat;
}
/* 按钮样式 */
.btn-container {
  background-color: #ffffff;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 8;
  width: 100%;
  box-sizing: border-box;
  padding: 0 32px 45px;
  border-radius: 40px 40px 0 0;
  box-shadow: 0px -20px 40px 0px rgba(0, 0, 0, 0.04);
}
.btn-goback-member {
  margin: 24px 0;
}
</style>
