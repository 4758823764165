import trackEvent from '@/common/js/track'
class Logs {
  async entry(params) {
    const { eventId, eventLabel, attrs = {} } = params;
    // 上报
    trackEvent({
      eventId,
      eventLabel,
      attrs
    })
    // 来源: 3.0.16埋点解藕需求，先写在这里，后面DS提需求下掉老埋点以后需要移出来
    if(eventId === 'sailing_c_x_homepage_realexposure_sw'){
      const attrsNew = {
        shop_id: attrs?.shop_id,
        rec_id: attrs?.content_json?.rec_id,
        trace_id: attrs?.trace_id,
        delivery_time: attrs?.content_json?.delivery_time || 0,
        delivery_fee: attrs?.content_json?.delivery_fee,
        is_free_delivery_type: attrs?.is_free_delivery_type,
        nlp_category_id: attrs?.content_json?.nlp_category_id,
        trending_type: attrs?.content_json?.trending_type,
        from_channel: attrs?.content_json?.from_channel,
        homepage_absolute_position: attrs?.content_json?.homepage_absolute_position
      }
      trackEvent({
        eventId:'sailing_c_x_trending_landingpage_shop_sw',
        eventLabel: '榜单落地页店铺实际曝光',
        attrs: attrsNew
      })
    }
  }
}

const log = new Logs();

/**
 * @desc 埋点上报
 * @param {Object} data
 * */
export function initTrackEvent(data) {
  return log.entry(data);
}
