import { getLocale, VueJed, getLanguageLong } from '@didi/gettext'
// import enUS from '../../locale/en-US/soda-act.po'
// import zhCN from '../../locale/zh-CN/soda-act.po'
// import esMX from '../../locale/es-MX/soda-act.po'
// import ptBR from '../../locale/pt-BR/soda-act.po'
// import jaJP from '../../locale/ja-JP/soda-act.po'

import enUS from '../../i18n/en-US'
import zhCN from '../../i18n/zh-CN'
import esMX from '../../i18n/es-MX'
import esCO from '../../i18n/es-CO'
import ptBR from '../../i18n/pt-BR'
import jaJP from '../../i18n/ja-JP'
import es419 from '../../i18n/es-419'

// import Vue from 'vue'

const map = {
  'zh-CN': zhCN,
  'en-US': enUS,
  'es-MX': esMX,
  'es-CO': esCO,
  'pt-BR': ptBR,
  'ja-JP': jaJP,
  'es-419': es419
}
const locale = getLocale()
const lang = getLanguageLong()
export function i18nInterceptors (config) {
  if (config.method === 'post') {
    config.data = {
      ...config.data,
      locale
    }
  } else if (config.method === 'get') {
    config.params = {
      ...config.params,
      locale
    }
  }
  return config
}

// 这里注释掉的功能，是解决异步获取locale的方法，暂时不用
// let i18n = null

// export function initVueJed (locale) {
//   console.log('locale78877', locale)
//   i18n = new VueJed(map[locale])
//   Vue.use(i18n.plugin)
//   // axios.interceptors.request.use(i18nInterceptors, error => Promise.reject(error))
//   // didiaxios.interceptors.request.use(i18nInterceptors, error => Promise.reject(error))
//   return i18n
// }

// export default {
//   gettext: (key) => {
//     return i18n ? i18n._(key) : key
//   }
// }

export default new VueJed(map[lang])
