export const TELNUM = {
  MX: '8008016888',
  BR: '03003130099',
  JP: '0120132355',
  CR: '50641063372',
  CO: '5715801398',
  DO: '(+) 18 29956 8643',
  CL: '(+56)227120527'
}
// 客服邮箱
export const EMAIL = {
  MX: 'soporte.food@mx.didiglobal.com',
  BR: '', // 巴西暂时没有邮箱配置
  JP: 'customer@didifood.co.jp',
  CR: '',
  CO: 'didifood.co@didiglocal.com',
  DO: 'didifood.do@didiglocal.com',
  CL: '' // 智利暂不配置邮箱
}
