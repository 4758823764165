<template>
    <div class="container" v-if="bubbleContent">
        {{ bubbleContent }}
    </div>
</template>

<script>
export default {
    name: 'Bubble',
    props: {
        bubbleContent: {
            default: ''
        }
    }
}
</script>

<style lang="scss" scoped>
.container {
    padding: 0 16px;
    height: 40px;
    border-radius: 22px 22px 22px 0;
    background: #31B77E;
    border: 2px solid rgba(255, 255, 255, 0.88);
    font-family: Aspira Demi;
    font-size: 20px;
    color: #FFFFFF;
    text-align: left;
    line-height: 42px;
}
</style>