import didiaxios from '@didi/dajax/dist/dajax'
import apiConfig from '@/common/js/apiConfig'
import {getUrlParams} from '@/common/js/utils'
const BASE_URL = apiConfig.BASE_API_ACT
// const BASE_URL = "https://mock.xiaojukeji.com/mock/776"
export const getPreviewActivity = function (data) {
  return didiaxios.request({
    url: BASE_URL + '/act-api/Api/Activitypage/preview',
    method: 'GET',
    emulateJSON: true,
    needEncrypt: true,
    params: data
  })
}

/*
* getContentWebp/getContentN:商户落地页 mock
* getContentItem:菜品落地页 mock
* getContentLX:拉新落地页 mock
*/
export const getActivityInfo = function (data) {
  const {threshold = '', discount = ''} = getUrlParams()

  return didiaxios.request({
    url: BASE_URL + '/act-api/Api/Activitypage/getContent',
    // url: 'https://mock.xiaojukeji.com/mock/776/act-api/Api/Activitypage/getContentWebp', // 商户落地页webp测试
    // url: 'https://mock.xiaojukeji.com/mock/612/act/Api/Activitypage/getContent',
    // url: 'https://mock.xiaojukeji.com/mock/776/act-api/Api/Activitypage/getContentItem', // 菜品落地页
    // url: 'https://mock.xiaojukeji.com/mock/776/act-api/Api/Activitypage/getContentItemCart', // 菜品加购页面
    // url: 'https://mock.xiaojukeji.com/mock/776/act-api/Api/Activitypage/getContentLX', // 拉线落地页
    // url: 'https://mock.xiaojukeji.com/mock/776/act-api/Api/Activitypage/getRecList', // 菜品落地页翻页
    method: 'POST',
    emulateJSON: true,
    needEncrypt: true,
    params: '',
    body: {
      ...data,
      threshold,
      discount
    }
  })
}

export const getCouponList = function (data) {
  return didiaxios.request({
    url: BASE_URL + '/act-api/Api/Activitypage/getCoupon',
    method: 'POST',
    emulateJSON: true,
    needEncrypt: true,
    params: '',
    body: data
  })
}

export const getNearList = function (data) {
  return didiaxios.request({
    url: BASE_URL + '/act-api/Api/Activitypage/getNearList',
    method: 'POST',
    emulateJSON: true,
    needEncrypt: true,
    params: '',
    body: data
  })
}

export const getRecList = function (data) {
  // r coupon特殊映射使用
  const {threshold = '', discount = ''} = getUrlParams
  return didiaxios.request({
    url: BASE_URL + '/act-api/Api/Activitypage/getRecList',
    method: 'POST',
    emulateJSON: true,
    needEncrypt: true,
    params: '',
    body: {...data, threshold, discount}
  })
}
