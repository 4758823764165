export default
{
  path: '/couponCenterDetail',
  name: 'couponCenterDetail',
  meta: {
    requiresAuth: true,
    title: 'couponCenterDetail'
  },
  component: () => import(/* webpackChunkName: "couponCenter" */ '@/page/couponCenter/components/couponCenterDetail/index.vue')
}
