<template>
  <div class="cancel-take">
    <ImmersionHeader
      ref="ImmersionHeader"
      :title="$t('FoodC_subscription_QaXu_EIIa', { didipackbrand: brandName })"
      :blackTheme="true"
      :titleStyle="titleStyle"
      :isMonitorScroll="false"
      @BackCK="BackCK"
    />
    <div v-if="!loading" :style="{ 'margin-top': headerHeight + 'px' }">
      <div v-if="isChangPaymentBridgeVersion" class="payment-box">
        <div class="title">{{ $t('FoodC_V2_RUXY_aLKJ') }}</div>
        <ChangePayment
          :paymentMethod="paymentMethod"
          :hasCardInfo="hasCardInfo"
          @changePaymentFn="changePaymentFn"
        />
      </div>
      <div class="title">{{ $t('FoodC_subscription_XfQl_RWPP') }}</div>
      <div v-if="isPauseFlowVersion" class="cancel-btn pause-btn" @click="pauseClick">
        <div class="text">
          {{ $t('FoodC_pause_Wukv_RhXR') }}
        </div>
        <div class="icon"></div>
      </div>

      <div class="cancel-btn" @click="cancelClick">
        <div class="text">
          {{ $t('FoodC_subscription_CtEg_IEoy', { didipackbrand: brandName }) }}
        </div>
        <div class="icon"></div>
      </div>
    </div>

    <LoadingMask v-else />
    <Popup
      :isShow="shows"
      :title="$t('FoodC_subscription_CtEg_IEoy', { didipackbrand: brandName })"
      @clickMask="shows = false"
    >
      <div slot="content" class="cancel-wrapper">
        <div class="wrapper-text">{{ cancelPackText }}</div>
        <div class="rule-btn">
          <rich-button @click="cancelPack">{{
            $t('FoodC_subscription_gNZb_xfcd', { didipackbrand: brandName })
          }}</rich-button>
          <div class="keep" @click="keepPage">{{ $t('FoodC_subscription_arhI_yUex') }}</div>
        </div>
      </div>
    </Popup>
  </div>
</template>

<script>
import {
  getUnsubscribeMembership,
  getRenewPaymentMethod,
  changeRenewPaymentMethod,
} from '@/api/member/index.js'
import Popup from '@/components/vant-popup'
import LoadingMask from '@/components/loading/loading-mask.vue'
import ImmersionHeader from '@/components/immersionHeader/index.vue'
import ChangePayment from '../changePayment/index.vue'
import { mapState } from 'vuex'
import { getPaymentSDKVersion } from '@/common/js/bridge.js'
import { SCENE } from '../../common/enumsUtil'
import { isEmpty } from 'lodash-es'

export default {
  components: {
    Popup,
    LoadingMask,
    ImmersionHeader,
    ChangePayment,
  },
  data() {
    return {
      loading: false,
      shows: false,
      titleStyle: {
        color: '#000000',
        alpha: 1,
      },
      headerHeight: 0,
      paymentMethod: {},
      hasCardInfo: false,
    }
  },
  computed: {
    ...mapState(['brandName']),
    getExpireDate() {
      // url上带着，填充
      return decodeURIComponent(this.params?.expireDate) || ''
    },
    isChangPaymentBridgeVersion() {
      // eslint-disable-next-line eqeqeq
      return this.params?.isChangPaymentBridgeVersion == 1
    },
    tagType() {
      // eslint-disable-next-line eqeqeq
      return this.params?.tagType == 1
    },
    // 是否命中取消流程迭代的实验
    isFlowVersion() {
      // eslint-disable-next-line eqeqeq
      return this.params?.flowVersion == 1
    },
    // 暂停订阅实验
    isPauseFlowVersion() {
      return +this.params?.isPauseFlowVersion || 0
    },
    // 点击取消订阅提示文案，根据是否是0元购会员展示不同的文案  tagType=1 时为0元购，默认tagType=0
    cancelPackText() {
      if (this.tagType) {
        return this.$t('FoodC_subscription_jyMF_Ybuq', { brand: this.brandName })
      } else {
        return this.$t('FoodC_subscription_uoAf_TiHz', {
          didipackbrand: this.brandName,
          date: this.getExpireDate,
        })
      }
    },
  },
  mounted() {
    this.headerHeight = this.$refs?.ImmersionHeader?.$el?.offsetHeight || 0
    if (this.isChangPaymentBridgeVersion) {
      this.initData()
    }
  },
  methods: {
    initData() {
      getPaymentSDKVersion({}, async ({ version }) => {
        this.loading = true
        const res = await getRenewPaymentMethod({ scene: SCENE.RENEW, cashierSDKVersion: version })
        if (res.errno === 0) {
          this.paymentMethod = res?.data?.paymentMethod || {}
          if (!isEmpty(this.paymentMethod)) {
            this.hasCardInfo = true
          }
        }
        this.loading = false
      })
    },
    cancelClick() {
      if (this.isFlowVersion) {
        this.$router.push({
          path: '/selectedReason',
          query: {
            nativeWebUIType: 1,
            isPauseFlowVersion: this.isPauseFlowVersion,
          },
        })
      } else {
        this.shows = true
      }
    },
    // 跳转到暂停时间选择页面
    pauseClick() {
      this.$router.push({
        path: '/pauseSubscribe',
        query: {
          nativeWebUIType: 1,
        },
      })
    },
    // 更改支付方式
    async changePaymentFn(val) {
      // 修改了支付方式
      if (val?.changed === 1) {
        // 更新当前视图
        const payMethod = val?.pay_method?.[0] || {}
        const paymentMethod = {
          channelID: payMethod?.channel_id * 1,
          cardIndex: payMethod.extra_info?.card_index || 0,
        }

        const res = await changeRenewPaymentMethod(paymentMethod)
        if (res.errno === 0) {
          // 成功
          this.initData()
        }
      }
    },
    BackCK() {
      window.history.go(-1)
    },
    async cancelPack() {
      this.loading = true
      let res = {}
      try {
        res = await getUnsubscribeMembership()
      } catch {}
      // eslint-disable-next-line eqeqeq
      if (res?.errno == 0) {
        this.shows = false
        this.$router.go(-1)
      } else {
        this.$createToast({
          type: 'warn',
          time: 2000,
          txt: res.errmsg,
        }).show()
      }
      this.loading = false
    },
    keepPage() {
      this.BackCK()
    },
  },
}
</script>

<style lang="scss" scoped>
.cancel-take {
  padding: 0 32px;
  background: #fafafa;
  height: 100%;
  overflow: hidden;
}
.payment-box {
  margin-bottom: 16px;
}
.title {
  font-family: DiDiSans-Pro-Bold;
  font-size: 36px;
  color: #000000;
  line-height: 43px;
  margin-bottom: 32px;
  padding-top: 48px;
}
.cancel-btn {
  background: #ffffff;
  box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.04);
  border-radius: 40px;
  display: flex;
  align-items: center;
  padding: 40px 24px;
  justify-content: space-between;
  .text {
    font-family: DiDiSans-Pro-Regular;
    font-size: 28px;
    color: #000000;
    line-height: 28px;
  }
  .icon {
    width: 28px;
    height: 28px;
    background-image: url('../../../../assets//images/member/right_arrow.png');
    background-size: 100% 100%;
  }
}
.pause-btn {
  margin-bottom: 24px;
}
.cancel-wrapper {
  .wrapper-text {
    opacity: 0.6;
    font-family: DiDiSans-Pro-Regular;
    font-size: 28px;
    color: #000000;
    line-height: 34px;
    padding-top: 40px;
    padding-bottom: 80px;
  }
  .rule-btn {
    .rich-btn {
      font-weight: normal;
    }
    .keep {
      text-align: center;
      font-family: DiDiSans-Pro-medium;
      font-size: 32px;
      line-height: 96px;
      height: 96px;
      color: #e08a5a;
    }
  }
}
</style>
