<template>
  <div v-if="isImmersion" class="isFixed">
    <div class="header-box" :style="getHeaderBackgroundStyle">
      <div class="bar" :style="{ height: getAppBarHeight }"></div>
      <div class="header">
        <div class="back-btn">
          <div v-if="isShowBackBtn" class="back-btn-click" @click="BackCK">
            <i :class="leftIconClassName" :style="getBackStyle"></i>
          </div>
        </div>
        <div v-if="title" class="title" :style="getTitleStyle">{{ title }}</div>
        <div class="right-text">
          <span
            v-if="isShowRightButton"
            class="right-text-click"
            :style="getRightTextStyle"
            @click="rightBtnCK"
            >{{ rightText }}</span
          >
        </div>
      </div>
    </div>
    <div ref="headerBoxTab" class="header-box-tab-box">
      <div class="bar" :style="{ height: getAppBarHeight }"></div>
      <div class="header-box-tab"></div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { util } from '@didi/soda-common'
import { isEmpty, throttle } from 'lodash-es'
const { env } = util

export default {
  props: {
    isMonitorScroll: {
      type: Boolean,
      default: true,
    },
    isShowBackBtn: {
      type: Boolean,
      default: true,
    },
    isShowRightButton: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    rightText: {
      type: String,
      default: '',
    },
    blackTheme: {
      type: Boolean,
      default: false,
    },
    titleStyle: {
      type: Object,
      default: () => {},
    },
    leftIconClassName: {
      type: String,
      default: 'icon-v3_outlined_back',
    },
  },
  data() {
    return {
      tittle: '',
      isShowRightBtn: false,
      alpha: 0, // 导航栏透明度是否达到。 1是完全不透明。 0是透明
      navConfig: {
        sysStatusBar: 1, // 0黑色,1白色
        nav: {
          // titlebar背景条
          color: '#58bc58',
          alpha: 0,
          hidden: 1, // 隐藏头部
        },
      },
      headerConfig: {
        backgroundStyle: {
          r: 255,
          g: 255,
          b: 255,
          a: 0,
        },
        back: {
          color: '#FFFFFF',
        },
        title: {
          color: '#FFFFFF',
          alpha: 0,
        },
        rightText: {
          color: '#FFFFFF',
        },
      },
    }
  },
  computed: {
    isImmersion() {
      // eslint-disable-next-line eqeqeq
      return this.params.nativeWebUIType * 1 == 1 || this.params.nativeWebUIType * 1 == 2
    },
    getAppBarHeight() {
      const statusBarHeight = this.$store.state.globalParams?.data?.statusBarHeight
      return this.comStatusBarHeight(statusBarHeight) + 'px'
    },
    getHeaderBackgroundStyle() {
      const bgStyle = this.headerConfig.backgroundStyle
      return {
        background: `rgba(${bgStyle.r},${bgStyle.g},${bgStyle.b},${bgStyle.a})`,
      }
    },
    getBackStyle() {
      return {
        color: this.headerConfig.back.color,
      }
    },
    getTitleStyle() {
      if (isEmpty(this.titleStyle)) {
        return {
          color: this.headerConfig.title.color,
          opacity: this.headerConfig.title.alpha,
        }
      } else {
        return this.titleStyle
      }
    },
    getRightTextStyle() {
      return {
        color: this.headerConfig.rightText.color,
      }
    },
  },
  watch: {
    blackTheme: {
      immediate: true,
      handler: function (val) {
        if (val) {
          this.navConfig.sysStatusBar = 0
          this.headerConfig.back.color = '#000000'
          this.headerConfig.rightText.color = '#000000'
        } else {
          this.navConfig.sysStatusBar = 1
          this.headerConfig.back.color = '#FFFFFF'
          this.headerConfig.rightText.color = '#FFFFFF'
        }
        this.isImmersion && this.updateNav(this.navConfig)
      },
    },
  },
  async created() {
    await this.getGlobalParams()
    // 设置标题栏及状态栏初始样式
    this.isImmersion && this.updateNav(this.navConfig)
  },
  mounted() {
    if (this.isMonitorScroll) {
      // 监听落地页页面滚动事件
      document.addEventListener('scroll', this.handleScroll, true)
    }
  },
  destroyed() {
    document.removeEventListener('scroll', this.handleScroll, true)
  },
  methods: {
    ...mapActions({
      getGlobalParams: 'getGlobalParams',
    }),
    comStatusBarHeight(statusBarHeight = 0) {
      const DPRNum = env.isIOS ? 2 : window.devicePixelRatio
      return statusBarHeight / DPRNum
    },
    updateNav(params) {
      this.SodaBridge('updateNav', params)
    },
    BackCK() {
      this.$emit('BackCK')
    },
    rightBtnCK() {
      this.$emit('rightBtnCK')
    },
    // 滚动监听的方法
    handleScroll() {
      const absY = Math.abs(document.documentElement.scrollTop || document.body.scrollTop)
      // 临界值,上滑到90的时候 titlebar要透明度100%的展现出来
      const criticalY = 104
      const percent = absY / criticalY
      this.isImmersion && this.setNavStyle(percent)
    },
    setNavStyle: throttle(function (percent) {
      // 设置导航栏颜色
      // 返回按钮的颜色
      // 返回按钮透明度
      // 标题颜色
      // 标题透明度
      // 右侧按钮颜色
      // 右侧按钮透明度
      // 背景颜色，
      // 背景透明度
      this.headerConfig.backgroundStyle.a = percent
      if (percent <= 0) {
        this.navConfig.sysStatusBar = 1 // 白色
        this.headerConfig.title.color = '#FFFFFF'
        this.headerConfig.title.alpha = 0
        if (this.blackTheme) {
          this.navConfig.sysStatusBar = 0
        } else {
          this.headerConfig.back.color = '#FFFFFF'
          this.headerConfig.rightText.color = '#FFFFFF'
        }
      } else if (percent <= 1) {
        this.navConfig.sysStatusBar = 0 // 黑色
        this.headerConfig.back.color = '#000000'
        this.headerConfig.title.color = '#000000'
        this.headerConfig.title.alpha = percent
        this.headerConfig.rightText.color = '#000000'
      } else {
        if (this.alpha < 1) {
          this.navConfig.sysStatusBar = 0 // 黑色
          this.headerConfig.back.color = '#000000'
          this.headerConfig.title.color = '#000000'
          this.headerConfig.title.alpha = percent
          this.headerConfig.rightText.color = '#000000'
        }
      }
      this.alpha = percent
      this.updateNav(this.navConfig)
    }, 10),
  },
}
</script>

<style lang="scss" scoped>
.header {
  height: 88px;
  display: flex;
  align-items: center;
  padding: 0 32px;
  .back-btn {
    width: 30%;
    .back-btn-click {
      width: 48px;
      height: 88px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    i {
      font-size: 36px;
    }
  }
  .title {
    flex: 1;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 32px;
  }
  .right-text {
    width: 30%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: DiDiSans-Pro-Medium;
    font-size: 28px;
    line-height: 32px;
    text-align: right;
    font-weight: 500;

    .right-text-click {
      padding: 20px 0;
    }
  }
}
.header-box-tab-box {
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
}
.header-box-tab {
  height: 70px;
}
.isFixed {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 999999;
}
</style>
