import xtd from './xtd'

export default {
  path: '/d',
  name: 'd',
  meta: {
    requiresAuth: true
  },
  component: () => import(/* webpackChunkName: "xtd" */ '@/page/d/xtd/index.vue'),
  children: [
    xtd
  ]
}
