
<template>
    <div class="subscribeFooter" :class="{'subscribeFooter-flow' : flowVersion == 1}">
        <!-- 命中实验的时候，在订阅按钮上方展示过期时间 -->
        <div v-if="isShowFooterTimeInfo" class="footer-info-time">
            {{ headInfo.validDateDesc }}
        </div>
        <div class="btn-container">
            <rich-button @click="joinSubscribe">
                {{ $t('FoodC_V2_NigJ_XaWd', {didipackbrand: brandName}) }}
            </rich-button>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
    name: 'SubscribeFooter',
    props:{
        flowVersion:{
            type:Number,
            default:0
        },
        headInfo:{
            type:Object,
            default:()=>{}
        }
    },
    computed: {
    ...mapState(['brandName']),
    isShowFooterTimeInfo(){
        return this.headInfo?.validDateDesc && this.flowVersion
    }
    },
    methods: {
        joinSubscribe() {
            this.$emit('joinSubscribe')
        },
    }

}
</script>
<style lang="scss" scoped>
.subscribeFooter {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 8;
    width: 100%;
    background: #ffffff;
    padding: 36px 24px 45px 24px;
    box-sizing: border-box;
    border-radius: 40px 40px 0 0;
    box-shadow: 0px -10px 48px 0px rgba(0,0,0,0.06);
    .footer-info-time{
        margin-bottom: 24px;
        line-height: 33px;
        font-family: DiDiSans-Pro-Bold;
        font-size: 28px;
        color: #FF4060;
        text-align: center;
    }
    .rich-btn {
        height: 108px;
    }
}
.subscribeFooter-flow{
    padding-top: 32px;
}
</style>