import state from './state'
import mutations from './mutations'
import * as actions from './actions'

import schema from '../../common/js/schema'

export default {
  state,
  mutations,
  actions,
  schema
}
