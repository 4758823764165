import xtc from './xtc'
import coupon from './coupon'
import staticPage from './staticpage'
import oldCustomer from './oldCustomer'
import couponCenter from './couponCenter'
import couponHistory from './couponHistory'
import couponCenterDetail from './couponCenterDetail'
import debugbridge from './debugbridge'
import titleTestPage from './titleTestPage'
import member from './member'
import pageIndex from '@/page/index.vue'

export default {
  path: '/c',
  name: 'c',
  meta: {
    requiresAuth: true
  },
  component: pageIndex,
  children: [
    xtc,
    coupon,
    staticPage,
    oldCustomer,
    couponCenter,
    couponHistory,
    couponCenterDetail,
    titleTestPage,
    debugbridge,
    ...member,
  ]
}
