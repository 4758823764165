import i18n from '@/common/js/i18n'

export default {
  path: '/newCouponsCenter',
  name: 'newCouponsCenter',
  meta: {
    title: i18n.gettext('优惠券中心')
  },
  component: () => import(/* webpackChunkName: "newCouponCenter" */ '@/page/newCouponsCenter/index')
}
