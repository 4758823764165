<template>
    <div class="old-member-box">
        <LoadingMask v-if="loading" />
        <CouponHeader 
            v-if="!noPageInfo"
            :headInfo="headInfo" 
            :benefitInfo="benefitInfo" 
            :noBuy="noBuy" 
            :isBuyAndNoSubscriptionStatus="isBuyAndNoSubscriptionStatus"
            @goDetailed="goDetailed"
            @goSettingBir="goSettingBir"
            @getBirthdayCoupons="getBirthdayCoupons"
            @goCoupons="goCoupons"
        />
        <!-- 商家券权益弹窗 -->
        <Rules
            v-model="isShowDetailedDialog"
            :rules="detailedDesc"
            title="FoodB_Part2_Detailed_description_mxuF"
        />
        <couponSuccessDialog 
            v-model="isShowCouponSuccessDialog" 
            @goCoupons="goCoupons"
          />
    </div>
</template>

<script>
import CouponHeader from '../couponHeader/index.vue'
import LoadingMask from "@/components/loading/loading-mask.vue"
import Rules from '../rules/index.vue'
import CouponSuccessDialog from '../couponSuccessDialog/index.vue'

import { getOldMembershipDetail, updateUserInfo, getBirthdayBenefit } from '@/api/member/index.js'
import { isEmpty } from 'lodash-es'
import { COUPON_URL_DIDI } from '../../common/enumsUtil.js'
export default {
    components:{
        CouponHeader,
        LoadingMask,
        Rules,
        CouponSuccessDialog
    },
    computed:{
        noBuy() {
            return this.membership?.status === 1
        },
         // 购买后，且未开通订阅
        isBuyAndNoSubscriptionStatus() {
            return !this.noBuy && this.noSubscriptionStatus
        },
         // 订阅状态：1-未订阅  2-已订阅
        noSubscriptionStatus() {
            return this.membership?.subscriptionStatus === 1;
        },
        noPageInfo() {
            return isEmpty(this.pageInfo)
        },
    },
    data() {
        return {
            loading: false,
            pageInfo: {},
            headInfo: {},
            benefitInfo: {},
            membership: {},
            detailedDesc: [
                {
                    content: []
                }
            ],
            isShowDetailedDialog: false,
            isShowCouponSuccessDialog: false,
        }
    },
    mounted() {
        this._initData()
    },
    methods:{
        async _initData() {
            this.loading = true
            const res = await getOldMembershipDetail()
            if (res?.errno === 0) {
                this.pageInfo = res?.data
                this.membership = this.pageInfo?.membership || {}
                this.headInfo = this.pageInfo?.headInfo || {}
                this.benefitInfo = this.pageInfo?.benefitInfo || {}
               
                this.asyncSetTitle({
                    navi_title: this.headInfo?.title,
                    timeout: 300
                })
            } else {
                this.createErrorToast(res?.errmsg || '')
            }
            this.loading = false
        },
        createErrorToast(errmsg = '', defaultMsg = this.gettext('网络繁忙，请稍后再试')) {
            const errorToast = this.$createToast({
                txt: errmsg || defaultMsg,
                time: 2000,
                mask: false,
                type: 'error'
            })
            errorToast.show()
        },
        // 吊起详情弹窗
        goDetailed(data) {
            this.detailedDesc[0].content[0] = data?.detailedDesc
            this.isShowDetailedDialog = true
        },
        // 生日弹窗
        goSettingBir() {
            this.showDatePicker()
        },
        showDatePicker() {
            if (!this.datePicker) {
                this.datePicker = this.$createDatePicker({
                    title: this.$t('FoodC_birthday_lxme_nZrh'),
                    subtitle: this.$t('FoodC_birthday_JshR_FWPW'),
                    confirmTxt: this.$t('FoodC_birthday_bthp_qePu'),
                    min: new Date(1900, 1, 1),
                    max: new Date(),
                    value: new Date(),
                    onSelect: this.selectHandle,
                    onCancel: this.cancelHandle
                })
            }

            this.datePicker.show()
        },
        async selectHandle(date) {
            this.loading = true
            const res = await updateUserInfo({
                birthday: this.withoutTime(date)
            })
            this.loading = false
            if (res.errno === 0) {
                this._initData()
            }
            else {
                this.createErrorToast(res?.errmsg || '')
            }
        },
        cancelHandle() {
        },
        // 转换时间格式
        withoutTime(dateString) {
            // 创建日期对象
            const date = new Date(dateString);
            // 获取年、月、日
            const year = date.getFullYear();
            const month = date.getMonth() + 1; // 月份从0开始，需要加1
            const day = date.getDate();
            // 格式化日期
            let formattedDate = year + "-" + this.addLeadingZero(month) + "-" + this.addLeadingZero(day);
            return formattedDate
        },
        // 添加前导零
        addLeadingZero(number) {
            return number < 10 ? "0" + number : number;
        },
        // 领取生日礼物
        async getBirthdayCoupons() {
            this.loading = true
            const res = await getBirthdayBenefit()
            this.loading = false
            if (res.errno === 0) {
                this.isShowCouponSuccessDialog = true
                this._initData()
            } else {
                const toast = this.$createToast({
                    txt: this.$t('FoodC_birthday_UhuO_lUDL'),
                    time: 2000,
                    type: 'warn',
                    mask: false
                })
                toast.show()
            }
        },
        goCoupons() {
            window.SodaBridge('openPage', {
                target: 'blank',
                url: COUPON_URL_DIDI
            })
        },
    }
}
</script>

<style lang="scss" scoped>
.old-member-box{
     background-color:#FFF ;
     height: 100%;
}
</style>