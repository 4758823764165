import i18n from '@/common/js/i18n'

export default {
  path: '/landingpage',
  name: 'landingPage',
  meta: {
    title: i18n.gettext('C端落地页')
  },
  component: () => import(/* webpackChunkName: "landingpage" */ '@/page/landingpage/default'),
  children: [{
    path: '/landingpageShare',
    name: 'landingpageShare',
    meta: {
      requiresAuth: true,
      title: i18n.gettext('落地页')
    },
    component: () => import(/* webpackChunkName: "landingpage" */ '@/page/landingpage/default')
  }]
}
