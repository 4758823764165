import { getGlobalParams, getShopInfo } from '@/common/js/bridge'
import store from '@/store'
import API from '@/api'
import Schema from '@/common/js/schema'
import { globalParamsSchema, xtdConfigSchema } from './schema'
function getGlobalInfo () {
  if (Schema.compare(store.getters.globalParams, globalParamsSchema)) {
    return Promise.resolve(store.getters.globalParams)
  } else {
    return getGlobalParams().then(globalParams => {
      store.commit('UPDATE_GLOBAL_PARAMS', globalParams)
      return globalParams
    })
  }
}
// xtd 需要知道shopId
function getShopInfos () {
  return getShopInfo().then(shopParams => {
    return shopParams
  }).catch(err => console.log(err))
}
// TODO: schema compare middleware
function getXtdConfigInfo (state, data) {
  // required 属性存在时，强制发出请求
  if (Schema.compare(store.getters.xtdConfig, xtdConfigSchema) && !data.required) {
    return Promise.resolve({data: store.getters.xtdConfig, errno: 0})
  } else {
    return API.xtdAcquisitionConfig(data).then(result => {
      store.commit('UPDATE_XTD_CONFIG', result?.data)
      return result
    }).catch(err => console.log(err))
  }
}

function getActivityInfo (state, data) {
  if (Schema.compare(store.getters.xtdConfig, xtdConfigSchema) && !data.required) {
    return Promise.resolve({data: store.getters.xtdConfig, errno: 0})
  } else {
    return API.xtdActivityInfo(data).then(result => {
      return result
    }).catch(err => console.log(err))
  }
}

function getActivityGoing (state, data) {
  if (Schema.compare(store.getters.xtdConfig, xtdConfigSchema) && !data.required) {
    return Promise.resolve({data: store.getters.xtdConfig, errno: 0})
  } else {
    return API.xtdActivityGoing(data).then(result => {
      return result
    }).catch(err => console.log(err))
  }
}

function getActivityFinishList (state, data) {
  if (Schema.compare(store.getters.xtdConfig, xtdConfigSchema) && !data.required) {
    return Promise.resolve({data: store.getters.xtdConfig, errno: 0})
  } else {
    return API.xtdActivityFinishList(data).then(result => {
      // store.commit('UPDATE_XTD_CONFIG', result.data)
      return result?.data
    }).catch(err => console.log(err))
  }
}

function getActivityDetail (state, data) {
  if (Schema.compare(store.getters.xtdConfig, xtdConfigSchema) && !data.required) {
    return Promise.resolve({data: store.getters.xtdConfig, errno: 0})
  } else {
    return API.xtdActivityDetail(data).then(result => {
      return result?.data
    }).catch(err => console.log(err))
  }
}

function getXtdConfigInfoV2 (state, data) {
  return API.xtdAcquisitionConfigV2(data).then(result => {
    return result?.data
  }).catch(err => console.log(err))
}

function getXtdRulesDetail (state, data) {
  return API.getXtdRulesDetail(data).then(result => {
    return result?.data
  }).catch(err => console.log(err))
}

function submitData (data) {
  return API.submitData(data).then(res => res)
}

export default {
  getGlobalInfo,
  getXtdConfigInfo,
  getShopInfos,
  submitData,
  getActivityInfo,
  getActivityGoing,
  getActivityFinishList,
  getActivityDetail,
  getXtdConfigInfoV2,
  getXtdRulesDetail
}
