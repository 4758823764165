function UPDATE_GLOBAL_PARAMS (state, payload) {
  state.globalParams = payload
}
function UPDATE_ACQUISITIOIN_INFO (state, payload) {
  state.acquisitionInfo = payload
}
function UPDATE_PARTICIPATE_INFO (state, payload) {
  state.participateInfo = payload
}
function UPDATE_PROMO_RESULT (state, payload) {
  state.promoResult = payload
}
function UPDATE_PAYINFO (state, payload) {
  state.payInfo = payload
}
function UPDATE_SELECTED_PAYMENT (state, payload) {
  state.selectedCard = payload
}
function UPDATE_EXTRAINFO_LIST (state, payload) {
  state.extraInfoList = payload
}
// 设置会员页面的信息
function SET_MEMBERSHIP_DETAIL (state, payload) {
  state.membership = payload
  SET_MEMBERSHIP_BRANDNAME(state, payload?.brandName)
}
function SET_MEMBERSHIP_BRANDNAME (state, payload) {
  state.brandName = payload || 'DiDi Pack'
}
// 设置退款挽留页面的信息
function SET_UNSUBSCRIBEINFO_DATA (state, payload) {
  state.unSubscribeInfoData = payload || {}
}
export default {
  UPDATE_GLOBAL_PARAMS,
  UPDATE_ACQUISITIOIN_INFO,
  UPDATE_PARTICIPATE_INFO,
  UPDATE_PROMO_RESULT,
  UPDATE_PAYINFO,
  UPDATE_SELECTED_PAYMENT,
  UPDATE_EXTRAINFO_LIST,
  SET_MEMBERSHIP_DETAIL,
  SET_MEMBERSHIP_BRANDNAME,
  SET_UNSUBSCRIBEINFO_DATA
}
