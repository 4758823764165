import member from '@/page/member/index.vue'
import memberHistory from '@/page/member/components/history/index.vue'
import cancelTake from '@/page/member/components/cancelTake/index.vue'
import subscribeLoading from '@/page/member/components/subscribeLoading/index.vue'
import joinSubscription from '@/page/member/components/joinSubscription/index.vue'
import oldMember from '@/page/member/components/oldMember/index.vue'
import selectedReason from '@/page/member/components/selectedReason/index.vue'
import retainMember from '@/page/member/components/retainMember/index.vue'
import joinRules from '@/page/member/components/joinRules/index.vue'
import pauseSubscribe from '@/page/member/components/pauseSubscribe/index.vue'

const memberPage = {
  path: '/member',
  name: 'member',
  component: member,
}

const memberHistoryPage = {
  path: '/memberHistory',
  name: 'memberHistory',
  component: memberHistory,
}

const cancelTakePage = {
  path: '/cancelTake',
  name: 'cancelTake',
  component: cancelTake,
}

const subscribeLoadingPage = {
  path: '/subscribeLoading',
  name: 'subscribeLoading',
  component: subscribeLoading,
}

const joinSubscriptionPage = {
  path: '/joinSubscription',
  name: 'joinSubscription',
  component: joinSubscription,
}

const oldMemberPage = {
  path: '/oldMember',
  name: 'oldMember',
  component: oldMember,
}
// 退款原因选择页面 selectedReason
const selectedReasonPage = {
  path: '/selectedReason',
  name: 'selectedReason',
  component: selectedReason,
}
// 退款挽留页面 retainMember
const retainMemberPage = {
  path: '/retainMember',
  name: 'retainMember',
  component: retainMember,
}
// 规则页面 joinRules
const joinRulesPage = {
  path: '/joinRules',
  name: 'joinRules',
  component: joinRules,
}
// 暂停订阅页面 pauseSubscribe
const pauseSubscribePage = {
  path: '/pauseSubscribe',
  name: 'pauseSubscribe',
  component: pauseSubscribe,
}
export default [
  memberPage,
  memberHistoryPage,
  cancelTakePage,
  subscribeLoadingPage,
  joinSubscriptionPage,
  oldMemberPage,
  selectedReasonPage,
  retainMemberPage,
  joinRulesPage,
  pauseSubscribePage,
]
