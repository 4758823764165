<template>
  <Popup :isShow="shows" :title="title" class="popup-box" :zIndex="zIndex" @clickMask="closeDialog">
    <div slot="content" class="popup-wrapper">
      <div class="rules-wrapper">
        <div v-for="(item, index) in rules" :key="index" class="rule-list">
          <div v-if="item.title" class="rule-title">{{ item.title }}</div>
          <div v-for="(desc, idx) in item.content" :key="idx" class="rule-desc">{{ desc }}</div>
        </div>
      </div>
      <div class="rule-btn">
        <rich-button @click="closeDialog">{{ getBtnName }}</rich-button>
      </div>
    </div>
  </Popup>
</template>

<script>
import Popup from '@/components/vant-popup'

export default {
  name: 'Rules',
  components: {
    Popup,
  },
  model: {
    prop: 'showTips',
    event: 'showsChange',
  },
  props: {
    zIndex: {
      type: String,
      default: '100',
    },
    title: {
      type: String,
      default: '',
    },
    rules: {
      type: Array,
      default: () => [],
    },
    isJumpLink: {
      type: Boolean,
      default: false,
    },
    showTips: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  computed: {
    shows: {
      get: function () {
        return this.showTips
      },
      set: function (value) {
        this.$emit('showsChange', value)
      },
    },
    getBtnName() {
      return this.$t('FoodC_register_mezq_UlpA')
    },
  },
  methods: {
    closeDialog() {
      this.shows = false
      this.$emit('closeDialog')
    },
  },
}
</script>

<style lang="scss" scoped>
// 规则样式开始------------
.popup-wrapper {
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  margin-top: 32px;
}
.rules-wrapper {
  overflow: auto;
  flex: 1;
}
.rule-list {
  .rule-title {
    padding: 40px 0;
    font-family: DiDiSans-Pro-Bold;
    font-size: 36px;
    color: #000000;
    line-height: 36px;
  }
  .rule-desc {
    opacity: 0.6;
    font-family: DiDiSans-Pro-Regular;
    font-size: 28px;
    color: #000000;
    line-height: 34px;
    margin-bottom: 32px;
  }
}
.rule-btn {
  padding: 48px 0 20px 0;
  button {
    height: 108px;
  }
}
// 规则样式结束------------
::v-deep .wrapper {
  .half-title {
    span {
      margin-left: 0;
    }
  }
}
</style>
