import { isEmpty, isNumber } from "lodash-es";

export const upgradeSwitch = function (upgradePublicityFrequency) {
  // 如果不是一个数字，且小于1，不显示升级弹窗
  if (!isNumber(upgradePublicityFrequency) || upgradePublicityFrequency < 1)
    return false;

  const res = JSON.parse(localStorage?.getItem("upgradeWarnInfo")) || {};

  const upgradeWarnInfo = {
    time: formatDateTime(new Date()), // 第一次进入的日期
    upgradePublicityFrequency: 1, // 已经展示的次数
  };

  // 如果内存内对应的数据，先存数据，再显示升级弹窗
  if (isEmpty(res)) {
    localStorage?.setItem("upgradeWarnInfo", JSON.stringify(upgradeWarnInfo));
    return true;
  }

  // 如果内存内有数据
  // 时间差大于1天，先存数据，再显示升级弹窗
  if (formatDateTime(new Date()) > res?.time) {
    localStorage?.setItem("upgradeWarnInfo", JSON.stringify(upgradeWarnInfo));
    return true;
  }
  
  // 时间差小于1天
  // 接口返回的次数小于内存的存取的次数
  if (res?.upgradePublicityFrequency < upgradePublicityFrequency) {
    // 更新内存显示的次数
    res.upgradePublicityFrequency++;
    localStorage?.setItem("upgradeWarnInfo", JSON.stringify(res));
    return true;
  }

  return false
};

// 将当日时间处理成 20240110形式
const formatDateTime = function (time) {
  const year = time.getFullYear();
  const month = String(time.getMonth() + 1).padStart(2, "0");
  const day = String(time.getDate()).padStart(2, "0");

  const formattedDate = `${year}${month}${day}`;

  return Number(formattedDate);
};
