<template>
  <div class="pack-header">
    <!-- 未购买状态 -->
    <template v-if="noBuy">
      <div :class="['title1', isShowNewStyle ? 'title1-new' : '']">
        <div class="title1-left">
          <div v-if="headInfo.iconUrl" class="title1-icon">
            <img :src="headInfo.iconUrl" alt="" />
          </div>
          <div class="title1-text">{{ headInfo.title }}</div>
        </div>
        <div v-if="pauseSubscriptionStatus" class="header-paused-info" @click="pausedClick">
          <span>{{ $t('FoodC_pause_AtFL_nncW') }}</span>
          <i class="icon-v3_outlined_entrance paused-icon" />
        </div>
      </div>
      <!-- showTemplate=1 预计可省模块 showTemplate=2 累计已省模块  -->
      <!-- <div v-if="isShowNewStyle" class="save-price-new">
        <div v-if="isShowTemplate" class="save-price-expect" @click="getShowExpectContent">
          <span v-html="getPriceSubscriptionText"></span>
          <i class="icon-v3_outlined_entrance"></i>
        </div>
        <div v-else class="save-price-total">
          <span class="save-price-new-left">{{ $t('FoodC_center_Ospi_SNsH') }}</span>
          <span class="save-price-new-right">{{ saleInfo.showAmount }}</span>
        </div>
      </div> -->
      <div v-if="isShowNewStyle" class="line-grey"></div>
      <BenefitList
        :saleInfo="saleInfo"
        :benefitInfo="benefitInfo"
        :noBuy="noBuy"
        @handleClick="goDetailed"
        @goSettingBir="goSettingBir"
        @getBirthdayCoupons="getBirthdayCoupons"
        @goCoupons="goCoupons"
        @getShowExpectContent="getShowExpectContent"
      />
      <div class="line-grey"></div>
    </template>
    <!-- 购买状态 -->
    <template v-else>
      <div
        :class="[
          'title2',
          flowVersion ? 'title2-flow-new' : '',
          isFlowAndSavedDisplay ? 'title2-flow-new-save' : '',
        ]"
      >
        <div class="title2-left">
          <div class="title2-icon"><img :src="headInfo.iconUrl" alt="" /></div>
          <div class="title2-info">
            <div class="title2-info_main">{{ headInfo.title }}</div>
            <!-- 命中实验的时候不在header展示过期时间 -->
            <div
              v-if="isShowHeaderInfo"
              class="title2-info-time"
              :class="{ 'title2-info-time1': isBuyAndNoSubscriptionStatus }"
            >
              {{ headInfo.validDateDesc }}
            </div>
          </div>
        </div>
        <div
          v-if="isPauseFlowVersion && pauseSubscriptionStatus"
          class="header-paused-info"
          @click="pausedClick"
        >
          <span>{{ $t('FoodC_pause_AtFL_nncW') }}</span>
          <i class="icon-v3_outlined_entrance paused-icon" />
        </div>
      </div>
      <!-- <div v-if="hasAlreadySavedDisplay" class="save-price">
        <div class="left-text">{{ alreadySavedDisplay.text }}</div>
        <div class="right-text">{{ alreadySavedDisplay.amount }}</div>
      </div> -->
      <div class="line-grey"></div>
      <BenefitList
        :benefitInfo="benefitInfo"
        :noBuy="noBuy"
        :alreadySavedDisplay="alreadySavedDisplay"
        @handleClick="goDetailed"
        @goSettingBir="goSettingBir"
        @getBirthdayCoupons="getBirthdayCoupons"
        @goCoupons="goCoupons"
      />
      <div class="line-grey"></div>
    </template>
  </div>
</template>
<script>
import { isEmpty } from 'lodash-es'
import BenefitList from '../benefitList/index.vue'
export default {
  components: {
    BenefitList,
  },
  props: [
    // eslint-disable-next-line vue/require-prop-types
    'headInfo',
    // eslint-disable-next-line vue/require-prop-types
    'benefitInfo',
    // eslint-disable-next-line vue/require-prop-types
    'noBuy',
    // eslint-disable-next-line vue/require-prop-types
    'isBuyAndNoSubscriptionStatus',
    // eslint-disable-next-line vue/require-prop-types
    'alreadySavedDisplay',
    // eslint-disable-next-line vue/require-prop-types
    'saleInfo',
    // eslint-disable-next-line vue/require-prop-types
    'flowVersion',
    // eslint-disable-next-line vue/require-prop-types
    'isPauseFlowVersion',
    // eslint-disable-next-line vue/require-prop-types
    'pauseSubscriptionStatus',
  ],
  computed: {
    // 是否展示头部的过期时间
    isShowHeaderInfo() {
      return this.headInfo?.validDateDesc && !this.flowVersion
    },
    hasAlreadySavedDisplay() {
      return !isEmpty(this.alreadySavedDisplay)
    },
    // 是否命中实验展示新样式，1的时候展示新样式
    isShowNewStyle() {
      return +this.saleInfo?.showStyle
    },
    // 1:展示预计可省 2: 展示累计已省
    isShowTemplate() {
      return +this.saleInfo?.showTemplate === 1
    },
    // 拼接样式
    getPriceSubscriptionText() {
      const money = `<span class="overbold-price">${this.saleInfo?.showAmount}</span>`
      return this.$t('FoodC_didipack_uwFV_TgLK', {
        money,
      })
    },
    // 命中新实验&有累计已省模块
    isFlowAndSavedDisplay() {
      return this.flowVersion && this.hasAlreadySavedDisplay
    },
  },
  methods: {
    // 详情弹窗
    goDetailed(data) {
      this.$emit('goDetailed', data)
    },
    // 设置生日
    goSettingBir() {
      this.$emit('goSettingBir')
    },
    // 领取生日券
    getBirthdayCoupons() {
      this.$emit('getBirthdayCoupons')
    },
    // 跳转到优惠券中心
    goCoupons() {
      this.$emit('goCoupons')
    },
    // 预计可省说明-弹窗
    getShowExpectContent() {
      this.$emit('getShowExpectContent')
    },
    // 暂停订阅弹窗
    pausedClick() {
      this.$emit('pausedClick')
    },
  },
}
</script>

<style lang="scss" scoped>
.pack-header {
  .title1 {
    display: flex;
    // justify-content: space-between;
    align-items: center;
    padding-top: 36px;
    padding-left: 32px;
    padding-right: 32px;
    margin-bottom: 48px;
    .title1-left {
      display: flex;
      align-items: center;
      .title1-icon {
        flex-shrink: 0;
        width: 76px;
        height: 76px;
        margin-right: 12px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .title1-text {
        line-height: 76px;
        font-family: DiDiSans-Pro-Bold;
        font-size: 64px;
        color: #000000;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2; /* 这里是超出几行省略 */
        overflow: hidden;
      }
    }
    // 命中暂停订阅
    .header-paused-info {
      margin-left: 12px;
      max-width: 270px;
      display: flex;
      align-items: center;
      padding: 14px 24px;
      background: rgba(0, 0, 0, 0.04);
      border-radius: 16px;
      line-height: 24px;
      font-family: DiDiSans-Pro-Bold;
      font-size: 20px;
      color: #000000;
      span {
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        /* 这里是超出几行省略 */
        overflow: hidden;
      }

      .paused-icon {
        flex-shrink: 0;
        font-size: 24px;
      }
    }
  }
  .title1-new {
    margin-bottom: 32px;
  }

  .line-grey {
    height: 12px;
    background: #f7f7f7;
  }

  .title2 {
    display: flex;
    align-items: center;
    padding-top: 36px;
    margin-bottom: 48px;
    padding-left: 32px;
    padding-right: 32px;
    // justify-content: space-between;
    .title2-left {
      display: flex;
      align-items: center;
      .title2-icon {
        width: 84px;
        height: 84px;
        flex-shrink: 0;
        margin-right: 16px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .title2-info {
        flex: 1;
        .title2-info_main {
          font-family: DiDiSans-Pro-Bold;
          font-size: 64px;
          color: #000000;
          line-height: 64px;
          margin-bottom: 8px;
          // word-break: break-all;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          /* 这里是超出几行省略 */
          overflow: hidden;
        }

        .title2-info-time {
          opacity: 0.6;
          font-family: DiDiSans-Pro-Regular;
          font-size: 24px;
          color: #000000;
          line-height: 24px;
          // word-break: break-all;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          /* 这里是超出几行省略 */
          overflow: hidden;
        }

        .title2-info-time1 {
          color: #ff4060;
          font-family: DiDiSans-Pro-Medium;
          opacity: 1;
        }
      }
    }
    // 命中暂停订阅
    .header-paused-info {
      max-width: 270px;
      margin-left: 12px;
      display: flex;
      align-items: center;
      padding: 14px 24px;
      background: rgba(0, 0, 0, 0.04);
      border-radius: 16px;
      line-height: 24px;
      font-family: DiDiSans-Pro-Bold;
      font-size: 20px;
      color: #000000;
      span {
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        /* 这里是超出几行省略 */
        overflow: hidden;
      }
      .paused-icon {
        flex-shrink: 0;
        font-size: 24px;
      }
    }
  }
  // 命中实验的时候header新样式
  .title2-flow-new {
    display: flex;
    align-items: center;
    .title2-icon {
      width: 76px;
      height: 76px;
      margin-right: 12px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .title2-left {
      .title2-info {
        .title2-info_main {
          margin-bottom: 0px;
        }
      }
    }
  }
  // 命中新实验&有累计已省模块
  .title2-flow-new-save {
    margin-bottom: 32px;
  }

  .save-price {
    margin: 0 auto 48px;
    // background: url("../../../../assets/images/member/save_bg.png") no-repeat;
    background: url('../../../../assets/images/member/save_bg_new.png') no-repeat;
    background-size: 100% 100%;
    height: 100px;
    width: 686px;
    border-radius: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left-text {
      margin-left: 32px;
      font-family: DiDiSans-Pro-Medium;
      font-size: 28px;
      color: #000000;
      line-height: 32px;
    }

    .right-text {
      font-family: DiDiSans-Pro-Bold;
      font-size: 40px;
      color: #ff8040;
      line-height: 44px;
      margin-right: 30px;
    }
  }
  .save-price-new {
    margin: 0 auto 48px;
    background: url('../../../../assets/images/member/save_bg_new.png') no-repeat;
    background-size: 100% 100%;
    height: 100px;
    width: 686px;
    box-sizing: border-box;
    border-radius: 40px;
    font-family: DiDiSans-Pro-Medium;
    font-size: 28px;
    color: #000000;
    line-height: 32px;
    font-weight: 500;
    // 预计可省模块样式
    .save-price-expect {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 100%;
      width: 100%;
      box-sizing: border-box;
      padding-left: 32px;
      padding-right: 16px;
      // 价格样式处理
      ::v-deep .overbold-price {
        font-family: DiDiSans-Pro-Bold;
        font-size: 40px;
        color: #ff8040;
        letter-spacing: 0;
        text-align: right;
        line-height: 44px;
        font-weight: 700;
      }
    }
    // 累计已省
    .save-price-total {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 100%;
      width: 100%;
      box-sizing: border-box;
      padding: 0 32px;
      .save-price-new-left {
        font-family: DiDiSans-Pro-Medium;
        font-size: 28px;
        color: #000000;
        line-height: 32px;
      }

      .save-price-new-right {
        font-family: DiDiSans-Pro-Bold;
        font-size: 40px;
        color: #ff8040;
        line-height: 44px;
      }
    }
  }
}
</style>
