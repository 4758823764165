import { checkIsOldVersion } from '@/common/js/utils'
import { util } from '@didi/soda-common'
const { env } = util

export async function getGlobalParams ({commit}) {
  const globalParams = await SodaBridge('getGlobalParams', 'badcase') || { data: {} }
  commit('UPDATE_GLOBALPARAMS', globalParams)
}

export async function versionControl ({commit, state}) {
  // 获取公参
  const globalParams = await SodaBridge('getGlobalParams', 'badcase') || {data: {}}
  const { appVersion } = globalParams && globalParams.data // 当前版本号
  const didiVersion = '1.2.6' // didi/99独立端  // 2020.7.14新增备注 此版本为topgun版本号
  const didiGlobalVersion = '7.1.82' // didi出行国家版嵌入端 // 2020.7.14新增备注 此版本为topgun版本号
  const taxi99Version = '6.12.6' // 99出行嵌入端 // 2020.7.14新增备注 此版本为topgun版本号
  let compareVersion = '' // 需要对比的版本号
  let hurdleVersion = '' // 2020.7.14新增 通栏版本号
  if (appVersion) {
    // 是否为嵌入端的判断
    if (env.inEmbedCustomerApp) {
      // 嵌入端需判断是didiGlobal还是99taxi
      compareVersion = state.brand === '99' ? taxi99Version : didiGlobalVersion
      // 判断此版本是否支持通栏样式
      hurdleVersion = state.brand === '99' ? '6.14.2' : '7.1.98'
    } else {
      // 独立端didiFood和99Food的版本号一致
      compareVersion = didiVersion
      // 判断此版本是否支持通栏样式
      hurdleVersion = '1.2.34'
    }
    commit('UPDATE_IS_TOPGUN', !checkIsOldVersion(appVersion, compareVersion))
    commit('UPDATE_IS_SUPPORT_HURDLE', !checkIsOldVersion(appVersion, hurdleVersion))
  } else {
    commit('UPDATE_IS_TOPGUN', true)
    commit('UPDATE_IS_SUPPORT_HURDLE', false)
  }
}
