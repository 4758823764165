import Vue from 'vue'
import Router from 'vue-router'
import { getLocale } from '@didi/gettext'
import clientC from './c/index'
import clientD from './d/index'
import sharebox from './sharebox/index'
import unsubscribe from './unsubscribe/index'
import landingpage from '@/page/landingpage/route'
import newCouponsCenter from '@/page/newCouponsCenter/route'
import trending from './c/trending'
import topShop from './c/topShop'
Vue.use(Router)

const notFount = {
  path: '*',
  name: 'notFound',
  component: () => import('@/page/index')
}

let locale = getLocale()
const router = new Router({
  mode: 'history',
  base: locale || '/',
  routes: [
    notFount,
    clientC,
    clientD,
    unsubscribe,
    landingpage,
    sharebox,
    newCouponsCenter,
    trending, // 榜单落地页
    topShop // 好店落地页
  ]
})
router.onError((error) => {
  const pattern = /Loading chunk (\d)+ failed/g;
  const isChunkLoadFailed = error.message.match(pattern);
  const targetPath = router.history.pending.fullPath;
  if (isChunkLoadFailed) {
    router.replace(targetPath);
  }
})
export default router
