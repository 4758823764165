<!-- 暂停订阅的管理弹窗 -->
<template>
  <Popup
    :isShow="shows"
    :title="$t('FoodC_pause_VaEr_OWMS')"
    class="popup-box"
    :zIndex="zIndex"
    @clickMask="shows = false"
  >
    <div slot="content" class="popup-wrapper">
      <div class="page-info">
        <!-- 更改支付方式的组件 -->
        <div class="top-info">
          <div class="rule-desc" @click="openRule($event)" v-html="getRuleDesc"></div>
          <div v-if="isChangPaymentBridgeVersion">
            <div class="pay-title info-title">{{ $t('FoodC_V2_RUXY_aLKJ') }}</div>
            <ChangePayment
              v-if="shows"
              :paymentMethod="paymentMethod"
              :hasCardInfo="hasCardInfo"
              @changePaymentFn="changePaymentFn"
            />
          </div>
          <div class="subscription-title info-title">
            {{ $t('FoodC_subscription_XfQl_RWPP') }}
          </div>
          <div class="cancel-btn" @click="cancelClick">
            <div class="text">
              {{ $t('FoodC_subscription_CtEg_IEoy', { didipackbrand: brandName }) }}
            </div>
            <div class="icon"></div>
          </div>
        </div>
      </div>
    </div>
  </Popup>
</template>

<script>
import Popup from '@/components/vant-popup'

import { getRenewPaymentMethod, changeRenewPaymentMethod } from '@/api/member/index.js'
import ChangePayment from '../changePayment/index.vue'
import { mapState } from 'vuex'
import { getPaymentSDKVersion } from '@/common/js/bridge.js'
import { SCENE } from '../../common/enumsUtil'
import { isEmpty } from 'lodash-es'

export default {
  name: 'JoinSubscribePopup',
  components: {
    Popup,
    ChangePayment,
  },
  model: {
    prop: 'showTips',
    event: 'showsChange',
  },
  props: {
    zIndex: {
      type: String,
      default: '100',
    },
    showTips: {
      type: Boolean,
      default: false,
      required: false,
    },
    isChangPaymentBridgeVersion: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data() {
    return {
      loading: false,
      paymentMethod: {},
      hasCardInfo: false,
      payMethod: {},
    }
  },
  computed: {
    shows: {
      get: function () {
        return this.showTips
      },
      set: function (value) {
        this.$emit('showsChange', value)
      },
    },
    ...mapState(['membership', 'brandName']),
    // 暂停恢复日
    getDate2() {
      return this.membership?.pauseAutoResumeDate || ''
    },
    // 自动扣款钱数
    getMoney() {
      return this.membership?.renewPriceSplit?.display || ''
    },
    getRuleDesc() {
      return this.$t('FoodC_pause_tfPj_IAoM', {
        didifoodbrand: this.brandName,
        date: this.getDate2,
        money: this.getMoney,
        tc: `<span class="rule">${this.$t('FoodC_subscription_VPMP_seXT')}</span>`,
      })
    },
  },
  watch: {
    // 使用watch来监听shows的变化
    shows: {
      handler(nVal) {
        if (nVal && this.isChangPaymentBridgeVersion) {
          // 只有在弹窗展示的时候才会执行initData请求/getRenewPaymentMethod接口
          this.initData()
        }
      },
    },
  },
  mounted() {},
  methods: {
    closeDialog() {
      this.shows = false
      this.$emit('closeDialog')
    },
    cancelClick() {
      // 点击取消订阅，跳转到取消挽留页
      this.$router?.push({
        path: '/retainMember',
        query: {
          nativeWebUIType: 1,
          retainFrom: 'memberPage', // 携带取消挽留页的来源
        },
      })
    },
    initData() {
      getPaymentSDKVersion({}, async ({ version }) => {
        this.loading = true
        const res = await getRenewPaymentMethod({ scene: SCENE.PAUSE, cashierSDKVersion: version })
        if (res.errno === 0) {
          this.paymentMethod = res?.data?.paymentMethod || {}
          if (!isEmpty(this.paymentMethod)) {
            this.hasCardInfo = true
          }
        }
        this.loading = false
      })
    },
    openRule(el) {
      const isRule = el.target.className === 'rule'
      if (isRule) {
        this.$emit('getJoinRules')
      }
    },
    // 更改支付方式
    async changePaymentFn(val) {
      // 修改了支付方式
      if (+val?.changed === 1) {
        // 更新当前视图
        const payMethod = val?.pay_method?.[0] || {}
        const paymentMethod = {
          channelID: payMethod?.channel_id * 1,
          cardIndex: payMethod.extra_info?.card_index || 0,
        }
        const res = await changeRenewPaymentMethod(paymentMethod)
        if (+res.errno === 0) {
          // 成功
          this.initData()
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
// 规则样式开始------------
.popup-wrapper {
  height: 100%;
  // overflow-y: auto;
  display: flex;
  flex-direction: column;
  margin-top: 47px;
}
// 规则样式结束------------
::v-deep .wrapper {
  .half-content {
    background-color: #fafafa;
    .half-title {
      span {
        margin-left: 0;
      }
    }
  }
}
.top-info {
  flex: 1;
}
.page-info {
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding-bottom: 348px;
  .info-title {
    font-family: DiDiSans-Pro-Bold;
    font-size: 28px;
    color: #000000;
    line-height: 43px;
    margin-bottom: 24px;
  }
  .subscription-title {
    margin-top: 48px;
  }
  .cancel-btn {
    background: #ffffff;
    box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.04);
    border-radius: 40px;
    display: flex;
    align-items: center;
    padding: 44px 24px;
    justify-content: space-between;
    .text {
      font-family: DiDiSans-Pro-Regular;
      font-size: 28px;
      color: #000000;
      line-height: 28px;
    }
    .icon {
      width: 28px;
      height: 28px;
      background-image: url('../../../../assets//images/member/right_arrow.png');
      background-size: 100% 100%;
    }
  }
}
.rule-desc {
  padding-bottom: 65px;
  font-family: DiDiSans-Pro-Regular;
  font-size: 28px;
  color: rgba(0, 0, 0, 0.6);
  text-align: left;
  line-height: 36px;
}
::v-deep .rule {
  font-family: DiDiSans-Pro-Medium;
  font-size: 28px;
  color: #000000;
  text-decoration: underline;
}
</style>
