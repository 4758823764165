export const TC_PROTOCOL_URL = {
  'MX': 'https://img0.didiglobal.com/static/dpubimg/fc56b7f639340ded49938a537bc5fc50/index.html?lang=es-MX&location_country=MX',
  'JP': 'https://img0.didiglobal.com/static/dpubimg/16aebd1620a29cccdf3914691d87c1bb/index.html?lang=ja-JP&location_country=JP',
  'BR': 'https://img0.99taxis.mobi/static/dpubimg/93435e0533165966aca15113e9a404b2/index.html?lang=pt-BR&location_country=BR',
  'CR': 'https://img0.didiglobal.com/static/dpubimg/16aebd1620a29cccdf3914691d87c1bb/index.html?lang=es-419&location_country=CR',
  'CO': 'https://img0.didiglobal.com/static/dpubimg/16aebd1620a29cccdf3914691d87c1bb/index.html?lang=es-419&location_country=CO',
  'DO': 'https://img0.didiglobal.com/static/dpubimg/16aebd1620a29cccdf3914691d87c1bb/index.html?lang=es-419&location_country=DO',
  'CL': 'https://img0.didiglobal.com/static/dpubimg/16aebd1620a29cccdf3914691d87c1bb/index.html?lang=es-419&location_country=CL'
}
export const PP_PROTOCOL_URL = {
  'MX': 'https://img0.didiglobal.com/static/dpubimg/fc56b7f639340ded49938a537bc5fc50/index.html?lang=es-MX&location_country=MX',
  'JP': 'https://img0.didiglobal.com/static/dpubimg/16aebd1620a29cccdf3914691d87c1bb/index.html?lang=ja-JP&location_country=JP',
  'BR': 'https://img0.didiglobal.com/static/dpubimg/6f3f36942b5e14b463f3a99bbbe3fa00/index.html?lang=pt-BR&location_country=BR',
  'CR': 'https://img0.didiglobal.com/static/dpubimg/fc56b7f639340ded49938a537bc5fc50/index.html?lang=es-MX&location_country=CR',
  'CO': 'https://img0.didiglobal.com/static/dpubimg/fc56b7f639340ded49938a537bc5fc50/index.html?lang=es-MX&location_country=CO',
  'DO': 'https://img0.didiglobal.com/static/dpubimg/fc56b7f639340ded49938a537bc5fc50/index.html?lang=es-MX&location_country=DO',
  'CL': 'https://img0.didiglobal.com/static/dpubimg/fc56b7f639340ded49938a537bc5fc50/index.html?lang=es-MX&location_country=CL'
}
export const TC_RULE = {
  'MX': 'https://img0.didiglobal.com/static/dpubimg/94a697502c5c260b555019ab864ed355/index.html?lang=es-MX&location_country=MX&dchn=',
  'JP': 'https://img0.didiglobal.com/static/dpubimg/eece985a76345b7f9d12f8d1be5afaad/index.html',
  'BR': 'https://img0.99taxis.mobi/static/dpubimg/93435e0533165966aca15113e9a404b2/index.html?lang=pt-BR&location_country=BR',
  'CR': 'https://img0.didiglobal.com/static/dpubimg/16aebd1620a29cccdf3914691d87c1bb/index.html?lang=es-419&location_country=CR',
  'CO': 'https://img0.didiglobal.com/static/dpubimg/16aebd1620a29cccdf3914691d87c1bb/index.html?lang=es-419&location_country=CO',
  'DO': 'https://img0.didiglobal.com/static/dpubimg/16aebd1620a29cccdf3914691d87c1bb/index.html?lang=es-419&location_country=DO',
  'CL': 'https://img0.didiglobal.com/static/dpubimg/16aebd1620a29cccdf3914691d87c1bb/index.html?lang=es-419&location_country=CL'
}
