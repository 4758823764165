export const COUPON_BG = {
  'MX': {
    C_BANNER_BG: 'https://img0.didiglobal.com/static/gstar/img/c71V5rcOUp1617021150558.png',
    DGD_BANNER_BG: 'https://img0.didiglobal.com/static/gstar/img/Zc9bOcTKbd1547542563985.jpg',
    DGD_BANNER_BG_PAD: 'https://img0.didiglobal.com/static/gstar/img/AtDQmSRtAI1546596490006.jpg'
  },
  'JP': {
    C_BANNER_BG: 'https://img0.didiglobal.com/static/gstar/img/oh7muQzvQE1617021176916.jpg',
    DGD_BANNER_BG: 'https://img0.didiglobal.com/static/gstar/img/K9FwnQBMz51617021209136.png',
    DGD_BANNER_BG_PAD: 'https://img0.didiglobal.com/static/gstar/img/kQd26f19Zv1617021206468.png'
  },
  'BR': {
    C_BANNER_BG: 'https://img0.didiglobal.com/static/gstar/img/uGs6WNc7Bn1617021236157.png',
    DGD_BANNER_BG: 'https://img0.didiglobal.com/static/gstar/img/Zc9bOcTKbd1547542563985.jpg',
    DGD_BANNER_BG_PAD: 'https://img0.didiglobal.com/static/gstar/img/AtDQmSRtAI1546596490006.jpg'
  },
  'CR': {
    C_BANNER_BG: 'https://img0.didiglobal.com/static/gstar/img/c71V5rcOUp1617021150558.png',
    DGD_BANNER_BG: 'https://img0.didiglobal.com/static/gstar/img/Zc9bOcTKbd1547542563985.jpg',
    DGD_BANNER_BG_PAD: 'https://img0.didiglobal.com/static/gstar/img/AtDQmSRtAI1546596490006.jpg'
  },
  'CO': {
    C_BANNER_BG: 'https://img0.didiglobal.com/static/gstar/img/c71V5rcOUp1617021150558.png',
    DGD_BANNER_BG: 'https://img0.didiglobal.com/static/gstar/img/Zc9bOcTKbd1547542563985.jpg',
    DGD_BANNER_BG_PAD: 'https://img0.didiglobal.com/static/gstar/img/AtDQmSRtAI1546596490006.jpg'
  },
  'DO': {
    C_BANNER_BG: 'https://img0.didiglobal.com/static/gstar/img/c71V5rcOUp1617021150558.png',
    DGD_BANNER_BG: 'https://img0.didiglobal.com/static/gstar/img/Zc9bOcTKbd1547542563985.jpg',
    DGD_BANNER_BG_PAD: 'https://img0.didiglobal.com/static/gstar/img/AtDQmSRtAI1546596490006.jpg'
  },
  'CL': {
    C_BANNER_BG: 'https://img0.didiglobal.com/static/gstar/img/c71V5rcOUp1617021150558.png',
    DGD_BANNER_BG: 'https://img0.didiglobal.com/static/gstar/img/Zc9bOcTKbd1547542563985.jpg',
    DGD_BANNER_BG_PAD: 'https://img0.didiglobal.com/static/gstar/img/AtDQmSRtAI1546596490006.jpg'
  }
}
