// 进端兑换码链接
export const APPLINKURL_CODE = {
  'didi': `https://didi-food.onelink.me/ssCr/redeemCode`,
  '99': `https://99-food.onelink.me/TDXj/redeemCode`
}
// 进端通用首页链接
export const APPLINKURL_HOME = {
  'didi': 'https://didi-food.onelink.me/ssCr/home',
  '99': 'https://99-food.onelink.me/TDXj/home'
}

// 进端首页链接区分国家
export const APPLINKURL_HOME_BY_COUNTRY = {
  'MX': APPLINKURL_HOME['didi'],
  'BR': APPLINKURL_HOME['99'],
  'JP': APPLINKURL_HOME['didi'],
  'CR': APPLINKURL_HOME['didi'],
  'CO': APPLINKURL_HOME['didi'],
  'DO': APPLINKURL_HOME['didi'],
  'CL': APPLINKURL_HOME['didi']
}
