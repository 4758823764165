import didiaxios from '@didi/dajax/dist/dajax'
import apiConfig from '@/common/js/apiConfig'

const BASE_URL = apiConfig.BASE_API_ACT

// cgc-活动页改版-跑马灯数据
const GET_HORSELAMP_DATA = BASE_URL + '/act-api/Api/Recactivity/getHorseLampData'
// 推荐人落地页信息
const POST_ACQUISTION_INFO = BASE_URL + '/act-api/api/Recactivity/getRecommendPage'
// 推荐人奖励列表
const POST_ACQUISTION_REFERRAL_LIST = BASE_URL + '/act-api/Api/Recactivity/getRewardList'
// 推荐人奖励列表mvp版本
const POST_ACQUISTION_MVP_REFERRAL_LIST = BASE_URL + '/act-api/Api/Recactivity/getInvitedRecordList'
// 被推荐人落地页信息
const POST_PARTICIPATE_INFO = BASE_URL + '/act-api/api/Recactivity/getSharePage'
// 被推荐人领取奖励
const POST_PARTICIPATE_REDEEM = BASE_URL + '/act-api/Api/Recactivity/receiveReward'
// 推荐码领取结果
const POST_PROMO_RESULT = BASE_URL + '/act-api/api/code/redeemResult'

export const getHorseLampData = function (data) {
  // data => activityId "string|required"
  return requestGET(GET_HORSELAMP_DATA, data)
}
export const acquisitionInfo = function (data) {
  return requestPOST(POST_ACQUISTION_INFO, data)
}
export const acquisitionReferralList = function (data) {
  return requestPOST(POST_ACQUISTION_REFERRAL_LIST, data)
}
export const acquisitionMvpReferralList = function (data) {
  return requestPOST(POST_ACQUISTION_MVP_REFERRAL_LIST, data)
}
export const participateInfo = function (data) {
  return requestPOST(POST_PARTICIPATE_INFO, data)
}
export const participateRedeem = function (data) {
  return requestPOST(POST_PARTICIPATE_REDEEM, data)
}
export const promoResult = function (data) {
  return requestPOST(POST_PROMO_RESULT, data)
}
function requestPOST (url, data) {
  return didiaxios.request({
    url: url,
    method: 'POST',
    emulateJSON: true,
    needEncrypt: true,
    params: '',
    body: data
  }).then(res => (res.data)).catch((err) => console.log(err))
}
async function requestGET (url, data) {
  let globalParams = await SodaBridge('getGlobalParams', 'badcase') || {}
  let paramsData = globalParams.data || {}
  return didiaxios.request({
    url: url,
    method: 'GET',
    emulateJSON: true,
    needEncrypt: true,
    params: {
      ...paramsData,
      ...data
    }
  })
}
