<template>
    <div class="box">
        <emptyState :text="text" :isShowButton="isShowButton"/>
    </div>
</template>

<script>
import  emptyState from '@/components/emptyState/index.vue'
export default {
    components: {
        emptyState
    },
    props:{
        text:{
            default:'FoodC_C_No_suitable_UnyN',
            type: String
        },
        isShowButton:{
            default:true,
            type:Boolean
        }
    }
   
}
</script>

<style lang="scss" scoped>
.box {
    padding-top: 484px;
}
</style>