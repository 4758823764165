import Vue from 'vue'
import App from './App'
import '@/common/lib/wsgchallenge'
import '@/common/js/performance-statistics'
import {
  init as elvishInit,
  getCapitalCity,
  setTimezone
} from '@didi/elvish-lib-javascript'
import { getLocale } from '@didi/gettext'
import {
  Style,
  Cell,
  Button,
  RadioGroup,
  CheckboxGroup,
  Input,
  Lazyload,
  Toast,
  Dialog,
  Locale,
  Theme,
  Tag,
  DatePicker,
  TextareaBox,
  Alert
} from '@didi/rich-ui'
import CouponUI from '@didi/soda-c-coupon'
import router from './router'
import store from './store'
import '@/common/js/rem'
import axios from 'axios'
import didiaxios from '@didi/dajax/dist/dajax'
import { init } from './init'
import initGlobalFilters from './filter'
import '@didi/soda-jsbridge'
import DDBridge from './plugin/DDBridge'
import { getLanguageLong } from '@didi/gettext'
import i18n, { i18nInterceptors } from './common/js/i18n'
import vueI18n from './i18n/locale'
import { initTrackEvent } from './omega';
import observerLog from '@didi/tools-observer-log'; // 曝光埋点
import { apiMonitorLog } from './common/js/tools.js'; // API监控埋点

const currentLang = getLanguageLong()
DDBridge.registerDefaultBridge()

Vue.use(DDBridge)
Vue.use(CouponUI)
Vue.use(i18n.plugin)
axios.interceptors.request.use(i18nInterceptors, error => Promise.reject(error))
didiaxios.interceptors.request.use(i18nInterceptors, error => Promise.reject(error))

// 请求响应拦截器
didiaxios.interceptors.response.use(
  (response) => {
    apiMonitorLog(response)
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

Vue.prototype.$http = axios
Vue.prototype.$didihttp = didiaxios

Vue.use(Cell)
Vue.use(Toast)
Vue.use(RadioGroup)
Vue.use(CheckboxGroup)
Vue.use(Button)
Vue.use(Dialog)
Vue.use(Locale)
Vue.use(Input)
Vue.use(Theme)
Vue.use(Tag)
Vue.use(DatePicker)
Vue.use(TextareaBox)
Vue.use(Alert)
const { country } = store.state

Theme.use("didi");

Vue.use(Lazyload, {
  preLoad: 1,
  attempt: 3, // 尝试计数
  filter: {
    progressive(listener, options) {
      const size = listener.el.getAttribute('crop-size')
      // SODA 图床暂只支持soda-public的服务
      const isSodaPublic = /soda-public/
      if (size && isSodaPublic.test(listener.src)) {
        listener.src = `${listener.src}!${size}_m`
      }
    }
  }
})
// 实时曝光
Vue.use(observerLog, {
  sailing_c_x_homepage_realexposure_sw: 'shop_id', // 商卡曝光
  sailing_c_x_top_shop_realexposure_sw: 'shop_id', // 好店商卡曝光
}, initTrackEvent);
Locale.use(currentLang, require(`@didi/rich-ui/lib/locale/lang/${currentLang}`))
CouponUI.locale.use(require(`@didi/soda-c-coupon/src/locale/lang/${currentLang}`).default)


initGlobalFilters()
Vue.config.productionTip = false

// Registering Our Service Worker
if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/sw-soda-act.js').then(registration => {
      console.log('SW registered: ', registration)
    }).catch(registrationError => {
      console.log('SW registration failed: ', registrationError)
    })
  })
}


// 12.22接入elvish：1、货币country 2、时间格式化locale timezone
const elvishLocale = `${getLocale().split('-')[0]}_${country}`
init().then(elvishInit(elvishLocale, country).then(() => {
  const capitalInfo = getCapitalCity(country)
  setTimezone(capitalInfo['timezone'])
  new Vue({
    el: '#app',
    i18n: vueI18n,
    router,
    store,
    render: h => h(App)
  })
}))
