function UPDATE_GLOBAL_PARAMS (state, payload) {
  state.globalParams = payload
}
function UPDATE_XTD_CONFIG (state, payload) {
  state.xtdConfig = payload
}
export default {
  UPDATE_GLOBAL_PARAMS,
  UPDATE_XTD_CONFIG
}
