<template>
  <div class="change-payment" @click="changePayment">
    <div class="left-box">
      <div v-if="hasCardInfo" class="pay-text">{{ $t('FoodC_V2_RlIh_OuIP') }}</div>
      <div v-else class="no-pay">
        <div class="no-pay-icon">
          <img src="../../../../assets/images/member/add_iocn.png" alt="" />
        </div>
        <div class="no-pay-text">
          <div class="no-pay-text1">{{ $t('FoodC_V2_ryoa_JKea') }}</div>
          <div class="no-pay-text2">{{ $t('FoodC_V2_syWI_awMo') }}</div>
        </div>
      </div>
    </div>
    <div class="right-box">
      <div v-if="hasCardInfo" class="card-box">
        <img :src="paymentMethod.cardOrg" alt="" />
        <div class="card-num">{{ paymentMethod.channelName }}</div>
      </div>
      <div class="arrow-icon">
        <img src="../../../../assets/images/member/pay_arrow_right.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
// bridge参考文档：https://cooper.didichuxing.com/knowledge/2199743584444/2199975558254
import { selectPayMethod } from '@/common/js/bridge.js'
import { getFintechParams } from '@/api/member/index.js'
import { mapState } from 'vuex'

export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ['hasCardInfo', 'paymentMethod'],
  data() {
    return {
      bridgeInfo: {},
    }
  },
  computed: {
    ...mapState(['membership', 'globalParams']),
  },
  mounted() {
    this.initGetFintechParams()
  },
  methods: {
    async initGetFintechParams() {
      const res = await getFintechParams({ renewPrice: this.membership?.renewPrice || 0 })
      if (res.errno === 0) {
        this.bridgeInfo = res?.data
      }
    },
    changePayment() {
      const { product_id, app_id } = JSON.parse(this.bridgeInfo?.biz_content || {})
      let pay_method = []
      if (this.hasCardInfo) {
        pay_method = [
          {
            channel_id: this.paymentMethod?.channelID,
            extra_info: { card_index: this.paymentMethod?.cardIndex },
          },
        ]
      }
      const { cityId, clientType, dataType, locationType, osType, osVersion } =
        this.globalParams?.data || {}
      const biz_device_info = JSON.stringify({
        cityId: cityId.toString(),
        clientType: clientType.toString(),
        dataType: dataType.toString(),
        locationType: locationType.toString(),
        osType: osType.toString(),
        osVersion: osVersion.toString(),
      })
      const bridgeParams = {
        biz_content: this.bridgeInfo?.biz_content,
        sign: this.bridgeInfo?.sign,
        sign_type: this.bridgeInfo?.sign_type,
        resource_id: this.bridgeInfo?.resource_id,
        product_id,
        app_id,
        pay_method,
        biz_device_info,
      }
      selectPayMethod(bridgeParams, res => {
        this.$emit('changePaymentFn', res)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.change-payment {
  background: #ffffff;
  box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.04);
  border-radius: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
}
.arrow-icon {
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 28px;
    height: 28px;
  }
}
.card-box {
  margin-right: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 43px 0;
  img {
    height: 36px;
    border-radius: 6px;
    margin-right: 8px;
  }
  .card-num {
    font-family: DiDiSans-Pro-Bold;
    font-size: 28px;
    color: #000000;
    text-align: right;
  }
}
.right-box {
  display: flex;
  justify-content: center;
  align-items: center;
}
.pay-text {
  width: 420px;
  font-family: DiDiSans-Pro-Regular;
  font-size: 28px;
  color: #000000;
  line-height: 28px;
}
.no-pay {
  display: flex;
  justify-content: center;
  align-items: center;
  .no-pay-icon {
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 16px;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
.no-pay-text {
  padding: 29px 0;
  .no-pay-text1 {
    height: 28px;
    font-family: DiDiSans-Pro-Regular;
    font-size: 28px;
    color: #ff8040;
    line-height: 28px;
    margin-bottom: 8px;
  }
  .no-pay-text2 {
    height: 22px;
    opacity: 0.4;
    font-family: DiDiSans-Pro-Regular;
    font-size: 22px;
    color: #000000;
    line-height: 22px;
  }
}
</style>
