<template>
  <div v-if="isShowSaleInfo">
    <div class="sale-info">
      <div class="info-icon"><img :src="saleInfo.iconUrl" alt="" /></div>
      <div class="title">{{ saleInfo.title }}</div>
      <div class="info">{{ saleInfo.content }}</div>
    </div>
    <div class="line-grey"></div>
  </div>
</template>

<script>
export default {
  props: ["noBuy", "saleInfo"],
  computed: {
    isShowSaleInfo(){
      // saleInfo.showStyle   //0旧样式，1新样式，用来区分实验,=1的时候不展示旧预计可省模块
      return this.noBuy && + this.saleInfo?.showStyle !==1
    }
  },
};
</script>

<style lang="scss" scoped>
.line-grey {
  height: 12px;
  background: #f7f7f7;
}
.sale-info {
  background: #fff;
  padding: 48px 32px;
  margin-top: 12px;

  .info-icon {
    padding-bottom: 12px;
    text-align: center;
    img {
      width: 100px;
      height: 100px;
    }
  }
  .title {
    font-family: DiDiSans-Pro-Bold;
    font-size: 32px;
    color: #ff8040;
    text-align: center;
    line-height: 45px;
  }
  .info {
    opacity: 0.6;
    font-family: DiDiSans-Pro-Regular;
    font-size: 24px;
    color: #000000;
    text-align: center;
    line-height: 32px;
  }
}
</style>
