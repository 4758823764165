<template>
  <van-popup
    v-model="showTips"
    position="bottom"
    :safe-area-inset-bottom="true"
    :close-on-popstate="true"
    class="upgrade-warn-box"
    :zIndex="999999"
  >
    <div class="content">
      <div class="content-scroll">
        <div class="close-dialog" @click="closeUpgradeWarn">
          <i class="icon-v3_outline_close"></i>
        </div>
        <div class="header-image">
          <img
            src="@/assets/images/member/upgrades_bg.gif"
            alt=""
          />
        </div>
        <div class="upgrade-title">
          {{ $t("FoodC_update_KqNE_JARP", { brand1: brandName }) }}
        </div>
        <div class="desc" v-html="desc"></div>
        <div class="benefit-content">
          <div class="benefit-box">
            <div class="benefit-image">
              <img src="@/assets/images/member/delivery.png" alt="" />
            </div>
            <div class="benefit-text-box">
              <div class="text-title-box">
                <div class="title-del">
                  <span class="title">{{ $t("FoodC_update_ZTvS_WSTC") }}</span>
                  <span class="del">x4</span>
                </div>
                <div class="new-benefit">
                  <span class="take">x</span>{{ $t("FoodC_update_KTVU_dCMP") }}
                </div>
              </div>
            </div>
            <div class="benefit-tips">{{ $t("FoodC_update_fIEP_oDcY") }}</div>
          </div>
          <div class="benefit-box">
            <div class="benefit-image">
              <img src="@/assets/images/member/service.png" alt="" />
            </div>
            <div class="benefit-text-box">
              <div class="text-title-box">
                <div class="title-del">
                  <span class="title">{{ $t("FoodC_update_rJfl_rGTF") }}</span>
                  <span class="del">x4</span>
                </div>
                <div class="new-benefit">
                  <span class="take">x</span>{{ $t("FoodC_update_KTVU_dCMP") }}
                </div>
              </div>
            </div>
            <div class="benefit-tips">{{ $t("FoodC_update_fIEP_oDcY") }}</div>
          </div>
          <div class="benefit-box">
            <div class="benefit-image">
              <img src="@/assets/images/member/trip.png" alt="" />
            </div>
            <div class="benefit-text-box">
              <div class="text-title-box">
                <div class="title-del">
                  <span class="title">{{ $t("FoodC_update_fyhl_EPiz") }}</span>
                </div>
                <div class="new-benefit">
                  <span class="take">x</span>{{ $t("FoodC_update_KTVU_dCMP") }}
                </div>
              </div>
            </div>
            <div class="benefit-tips benefit-tips-green">
              {{ $t("FoodC_update_mXRF_lKCK") }}
            </div>
          </div>
        </div>
      </div>
      <div class="btn" @click="closeUpgradeWarn">
        {{ $t("FoodC_update_ZFGk_VVRa") }}
      </div>
    </div>
  </van-popup>
</template>

<script>
import Vue from "vue";
import { Popup } from "vant";

Vue.use(Popup);
export default {
  model: {
    prop: "showTips",
    event: "showsChange",
  },
  computed: {
    shows: {
      get: function () {
        return this.showTips;
      },
      set: function (value) {
        this.$emit("showsChange", value);
      },
    },
    desc() {
      const money = `<span class="money-desc">${this?.money}</span>`;
      return this.$t("FoodC_update_pgdT_nUsj", {
        money,
      });
    },
  },
  props: {
    showTips: {
      type: Boolean,
      default: false,
      required: false,
    },
    brandName: {
      type: String,
      default: "",
    },
    money: {
      type: String,
      default: "",
    },
  },
  methods: {
    closeUpgradeWarn() {
      this.$emit("closeUpgradeWarn");
    },
  },
};
</script>

<style lang="scss" scoped>
.upgrade-warn-box {
  max-height: 92%;
  border-radius: 40px 40px 0 0;
  background-image: linear-gradient(179deg, #fefdf8 0%, #fdfae9 100%);
  overflow: hidden;
  display: flex;
}
.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  .content-scroll {
    overflow: auto;
  }
}
.header-image {
  width: 700px;
  height: 355px;
  margin: 29px auto 40px;
  img {
    width: 100%;
    height: 100%;
  }
}
.close-dialog {
  position: absolute;
  left: 40px;
  top: 32px;
  i {
    width: 48px;
    height: 48px;
    font-size: 48px;
  }
}
.upgrade-title {
  width: 686px;
  font-family: DiDiSans-Pro-Bold;
  font-size: 56px;
  color: #000000;
  text-align: center;
  line-height: 64px;
  margin: 0 auto 12px;
}
.desc {
  width: 686px;
  font-family: DiDiSans-Pro-Medium;
  font-size: 32px;
  color: #101938;
  text-align: center;
  line-height: 42px;
  margin: 0 auto 56px;
  ::v-deep .money-desc {
    color: #ff8040;
    font-family: DiDiSans-Pro-Bold;
  }
}
.benefit-box {
  width: 686px;
  background: #ffffff;
  box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.02);
  border-radius: 40px;
  margin: 0 auto 20px;
  display: flex;
  position: relative;
  align-items: center;
  overflow: hidden;
}
.benefit-image {
  width: 140px;
  height: 140px;
  margin: 16px 32px;
  img {
    width: 100%;
    height: 100%;
  }
}
.text-title-box {
  width: 384px;
  .title-del {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  .title {
    opacity: 0.6;
    font-family: DiDiSans-Pro-Medium;
    font-size: 28px;
    color: #101938;
    line-height: 32px;
    margin-right: 8px;
    display: inline-block;
  }
  .del {
    display: inline-block;
    opacity: 0.6;
    font-family: DiDiSans-Pro-Medium;
    font-size: 28px;
    color: #101938;
    line-height: 32px;
    text-decoration: line-through;
  }
  .new-benefit {
    font-family: DiDiSans-Pro-Bold;
    font-size: 32px;
    color: #000000;
    line-height: 40px;
    margin-top: 8px;
    .take {
      margin-right: 8px;
    }
  }
}
.btn {
  width: 686px;
  height: 108px;
  background: #ff8040;
  border-radius: 28px;
  font-family: DiDiSans-Pro-Bold;
  font-size: 36px;
  color: #ffffff;
  text-align: center;
  line-height: 108px;
  margin: 36px auto;
}
.benefit-tips {
  top: 25px;
  right: -60px;
  position: absolute;
  background: #ccc;
  text-align: center;
  font-family: DiDiSans-Pro-Bold;
  font-size: 22px;
  color: #ff8040;
  background-color: rgba($color: #ff8040, $alpha: 0.08);
  
  line-height: 24px;
  transform: rotate(-320deg);
  width: 220px;
  height: 40px;
  line-height: 40px;
}
.benefit-tips-green {
  color: #31b77e;
  background: #f5fbf8;
}
</style>
