import sim from './sim.js'
import pre from './pre.js'

const devHosts = {
  xtdApi: '//10.179.48.135:8200',
  bannerApi: '//10.179.133.206:8380',
  cApi: '//10.179.133.206:8380',
  landingApi: '//10.179.133.206:8380',
}

const testHosts = {
  xtdApi: '//10.179.48.135:8200',
  bannerApi: '//10.179.133.206:8380',
  cApi: '//10.179.133.206:8380',
  landingApi: '//10.179.133.206:8380',
}
const prodHosts = {
  xtdApi: '//d.didi-food.com',
  bannerApi: '//act-api.didi-food.com',
  cApi: '//c.didi-food.com',
  landingApi: '//act-api.didi-food.com',
}

let BASE_API_ACT = ''
let BASE_API_C = ''
let BASE_API_D = ''
let BASE_API_FULFILLMENT = ''
if (sim.isSim()) {
  BASE_API_ACT = sim.getSimActApi()
  BASE_API_C = sim.getSimCApi()
  BASE_API_D = sim.getSimDApi() // Dsim环境
  BASE_API_FULFILLMENT = sim.getSimFulfillmentApi() // 管控
  prodHosts.bannerApi = sim.getSimActApi()
  prodHosts.cApi = sim.getSimCApi()
} else if (pre.isPre()) {
  BASE_API_ACT = pre.getPreActApi()
  BASE_API_C = pre.getPreCApi()
  BASE_API_D = pre.getPreDApi()
  BASE_API_FULFILLMENT = pre.getPreFulfillmentApi()
  prodHosts.bannerApi = pre.getPreActApi()
  prodHosts.cApi = pre.getPreCApi()
} else {
  BASE_API_ACT = GlobalConfig.BASE_API_ACT
  BASE_API_C = GlobalConfig.BASE_API_C
  BASE_API_D = GlobalConfig.BASE_API_D
  BASE_API_FULFILLMENT = GlobalConfig.BASE_API_FULFILLMENT
}

const NODE_ENV = process.env.NODE_ENV
const hosts = NODE_ENV === 'development' ? devHosts : NODE_ENV === 'testing' ? testHosts : prodHosts

const isDev = NODE_ENV === '"development"'

export default {
  isDev,
  hosts,
  BASE_API_ACT,
  BASE_API_C,
  BASE_API_D,
  BASE_API_FULFILLMENT,
}
