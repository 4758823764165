<template>
  <div class="cancel-take">
    <ImmersionHeader 
      ref="ImmersionHeader" 
      :title="$t('FoodC_V2_NigJ_XaWd', { didipackbrand: brandName })"
      :blackTheme="true" 
      :titleStyle="titleStyle" 
      :isMonitorScroll="false" 
      @BackCK="BackCK" />
    <div class="page-info" :style="{ 'padding-top': headerHeight + 'px' }" v-if="!loading">
      <!-- 更改支付方式的组件 -->
      <div class="top-info">
        <ChangePayment :paymentMethod="paymentMethod" :hasCardInfo="hasCardInfo" @changePaymentFn="changePaymentFn"/>
        <div class="rule-desc" v-html="getRuleDesc" @click="openRule($event)"></div>
      </div>
      <div class="footer-btn">
        <rich-button size="max" @click="subscription" :disabled="btnDisabled">{{ $t('FoodC_V2_qWhb_IjVg') }}</rich-button>
      </div>
    </div>
    <LoadingMask v-if="loading" />
    <!-- 订阅的规则 -->
    <Rules
      v-model="isShowTakeRuleDialog"
      :rules="membership.subscriptionRules"
      title="FoodC_subscription_VPMP_seXT"
      zIndex="9999999"
    />
  </div>
</template>
  
<script>
import { getRenewPaymentMethod, subscribeMembership } from '@/api/member/index.js'
import LoadingMask from "@/components/loading/loading-mask.vue"
import ImmersionHeader from '@/components/immersionHeader/index.vue'
import ChangePayment from '../changePayment/index.vue'
import Rules from '../rules/index.vue'
import { mapState } from 'vuex'
import {getPaymentSDKVersion} from '@/common/js/bridge.js'
import { SCENE } from '../../common/enumsUtil'
import { isEmpty } from 'lodash-es'
import { membershipLandingCk } from "../../trackEvent.js";
export default {
  components: {
    LoadingMask,
    ImmersionHeader,
    ChangePayment,
    Rules
  },
  data() {
    return {
      loading: false,
      titleStyle: {
        color: '#000000',
        alpha: 1,
      },
      headerHeight: 0,
      isShowTakeRuleDialog:false,
      paymentMethod:{},
      hasCardInfo:false,
      payMethod:{},
      queryData:{}
    }
  },
  computed: {
    ...mapState(['membership','brandName']),
    getDate() {
      return this.membership?.renewDate || ''
    },
    getMoney2(){
      return this.membership?.renewPriceSplit?.display || ''
    },
    getRuleDesc(){
      return this.$t('FoodC_V2_kGDN_zGcg',{
        didifoodbrand: this.brandName, 
        date: this.getDate,
        money: this.getMoney2,
        rules: `<span class="rule">${this.$t("FoodC_subscription_VPMP_seXT")}</span>`})
    },
    btnDisabled(){
      return !this.payMethod?.channelID && !this.payMethod?.cardIndex
    }
  },
  mounted() {
    this.headerHeight = this.$refs?.ImmersionHeader?.$el?.offsetHeight || 0
    this.queryData = this.$route?.query;
    this.initData()
  },
  methods: {
    initData(val){
        getPaymentSDKVersion({}, async ({version}) => {
            this.loading = true
            const params = {
             scene: SCENE.RECOMMEND, 
             cashierSDKVersion: version,
              ...val
            }
            const res = await getRenewPaymentMethod(params)
            if(res.errno === 0) {
              this.paymentMethod = res?.data?.paymentMethod || {}
              this.payMethod = {
                channelID:this.paymentMethod?.channelID,
                cardIndex:this.paymentMethod?.cardIndex,
              }
              if(!isEmpty(this.paymentMethod)) {
                this.hasCardInfo = true
              }
            }else {
              this.createErrorToast(res.errmsg || '')
            }
            this.loading = false
        })
    },
    BackCK() {
      window.history.go(-1)
    },
    openRule(el){
      const isRule = el.target.className==='rule'
      if(isRule) {
        this.isShowTakeRuleDialog = true
      }
    },
    // 续订页面的支付方式回显
    async changePaymentFn(val) {
      if(val.changed===1) {
        // 更新当前视图
        const payMethod = val.pay_method[0] || {}
        this.payMethod = {
          channelID:payMethod?.channel_id*1,
          cardIndex:payMethod.extra_info?.card_index*1 || 0,
        }
        this.initData({...this.payMethod, scene: SCENE.ASSIGN})
       
      }
    },
    async subscription() {
      // 点击订阅时
      if(this.queryData?.isBuyAndNoSubscriptionStatus){
        membershipLandingCk({
          benefit_id: this.queryData?.benefitId,
          coupon_package_status: this.queryData?.couponPackageStatus,
          click_type: this.queryData?.clickType,
          benefit_type: this.queryData?.benefitType,
          add_coupon_list: this.queryData?.addCouponList,
          from: this.queryData?.from,
        }); 
      }
      const res = await subscribeMembership({
        ...this.payMethod
      })
      if(res.errno === 0) {
          this.BackCK()
      }else {
        this.createErrorToast(res.errmsg || '')
      }
    },
    createErrorToast(errmsg = '', defaultMsg = this.gettext('网络繁忙，请稍后再试')) {
      const errorToast = this.$createToast({
        txt: errmsg || defaultMsg,
        time: 2000,
        mask: false,
        type: 'error'
      })
      errorToast.show()
    },
  }
}
</script>
  
<style lang="scss" scoped>
.cancel-take {
  background: #fafafa;
  height: 100%;
  overflow: hidden;
}
.top-info {
  padding: 48px 32px 0;
  flex: 1;
}
.page-info {
  height: 100%;
  display: flex;
  flex-direction: column; 
  box-sizing: border-box; 
  .footer-btn{
    padding: 0 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 180px;
    background: #FFFFFF;
    border-radius: 40px 40px 0 0 ;
  }
}
.rule-desc{
  padding-top: 64px;
  padding-bottom:12px;
  font-family: DiDiSans-Pro-Regular;
  font-size: 22px;
  color: rgba(0, 0, 0, 0.4);
  text-align: justify;
  line-height: 32px;
}
::v-deep .rule {
  font-family: DiDiSans-Pro-Bold;
  font-size: 24px;
  color: #FF8040;
  text-decoration: underline;
}
</style>