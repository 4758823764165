<template>
    <div class="take-explain">
        <div class="take-explain-btn" @click="goManage">
            <div class="explain-btn">{{ $t('FoodC_subscription_QaXu_EIIa', { didipackbrand: brandName }) }}</div> 
            <img src="../../../../assets/images/member/pay_arrow_right.png" alt="">
        </div>
        <div class="take-explain-info">{{ $t('FoodC_subscription_eceZ_mHfd', {
            date: membership.renewDate,
            money: membership.renewPriceSplit.display
        }) }} <span class="rule" @click="openRule">{{ $t('FoodC_subscription_VPMP_seXT') }}</span></div>
    </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
    props: {
        membership: {
            default: () => {},
            type: Object
        }
    },
    computed: {
    ...mapState(['brandName']),
    },
    methods: {
        goManage() {
            this.$emit('goManage')
        },
        openRule() {
            this.$emit('openRule')
        }
    }
}
</script>

<style lang="scss" scoped>
.take-explain{
    padding: 0 32px 100px 32px;
    .take-explain-btn{
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
        margin-bottom: 48px;
        .explain-btn {
            flex: 1;
            font-family: DiDiSans-Pro-Bold;
            font-size: 36px;
            color: #000000;
            line-height: 132px;
            // word-break: break-all;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            /* 这里是超出几行省略 */
            overflow: hidden;
            margin-right: 30px;
        }
        img {
            width: 24px;
            height: 24px;
        }
    }
    .take-explain-info{
        opacity: 0.6;
        font-family: DiDiSans-Pro-Regular;
        font-size: 24px;
        color: #000000;
        line-height: 32px;
        .rule {
            font-family: DiDiSans-Pro-Medium;
            font-size: 24px;
            color: #000000;
            line-height: 24px;
            text-decoration: underline;
        }
    }
}
</style>