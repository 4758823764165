/* eslint-disable camelcase */
import trackEvent from '@/common/js/track'

export function GET_ENTRY_URL_INFO () {
  try {
    const referrer = document.referrer || ''
    const current_host = window.location.host
    const current_pathname = window.location.pathname
    const current_url = current_host + current_pathname
    let referrer_host = ''

    if (referrer) {
      const referrer_url = new URL(referrer)
      referrer_host = referrer_url.host
    }

    return {
      referrer,
      referrer_host,
      current_host,
      current_pathname,
      current_url
    }
  } catch (error) {
    return {}
  }
}

/** @desc 项目API请求监控埋点 */
export const apiMonitorLog = (response) => {
  try {
    if (response && +response.status === 200) {
      const { config, data } = response
      const { errno, traceId = '', trace_id = '', requestId = '', time = '' } = data

      trackEvent({
        eventId: 'sailing_c_api_monitor_bt',
        eventLabel: 'api请求上报',
        attrs: {
          request_errno: errno,
          request_path: config.url,
          trace_id: traceId || trace_id,
          page_url: window.location.href,
          request_id: requestId,
          request_time: time
        }
      })
    }
  } catch (err) {
    console.log(err)
  }
}

