<template>
    <!-- 注：在非购买条件下展示 -->
    <!-- 且只对有订阅的版本才展示 -->
    <div class="subscription-text">
        <div v-if="hasMarketingPrice"><span v-html="getPriceSubscriptionText"></span><span class="rule" @click="openRule">{{ $t("FoodC_subscription_VPMP_seXT") }}</span>
        </div>
        <div v-else>
            <span v-html="getNoPriceSubscriptionText"></span><span class="rule" @click="openRule">{{ $t("FoodC_subscription_VPMP_seXT") }}</span>
        </div>
    </div>
</template>

<script>
import { memberShipCommOptV3 } from '../../common/enumsUtil.js'
export default {
    computed: {
        getMemberShipCommOptV3() {
            return this.membership?.testConfig?.memberShipCommOptV3 || 0
        },
        isExperiment1() {
            return this.getMemberShipCommOptV3 === memberShipCommOptV3.experiment1
        },
        isExperiment2() {
            return this.getMemberShipCommOptV3 === memberShipCommOptV3.experiment2
        },
        getPriceSubscriptionText() {
            let money1 = this.money1
            let money2 = this.money2
            if(this.isExperiment1 || this.isExperiment2) {
                money1 = `<span class="overbold">${this.money1}</span>`
                money2 = `<span class="overbold">${this.money2}</span>`
            }
            return this.$t('FoodC_didipack_XQlZ_sNjR', {
                money1,
                money2,
                didipackbrand: this.didipackbrand,
                didifoodbrand: this.didifoodbrand
            }) 
        },
        getNoPriceSubscriptionText() {
            let money2 = this.money2
            if(this.isExperiment1 || this.isExperiment2) {
                money2 = `<span class="overbold">${this.money2}</span>`
            }
            return this.$t('FoodC_didipack_kIYJ_MqHw', {
                money2,
                didifoodbrand: this.didifoodbrand
            }) 
        }
    },
    props: ['hasMarketingPrice', 'money1', 'money2', 'didipackbrand', 'didifoodbrand', 'membership'],
    methods: {
        openRule() {
            this.$emit('openRule')
        },
    }
}
</script>

<style lang="scss" scoped>
.subscription-text {
    padding: 48px 32px;
    font-family: DiDiSans-Pro-Regular;
    font-size: 24px;
    color: rgba(0, 0, 0, 0.6);
    line-height: 32px;
    ::v-deep .overbold{
        opacity: 0.6;
        font-family: DiDiSans-Pro-Bold;
        font-size: 28px;
        color: #000000;
    }
}

.rule {
    font-family: DiDiSans-Pro-Medium;
    font-size: 24px;
    color: #000000;
    line-height: 24px;
    text-decoration: underline;
}

</style>