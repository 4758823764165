import didiaxios from '@didi/dajax/dist/dajax'
import apiConfig from '@/common/js/apiConfig'

const BASE_URL = apiConfig.BASE_API_ACT

// 获取用户任务
const GET_AVAILABLE_BENEFITS = BASE_URL + '/act-api/api/benefitgrowth/getAvailableBenefits'

const GET_PURCHASABLE_BENEFITS = BASE_URL + '/act-api/api/benefitgrowth/getPurchasableBenefits'

const GET_BENEFIT_PURCHASING_RECORDS = BASE_URL + '/act-api/api/benefitgrowth/getBenefitPurchasingRecords'
const GET_BENEFIT_DETAIL = BASE_URL + '/act-api/api/benefitgrowth/getBenefitDetail'

export const getBenefitDetail = function (data) {
  return requestPOST(GET_BENEFIT_DETAIL, data)
}

export const getBenefitPurchasingRecords = function (data) {
  return requestPOST(GET_BENEFIT_PURCHASING_RECORDS, data)
}

export const getPurchasableBenefits = function (data) {
  return requestPOST(GET_PURCHASABLE_BENEFITS, data)
}

export const getAvailableBenefits = function (data) {
  return requestPOST(GET_AVAILABLE_BENEFITS, data)
}

async function requestPOST (url, data) {
  let globalParams = await SodaBridge('getGlobalParams', 'badcase') || {}
  let paramsData = globalParams.data || {}
  return didiaxios.request({
    url: url,
    method: 'POST',
    emulateJSON: true,
    needEncrypt: true,
    params: '',
    body: { ...paramsData, ...data }
  }).then(res => (res.data)).catch((err) => {
    console.log(err)
    throw Error(err)
  })
}