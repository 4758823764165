<template>
  <div class="history-list" :class="{ 'expired': list.status == 2 }">
    <div class="left">
      <div class="history-name">{{ list.benefitTypeName }}</div>
      <div class="history-text" v-if="list.benefitType != 1">{{ $t('FoodC_subscription_yHJx_Ospz', {date1:list.enableDate, date2: list.expireDate}) }}
      </div>
      <div class="history-text">
        {{ $t("FoodC_subscription_DzKZ_WhvD", {date: list.purchasingTime}) }}
      </div>
      <div class="history-text1">
        {{ $t("FoodC_subscription_CYiq_FQpm", {money: list.priceSplit.display}) }}
      </div>
    </div>
    <div class="right">
      <!-- 生效中 -->
      <div class="status1" v-if="list.status == 1">{{ $t('FoodC_subscription_KlqY_fMjr') }}</div>
      <!-- 已失效 -->
      <div class="status2" v-else-if="list.status == 2">{{ $t('FoodC_subscription_pODC_SAYm') }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['list'],
  data(){
    return{
    }
  }
}
</script>

<style lang="scss" scoped>
.history-list {
  margin-bottom: 24px;
  background: #FFFFFF;
  box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.04);
  border-radius: 28px;
  display: flex;
  padding: 40px 24px;

  .left {
    width: 387px;

    .history-name {
      font-family: DiDiSans-Pro-Bold;
      font-size: 28px;
      color: #000000;
      line-height: 28px;
      margin-bottom: 20px;
    }

    .history-text {
      font-family: DiDiSans-Pro-Regular;
      font-size: 24px;
      color: rgba(0, 0, 0, 0.40);
      line-height: 24px;
      margin-bottom: 8px;
    }

    .history-text1 {
      font-family: DiDiSans-Pro-Regular;
      font-size: 24px;
      color: rgba(0, 0, 0, 0.40);
      line-height: 24px;
    }
  }

  .right {
    flex: 1;

    .status1,
    .status2 {
      font-family: DiDiSans-Pro-Bold;
      font-size: 28px;
      color: #FF8040;
      text-align: right;
      line-height: 28px;
    }
  }
}

.expired {
  .left {
    .history-name {
      opacity: 0.4;
      color: #000000
    }
  }

  .right {
    .status2 {
      opacity: 0.4;
      color: #000000
    }
  }
}</style>
