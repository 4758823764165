<template>
  <div :class="['retain-member-page', { 'retain-member-page-bg': !loading }]">
    <ImmersionHeader
      ref="ImmersionHeader"
      :isShowBackBtn="true"
      :blackTheme="true"
      @BackCK="BackCK"
    />
    <div v-if="!loading" class="retain-member-box">
      <div class="retain-member-text">
        <div class="retain-member-title">{{ reasonTitle }}</div>
        <div class="retain-member-sub">{{ subtitle }}</div>
      </div>
      <div class="btn-container">
        <rich-button class="btn-goback-member" @click="goBackMember">
          {{ $t('FoodC_unsubscription_EyKD_bpPY', { brand: brandName }) }}
        </rich-button>
        <rich-button outline="true" @click="cancelPack">
          {{ $t('FoodC_unsubscription_qlCR_GqbU') }}
        </rich-button>
      </div>
    </div>
    <LoadingMask v-if="loading" />
  </div>
</template>

<script>
import ImmersionHeader from '@/components/immersionHeader/index.vue'
import LoadingMask from '@/components/loading/loading-mask.vue'
import { membershipSubscribeKeepSw, membershipSubscribeKeepCk } from '../../trackEvent.js'
import { getUnsubscribeMembership, getUnsubscribeInfoDetail } from '@/api/member/index.js'
import { mapState } from 'vuex'
export default {
  components: {
    ImmersionHeader,
    LoadingMask,
  },
  data() {
    return {
      loading: false,
      headerHeight: 0,
      unSubscribeInfoData: {},
    }
  },
  computed: {
    ...mapState(['brandName']),
    reasonTitle() {
      return this.unSubscribeInfoData?.title
    },
    subtitle() {
      return this.unSubscribeInfoData?.subtitle
    },
    isFromMember() {
      return this.params?.retainFrom === 'memberPage'
    },
  },
  created() {},
  mounted() {
    this.headerHeight = this.$refs?.ImmersionHeader?.$el?.offsetHeight || 0
    this.getUnsubscribeInfo()
    membershipSubscribeKeepSw()
  },
  methods: {
    async getUnsubscribeInfo() {
      this.loading = true
      const res = await getUnsubscribeInfoDetail()
      this.unSubscribeInfoData = res.data
      this.loading = false
    },
    // 会员取消续订
    async cancelPack() {
      this.loading = true
      let res = {}
      try {
        res = await getUnsubscribeMembership()
      } catch {}
      if (res?.errno === 0) {
        this.membershipSubscribeKeepCkFn(1)
        // 取消订阅成功-toast提示
        const toast1 = this.$createToast({
          txt: this.$t('FoodC_pause_aidJ_DeYv'),
          time: 1000,
          type: 'warn',
          mask: false,
        })
        toast1.show()
        setTimeout(() => {
          this.goMemberPage()
        }, 1000)
      } else {
        this.$createToast({
          type: 'warn',
          time: 2000,
          txt: res.errmsg,
        }).show()
      }
      this.loading = false
    },
    BackCK() {
      window.history.go(-1)
    },
    // 回到会员页面
    goBackMember() {
      this.membershipSubscribeKeepCkFn(2)
      setTimeout(() => {
        this.goMemberPage()
      }, 300)
    },
    // 挽留页面CK埋点上报
    membershipSubscribeKeepCkFn(type) {
      membershipSubscribeKeepCk({
        click_type: type,
      })
    },
    // 跳转到会员页面
    goMemberPage() {
      // 如果从会员页面跳进来，则go(-1) 否则go(-3) 「会员页-> 管理页面 -> 原因选择页 ->取消挽留页」
      if (this.isFromMember) {
        this.$router.go(-1)
      } else {
        this.$router.go(-3)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.retain-member-page {
  height: 100%;
  background-color: #ffffff;
  .retain-member-box {
    padding-top: 480px;

    .retain-member-text {
      box-sizing: border-box;
      padding: 40px 32px 0px;
      background-color: #ffffff;
      border-radius: 60px 60px 0 0;
      .retain-member-title {
        line-height: 71px;
        font-family: DiDiSans-Pro-Bold;
        font-size: 60px;
        color: #000000;
      }
      .retain-member-sub {
        margin-top: 18px;
        line-height: 33px;
        opacity: 0.6;
        font-family: DiDiSans-Pro-Regular;
        font-size: 28px;
        color: #000000;
        letter-spacing: 0;
      }
    }
  }
}
.retain-member-page-bg {
  background-image: url('../../../../assets/images/member/retain_member_bg.png');
  background-size: 750px 540px;
  background-repeat: no-repeat;
}
/* 按钮样式 */
.btn-container {
  background-color: #ffffff;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 8;
  width: 100%;
  box-sizing: border-box;
  padding: 0 32px 45px;
  border-radius: 40px 40px 0 0;
  box-shadow: 0px -20px 40px 0px rgba(0, 0, 0, 0.04);
}
.btn-goback-member {
  margin: 24px 0;
}
</style>
