import { render, staticRenderFns } from "./loading-mask.vue?vue&type=template&id=7c8a26a2&scoped=true&"
var script = {}
import style0 from "./loading-mask.vue?vue&type=style&index=0&id=7c8a26a2&prod&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7c8a26a2",
  null
  
)

export default component.exports