export const MODULE_TYPE = {
  RULE: 1, // 右上角的历史记录, 命中实验的时候，1改为预计可省模块
  DETAIL: 2, // 累计已省模块
  BUY_BUTTON: 3, // 会员权益购买按钮
  PACKAGING: 4, // 加量包购买模块
  UPGRADE_BUTTON: 5, // 5-特型页面“开启订阅”按钮
  PAUSE_BUTTON: 32, // 「已开通-订阅暂停」状态点击了底部的恢复订阅按钮
  PAUSE_MANAGE: 33, // 代表「未开通-订阅暂停」状态点击了“暂停信息模块”的管理按钮。
}

export const PAY_STATUS = {
  SUCCESS: 1,
  CLOSE: 2,
  CANCEL: 3,
  CLIENT_SUCCESS: 0,
}

export const POP_DIALOG_POSITION = {
  MEMBER: 9,
}

// eslint-disable-next-line no-template-curly-in-string
export const HISTORY_PAGE = '/${locale}/memberHistory'
// eslint-disable-next-line no-template-curly-in-string
export const OLD_PACK_PAGE = '/${locale}/oldMember'
// eslint-disable-next-line no-template-curly-in-string
export const JOIN_RULES_PAGE = '/${locale}/joinRules'
export const DIDI_FOOD_BRAND = 'DiDi Food'

export const SCENE = {
  RENEW: 1,
  RECOMMEND: 2,
  ASSIGN: 3,
  PAUSE: 4,
}

export const SALE_CHANNEL = 21 // 21表示独立页购买
export const UPGRADES_CHANNEL = 22 // 22表示独立页升级

export const DIDI_PRO = 'DiDi Pro'
export const DIDI_PACK = 'DiDi Pack'

export const BACK_ICON_NAME = 'icon-v3_outlined_back'

export const COUPON_URL_DIDI =
  'https://c-h5.didi-food.com/${locale}/user/validCouponInfo?nativeWebUIType=2' // eslint-disable-line

export const BUY_COUPON_PACKAGE = 1 // 已经开通购买会员

// 1-免配 2-免服务费 3-额外权益 4-出行权益 5-生日权益 6-商家券 8-闪送券 9-fintech充话费返券
const freeDeliveryItem = 1
const freeServiceItem = 2
const otherBenefitItems = 3
export const mobilityCouponItem = 4
const birthdayItem = 5
const storeCouponItem = 6
export const entregaCouponItem = 8
const fintechTopUpItem = 9

// 埋点
export const BENEFIT_OPEN_DIALOG_TYPE = {
  [freeDeliveryItem]: 6, // 6-免配权益点击
  [freeServiceItem]: 7, // 7-免服务费权益点击
  [otherBenefitItems]: 11, // 8-会员商家券权益点击
  [mobilityCouponItem]: 9, // 9-5%off出行权益点击
  [birthdayItem]: 10, // 10-生日券权益点击
  [storeCouponItem]: 8, // 11-其他权益点击
  [entregaCouponItem]: 12, // 12-entrega权益点击;
  [fintechTopUpItem]: 13, // 13-充话费权益点击;
}

export const BENEFIT_JUMP_TYPE = {
  [otherBenefitItems]: 111, // 111-其他权益详情浮层内跳转按钮点击
  [mobilityCouponItem]: 91, // 91-出行权益详情浮层内跳转按钮点击
  [storeCouponItem]: 81, // 81-会员商家券权益详情浮层内跳转按钮点击
  [entregaCouponItem]: 121, // 121-entrega权益详情浮层内跳转按钮点击;
  [fintechTopUpItem]: 131, // 131-fintech充话费返券权益详情浮层内跳转按钮点击
}

export const GLOBAL_EMBED_CUSTOMER = 7 // 国际嵌入式C端

export const IS_FIRST_ENTRY = 'isFirstEntry' // 第一次进入app

export const memberShipCommOptV3 = {
  // 会员订阅信息沟通优化--3期设计方案新增实验
  experiment0: 0, // 对照组
  experiment1: 1, // 实验组1
  experiment2: 2, // 实验组2
}
// 随单购买场景
export const lockSalesChannelNum = {
  channelFood: 11, // 外卖随单购
  channelTravel: 13, // 出行随单购
  renew: 31, // 续订
}
// 会员订阅状态 1:未订阅 2:已订阅 3:暂停订阅
export const subscriptionStatusNum = {
  noSubscription: 1, // 未订阅
  inSubscription: 2, // 已订阅
  pauseSubscription: 3, // 暂停订阅
}

export const SCENARIO_CODE = 'WM-0009' // 新支付场景码

export const INTEGRATED_DIALOG_CLICK_TYPE = {
  SELECT_PAYMENT_METHOD: 1, // 支付方式选择行
  PAY_BTN: 2, // 支付按钮
}
