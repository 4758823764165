<template>
  <Popup :isShow="shows" :title="getTitleText" class="popup-box" @clickMask="shows = false">
    <div slot="content" class="popup-wrapper">
      <LoadingMask v-if="loading"></LoadingMask>
      <div class="rules-wrapper">
        <div class="change-payment">
          <ChangePayment
            :paymentMethod="paymentMethod"
            :hasCardInfo="hasCardInfo"
            @changePaymentFn="changePaymentFn"
          />
        </div>
        <template v-if="noBuy && isCanSubscribeVersion">
          <SubscriptionText
            :hasMarketingPrice="hasMarketingPrice"
            :money1="money1"
            :money2="money2"
            :didipackbrand="brandName"
            :didifoodbrand="DIDI_FOOD_BRAND"
            :membership="membership"
            @openRule="openRule"
          />
        </template>
      </div>
      <div class="rule-btn">
        <!-- 支付时显示气泡 -->
        <div class="bubble">
          <Bubble :bubbleContent="bubbleContent" />
        </div>
        <rich-button
          :disabled="!hasCardInfo || payBtnLoading"
          :loading="payBtnLoading"
          @click="goBuy"
        >
          <div class="price">
            <div class="price-box">
              {{ btnText }}
              <div v-if="isShowDelPrice" class="del">{{ delPrice }}</div>
            </div>
          </div>
        </rich-button>
        <div v-if="!hasCardInfo" class="btn-tips">{{ $t('FoodC_register_WoRy_tjec') }}</div>
      </div>
    </div>
  </Popup>
</template>

<script>
import Popup from '@/components/vant-popup'
import SubscriptionText from '../subscriptionText/index.vue'
import ChangePayment from '../changePayment/index.vue'
import LoadingMask from '@/components/loading/loading-mask.vue'
import Bubble from '@/components/bubble/index.vue'

import { getRecommendPaymentMethod } from '@/api/member/index.js'
import { mapState } from 'vuex'
import { isEmpty } from 'lodash-es'
import { getPaymentSDKVersion } from '@/common/js/bridge.js'

import {
  DIDI_FOOD_BRAND,
  INTEGRATED_DIALOG_CLICK_TYPE,
  memberShipCommOptV3,
} from '../../common/enumsUtil.js'
import { membershipPayPopupSw, membershipPayPopupCk } from '../../trackEvent.js'
export default {
  name: 'IntegratedPayDialog',
  components: {
    Popup,
    SubscriptionText,
    ChangePayment,
    LoadingMask,
    Bubble,
  },
  model: {
    prop: 'showTips',
    event: 'showsChange',
  },
  props: {
    showTips: {
      type: Boolean,
      default: false,
      required: false,
    },
    priceText: {
      type: String,
      default: '',
      required: true,
    },
    hasMarketingPrice: {
      type: String,
      default: '',
    },
    money1: {
      type: String,
      default: '',
    },
    money2: {
      type: String,
      default: '',
    },
    didipackbrand: {
      type: String,
      default: '',
    },
    didifoodbrand: {
      type: String,
      default: '',
    },
    membership: {
      type: String,
      default: '',
    },
    payBtnLoading: {
      type: Boolean,
      default: false,
    },
    bubbleContent: {
      type: String,
      default: '',
    },
    isCanSubscribe: {
      type: Boolean,
      default: false,
    },
    noSubscriptionStatus: {
      type: Boolean,
      default: false,
    },
    pauseSubscriptionStatus: {
      type: Boolean,
      default: false,
    },
    delPrice: {
      type: String,
      default: '',
    },
    isCanSubscribeVersion: {
      type: Boolean,
      default: false,
    },
    noBuy: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      DIDI_FOOD_BRAND,
      paymentMethod: {},
      hasCardInfo: false,
      loading: false,
    }
  },
  computed: {
    ...mapState(['brandName']),
    getTitleText() {
      return this.$t('FoodC_subscription_YJLt_vjMk', {
        didipackbrand: this.brandName,
      })
    },
    getMemberShipCommOptV3() {
      return this.membership?.testConfig?.memberShipCommOptV3 || 0
    },
    isExperiment0() {
      return this.getMemberShipCommOptV3 === memberShipCommOptV3.experiment0
    },
    isExperiment1() {
      return this.getMemberShipCommOptV3 === memberShipCommOptV3.experiment1
    },
    isExperiment2() {
      return this.getMemberShipCommOptV3 === memberShipCommOptV3.experiment2
    },
    isShowDelPrice() {
      if (this.isCanSubscribe) {
        return this.delPrice && this.isExperiment0
      }
      return this.delPrice
    },
    btnText() {
      // 能订阅
      if (this.isCanSubscribe) {
        // 未订阅
        if (this.noSubscriptionStatus || this.pauseSubscriptionStatus) {
          if (this.isExperiment1) {
            // 实验一
            if (this.hasMarketingPrice) {
              // 有营销价格
              return this.$t('FoodC_words_bshC_QpQz', {
                brand: this.brandName,
                money: ' ' + this.priceText,
              })
            }
            return this.$t('FoodC_words_vHza_gIgE', {
              brand: this.brandName,
              money: ' ' + this.priceText,
            })
          } else if (this.isExperiment2) {
            // 实验二
            return this.$t('FoodC_words_ikBq_XDxq', {
              brand: this.brandName,
              money: ' ' + this.priceText,
            })
          } else {
            // 对照组
            return (
              this.$t('FoodC_subscription_YJLt_vjMk', {
                didipackbrand: this.brandName,
              }) +
              ' ' +
              this.priceText
            )
          }
        } else {
          // 已经订阅
          return ''
        }
      }
      return this.$t('FoodC_C_Only_money_ZEKz', { money: this.priceText })
    },
    shows: {
      get: function () {
        return this.showTips
      },
      set: function (value) {
        this.$emit('showsChange', value)
      },
    },
    getTrackParams() {
      return {
        card_suffix: this.paymentMethod?.channelName || '无卡',
        sell_price: this.priceText,
      }
    },
  },
  watch: {
    shows(newV, oldV) {
      if (newV) {
        membershipPayPopupSw({ ...this.getTrackParams })
      }
    },
  },
  mounted() {
    this.initData()
  },
  methods: {
    openRule() {
      this.$emit('openRule')
    },
    initData(params) {
      getPaymentSDKVersion({}, async ({ version }) => {
        this.loading = true
        const res = await getRecommendPaymentMethod({ cashierSDKVersion: version, ...params })
        if (res.errno === 0) {
          this.paymentMethod = res?.data?.paymentMethod || {}
          if (!isEmpty(this.paymentMethod)) {
            this.hasCardInfo = true
          }
        }
        this.loading = false
      })
    },
    // 更改支付方式
    async changePaymentFn(val) {
      membershipPayPopupCk({
        ...this.getTrackParams,
        click_type: INTEGRATED_DIALOG_CLICK_TYPE.SELECT_PAYMENT_METHOD,
      })
      // 修改了支付方式
      if (val?.changed === 1) {
        // 更新当前视图
        const payMethod = val?.pay_method?.[0] || {}
        const paymentMethodInfo = {
          channelID: payMethod?.channel_id * 1,
          cardIndex: payMethod.extra_info?.card_index || 0,
        }
        // 获取更换支付卡信息
        this.initData(paymentMethodInfo)
      }
    },
    goBuy() {
      membershipPayPopupCk({
        ...this.getTrackParams,
        click_type: INTEGRATED_DIALOG_CLICK_TYPE.PAY_BTN,
      })
      this.$emit('goBuy', this.paymentMethod)
    },
  },
}
</script>

<style lang="scss" scoped>
.change-payment {
  margin-top: 40px;
  margin-bottom: 48px;
}
// 规则样式开始------------
.popup-box {
  ::v-deep .half-content {
    background: #fafafa;
  }
}
.popup-wrapper {
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  margin-top: 32px;
}
.rules-wrapper {
  overflow: auto;
  flex: 1;
  ::v-deep .subscription-text {
    padding: 0 0;
  }
}
.rule-list {
  .rule-title {
    padding: 40px 0;
    font-family: DiDiSans-Pro-Bold;
    font-size: 36px;
    color: #000000;
    line-height: 36px;
  }
  .rule-desc {
    opacity: 0.6;
    font-family: DiDiSans-Pro-Regular;
    font-size: 28px;
    color: #000000;
    line-height: 34px;
    margin-bottom: 32px;
  }
}
.rule-btn {
  position: relative;
  padding: 0 0 20px 0;
  margin-top: 120px;
  button {
    height: 108px;
  }
  .bubble {
    position: absolute;
    right: 0;
    top: -20px;
  }
  .price {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    .price-box {
      position: relative;
      width: fit-content;
      height: fit-content;
      .del {
        position: absolute;
        left: 100%;
        bottom: 0;
        height: 22px;
        opacity: 0.8;
        font-family: DiDiSans-Pro-Bold;
        font-size: 22px;
        color: #ffffff;
        line-height: 22px;
        text-decoration: line-through;
        margin-left: 8.5px;
      }
    }
  }
}
.btn-tips {
  padding-top: 24px;
  opacity: 0.6;
  font-family: DiDiSans-Pro-Regular;
  font-size: 28px;
  color: #000000;
  text-align: center;
  line-height: 36px;
}
// 规则样式结束------------
::v-deep .wrapper {
  .half-title {
    span {
      margin-left: 0;
    }
  }
}
</style>
