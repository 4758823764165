<template>
  <Popup
    :isShow="shows"
    :title="$t('FoodC_V2_NigJ_XaWd', { didipackbrand: brandName })"
    class="popup-box"
    :zIndex="zIndex"
    @clickMask="shows = false"
  >
    <div slot="content" class="popup-wrapper">
      <div class="page-info">
        <!-- 更改支付方式的组件 -->
        <div class="top-info">
          <ChangePayment
            v-if="shows"
            :paymentMethod="paymentMethod"
            :hasCardInfo="hasCardInfo"
            @changePaymentFn="changePaymentFn"
          />
          <div class="rule-desc" @click="openRule($event)" v-html="getRuleDesc"></div>
        </div>
        <div class="footer-btn">
          <rich-button size="max" :disabled="btnDisabled" @click="subscription">
            {{ $t('FoodC_V2_qWhb_IjVg') }}
          </rich-button>
        </div>
      </div>
    </div>
  </Popup>
</template>

<script>
import Popup from '@/components/vant-popup'

import { getRenewPaymentMethod, subscribeMembership } from '@/api/member/index.js'
import LoadingMask from '@/components/loading/loading-mask.vue'
import ImmersionHeader from '@/components/immersionHeader/index.vue'
import ChangePayment from '../changePayment/index.vue'
import { mapState } from 'vuex'
import { getPaymentSDKVersion } from '@/common/js/bridge.js'
import { SCENE } from '../../common/enumsUtil'
import { isEmpty } from 'lodash-es'
import { membershipLandingCk } from '../../trackEvent.js'

export default {
  name: 'JoinSubscribePopup',
  components: {
    Popup,
    // 原来的组件
    LoadingMask,
    ImmersionHeader,
    ChangePayment,
  },
  model: {
    prop: 'showTips',
    event: 'showsChange',
  },
  props: {
    zIndex: {
      type: String,
      default: '100',
    },
    title: {
      type: String,
      default: 'FoodC_page_eUPd_sDHg',
    },
    showTips: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data() {
    return {
      loading: false,
      titleStyle: {
        color: '#000000',
        alpha: 1,
      },
      paymentMethod: {},
      hasCardInfo: false,
      payMethod: {},
      queryData: {},
    }
  },
  computed: {
    shows: {
      get: function () {
        return this.showTips
      },
      set: function (value) {
        this.$emit('showsChange', value)
      },
    },

    // 原来订阅的逻辑
    ...mapState(['membership', 'brandName']),
    getDate() {
      return this.membership?.renewDate || ''
    },
    getMoney2() {
      return this.membership?.renewPriceSplit?.display || ''
    },
    getRuleDesc() {
      return this.$t('FoodC_V2_kGDN_zGcg', {
        didifoodbrand: this.brandName,
        date: this.getDate,
        money: this.getMoney2,
        rules: `<span class="rule">${this.$t('FoodC_subscription_VPMP_seXT')}</span>`,
      })
    },
    btnDisabled() {
      return !this.payMethod?.channelID && !this.payMethod?.cardIndex
    },
  },
  watch: {
    // 使用watch来监听shows的变化
    shows: {
      handler(nVal) {
        if (nVal) {
          // 只有在弹窗展示的时候才会执行initData请求/getRenewPaymentMethod接口
          this.initData()
        }
      },
    },
  },
  mounted() {
    this.queryData = this.$route?.query
    // this.initData();
  },
  methods: {
    closeDialog() {
      this.shows = false
      this.$emit('closeDialog')
    },

    // 原来订阅的逻辑
    initData(val) {
      getPaymentSDKVersion({}, async ({ version }) => {
        this.loading = true
        const params = {
          scene: SCENE.RECOMMEND,
          cashierSDKVersion: version,
          ...val,
        }
        const res = await getRenewPaymentMethod(params)
        if (res.errno === 0) {
          this.paymentMethod = res?.data?.paymentMethod || {}
          this.payMethod = {
            channelID: this.paymentMethod?.channelID,
            cardIndex: this.paymentMethod?.cardIndex,
          }
          if (!isEmpty(this.paymentMethod)) {
            this.hasCardInfo = true
          }
        } else {
          this.createErrorToast(res.errmsg || '')
        }
        this.loading = false
      })
    },
    openRule(el) {
      const isRule = el.target.className === 'rule'
      if (isRule) {
        this.$emit('getJoinRules')
      }
    },
    // 续订页面的支付方式回显
    async changePaymentFn(val) {
      if (val.changed === 1) {
        // 更新当前视图
        const payMethod = val.pay_method[0] || {}
        this.payMethod = {
          channelID: payMethod?.channel_id * 1,
          cardIndex: payMethod.extra_info?.card_index * 1 || 0,
        }
        this.initData({ ...this.payMethod, scene: SCENE.ASSIGN })
      }
    },
    async subscription() {
      // 点击订阅时
      if (this.queryData?.isBuyAndNoSubscriptionStatus) {
        membershipLandingCk({
          benefit_id: this.queryData?.benefitId,
          coupon_package_status: this.queryData?.couponPackageStatus,
          click_type: this.queryData?.clickType,
          benefit_type: this.queryData?.benefitType,
          add_coupon_list: this.queryData?.addCouponList,
          from: this.queryData?.from,
        })
      }
      const res = await subscribeMembership({
        ...this.payMethod,
      })
      if (res.errno === 0) {
        // 订阅成功的时候刷新会员页面
        localStorage.setItem('isJoinSuccessPopup', true)
        this.$emit('getJoinSuccess')
      } else {
        this.createErrorToast(res.errmsg || '')
      }
    },
    createErrorToast(errmsg = '', defaultMsg = this.gettext('网络繁忙，请稍后再试')) {
      const errorToast = this.$createToast({
        txt: errmsg || defaultMsg,
        time: 2000,
        mask: false,
        type: 'error',
      })
      errorToast.show()
    },
  },
}
</script>

<style lang="scss" scoped>
// 规则样式开始------------
.popup-wrapper {
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  margin-top: 32px;
}
// 规则样式结束------------
::v-deep .wrapper {
  .half-title {
    span {
      margin-left: 0;
    }
  }
}
.cancel-take {
  background: #fafafa;
  height: 100%;
  overflow: hidden;
}
.top-info {
  flex: 1;
}
.page-info {
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  .footer-btn {
    margin-top: 197px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 108px;
    background: #ffffff;
    border-radius: 40px 40px 0 0;
  }
}
.rule-desc {
  padding-top: 64px;
  padding-bottom: 12px;
  font-family: DiDiSans-Pro-Regular;
  font-size: 22px;
  color: rgba(0, 0, 0, 0.6);
  text-align: justify;
  line-height: 32px;
}
::v-deep .rule {
  font-family: DiDiSans-Pro-Medium;
  font-size: 24px;
  color: #000000;
  text-decoration: underline;
}
</style>
