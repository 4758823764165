class Pre {
  isPre() {
    return location.host.startsWith('pre.') || location.host.startsWith('pre-')
  }

  getPreCApi() {
    return 'https://pre-c.didi-food.com'
  }

  getPreActApi() {
    return 'https://pre-act-api.didi-food.com'
  }

  getPreDApi() {
    return 'https://pre-d.didi-food.com'
  }

  getPreFulfillmentApi() {
    return 'https://pre-fulfillment.didi-food.com'
  }
}

export default new Pre()
