import didiaxios from '@didi/dajax/dist/dajax'
import apiConfig from '@/common/js/apiConfig'

const BASE_URL = apiConfig.BASE_API_ACT

const DELIVERY_PRICE_DESC = BASE_URL + '/common/getDeliveryPriceDesc'
const GET_COUNTRY_LIST = BASE_URL + '/act-api/Api/Recactivity/getCountryList'
export const getDeliveryPriceDesc = function (data) {
  return didiaxios.request({
    url: DELIVERY_PRICE_DESC,
    method: 'POST',
    emulateJSON: true,
    needEncrypt: true,
    params: '',
    body: data
  })
}
export const getCountryList = function (data) {
  return didiaxios.request({
    url: GET_COUNTRY_LIST,
    method: 'GET',
    emulateJSON: true,
    needEncrypt: true,
    params: data
  })
}

export const getLocationByIp = function (data) {
  return didiaxios.request({
    url: BASE_URL + '/act-api/Api/Activitypage/getCityIdByIp',
    method: 'GET',
    emulateJSON: true,
    needEncrypt: true,
    params: data
  }).then(res => (res.data)).catch((err) => console.log(err))
}

const COMMON_CONFIG = apiConfig.BASE_API_C + '/common/config' // 根据经纬度反解country接口
const SHORT_LINK = BASE_URL + '/act-api/Growth/ShortUrl/add'
// const SHORT_LINK = 'https://mock.xiaojukeji.com/mock/776/act-api/Growth/ShortUrl/add'

export const getAddress = function (data) {
  return didiaxios.request({
    url: COMMON_CONFIG,
    method: 'POST',
    emulateJSON: true,
    needEncrypt: true,
    params: '',
    body: data
  }).then(res => (res.data)).catch((err) => console.log(err))
}

export const getShortLink = async function (data) {
  // return new Promise(resolve => {
  //   resolve({
  //     "errno": 0,
  //     "errmsg": "ok",
  //     "time": 1592825258,
  //     "data": {
  //       "success": true,
  //       "msg": "",
  //       "short_url": data.url,
  //       "short_url_99": data.url
  //     },
  //     "requestId": "f_5ef095aa5dafa"
  //   })
  // })
  let globalParams = await SodaBridge('getGlobalParams', 'badcase') || {}
  let paramsData = globalParams.data || {}
  return didiaxios.request({
    url: SHORT_LINK,
    method: 'POST',
    emulateJSON: true,
    needEncrypt: true,
    params: '',
    body: { ...data, ...paramsData }
  }).then(res => (res.data)).catch((err) => console.log(err))
}
