<template>
    <div class="bill-footer-container" :class="{ 'can-subscribe': isCanSubscribe }">
        <div class="btn-container">
            <rich-button @click="goBack">
                {{ $t('FoodC_subscription_uipE_quiy') }}
            </rich-button>
            <!-- <div v-if="isCanSubscribe && noSubscriptionStatus" class="subscribe-rule" v-html="ruleText" @click="openRule">
            </div> -->
        </div>
    </div>
</template>
<script>

export default {
    name: 'BillFooter',
    props: ['isCanSubscribe', 'noSubscriptionStatus'],
    computed: {
        ruleText() {
            const rule = this.$t('FoodC_subscription_VPMP_seXT')
            const text = `<span class="subscribe-rule-info">${rule}</span>`
            return this.$t('FoodC_subscription_StNY_sWmE', { rules: text })
        }
    },
    methods: {
        goBack() {
            this.$emit('goBack')
        },
        openRule() {
            this.$emit('openRule')
        }
    }

}
</script>
<style lang="scss" scoped>
.bill-footer-container {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 8;
    width: 750px;
    background: #FFFFFF;
    padding: 36px 24px 45px 24px;
    box-sizing: border-box;
    box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.04);
    border-radius: 40px 40px 0 0;
    .subscribe-rule {
        padding: 32px 24px 36px 24px;
        opacity: 0.4;
        font-family: DiDiSans-Pro-Regular;
        font-size: 22px;
        color: #000000;
        line-height: 26px;
        text-align: center;

        ::v-deep .subscribe-rule-info {
            font-family: DiDiSans-Pro-Bold;
            font-size: 22px;
            color: #FF7825;
            line-height: 26px;
            margin-left: 4px;
            text-decoration: underline;
        }
    }
    .rich-btn {
        height: 108px;
    }
}
  
</style>