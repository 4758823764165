import didiaxios from '@didi/dajax/dist/dajax'
import apiConfig from '@/common/js/apiConfig'

const BASE_ACT_URL = apiConfig.BASE_API_ACT
const BASE_URL = apiConfig.BASE_API_C
const BASE_API_FULFILLMENT = apiConfig.BASE_API_FULFILLMENT

// 获取会员页面的数据
const GET_MEMBERSHIP_DETAIL = BASE_ACT_URL + '/act-api/api/benefitgrowth/getMembershipDetail'

// 收银台2.0创建订单
const GET_BENEFITS_CREATE_V2 = BASE_URL + '/memberBenefit/createV2'

// 收银台2.0创建订单管控接口
const GET_BENEFITS_CREATE_V2_FULFILLMENT = BASE_API_FULFILLMENT + '/memberBenefit/createV2'

// 运营位弹窗
const GET_POP_DIALOG = BASE_ACT_URL + '/act-api/growth/common/popDialog'

// 取消订阅
const GET_UNSUBSCRIBE_MEMBERSHIP = BASE_ACT_URL + '/act-api/api/benefitgrowth/unsubscribeMembership'

// 获取更改支付方式的bridge的入参
const GET_FINTECH_PARAMS = BASE_ACT_URL + '/act-api/api/benefitgrowth/getFintechParams'

// 获取上一次的支付方式
const GET_RENEW_PAYMENT_METHOD = BASE_ACT_URL + '/act-api/api/benefitgrowth/getRenewPaymentMethod'

// 更改支付方式
const CHANGE_RENEW_PAYMENT_METHOD =
  BASE_ACT_URL + '/act-api/api/benefitgrowth/changeRenewPaymentMethod'

// 订阅
const SUBSCRIBE_MEMBERSHIP = BASE_ACT_URL + '/act-api/api/benefitgrowth/subscribeMembership'

// 修改个人信息
const UPDATE_USER_INFO = BASE_URL + '/user/updateUserInfo'

// 领取生日礼物
const GET_BIRTHDAT_BENEFIT = BASE_ACT_URL + '/act-api/api/benefitgrowth/getBirthdayBenefit'

// 免费发放会员
const MEMBER_BUY_FREE = BASE_ACT_URL + '/act-api/api/benefitgrowth/memberBuyFree'

// 获取老的权益
const GET_OLD_MEMBERSHIP_DETAIL = BASE_ACT_URL + '/act-api/api/benefitgrowth/getOldMembershipDetail'

// 权益升级接口
const BENEFIT_UPGRADE = BASE_ACT_URL + '/act-api/api/benefitgrowth/benefitUpgrade'

// 点击购买时判断是否重复购买
const CHECK_BUY_MEMBER = BASE_ACT_URL + '/act-api/api/benefitgrowth/checkBuyMember'

// 根据商家ID发送会员商家券
const SEND_MEMBERSHIP_STORE_COUPON_BY_SHOP_ID =
  BASE_ACT_URL + '/act-api/api/benefitgrowth/sendMembershipStoreCouponByShopID'

// 会员取消续订原因接口 /act-api/api/benefitgrowth/getUnsubscribeInfo
const GET_UNSUBSCRIBEINFON = BASE_ACT_URL + '/act-api/api/benefitgrowth/getUnsubscribeInfo'

// 会员暂停订阅页面
const GET_PAUSE_PAGE_INFO = BASE_ACT_URL + '/act-api/api/benefitgrowth/getPausePageInfo'

// 暂停订阅接口
const PAUSE_MEMBERSHIP_SUBSCRIPTION =
  BASE_ACT_URL + '/act-api/api/benefitgrowth/pauseMembershipSubscription'

// 恢复订阅
const RESUME_MEMBERSHIP_SUBSCRIPTION =
  BASE_ACT_URL + '/act-api/api/benefitgrowth/resumeMembershipSubscription'

// 获取财务推荐支付卡第一个
const GET_RECOMMEND_PAYMENT_METHOD =
  BASE_ACT_URL + '/act-api/api/benefitgrowth/getRecommendPaymentMethod'

export const getRecommendPaymentMethod = function (data) {
  return requestPOST(GET_RECOMMEND_PAYMENT_METHOD, data)
}

export const getMembershipDetail = function (data) {
  return requestPOST(GET_MEMBERSHIP_DETAIL, data)
}

export const getBenefitsCreateV2Fulfillment = function (data) {
  return requestPOST(GET_BENEFITS_CREATE_V2_FULFILLMENT, data)
}
export const getBenefitsCreateV2 = function (data) {
  return requestPOST(GET_BENEFITS_CREATE_V2, data)
}

export const getPopDialog = function (data) {
  return requestPOST(GET_POP_DIALOG, data)
}

export const getUnsubscribeMembership = function (data) {
  return requestPOST(GET_UNSUBSCRIBE_MEMBERSHIP, data)
}

export const getFintechParams = function (data) {
  return requestPOST(GET_FINTECH_PARAMS, data)
}

export const getRenewPaymentMethod = function (data) {
  return requestPOST(GET_RENEW_PAYMENT_METHOD, data)
}

export const changeRenewPaymentMethod = function (data) {
  return requestPOST(CHANGE_RENEW_PAYMENT_METHOD, data)
}

export const subscribeMembership = function (data) {
  return requestPOST(SUBSCRIBE_MEMBERSHIP, data)
}
export const updateUserInfo = function (data) {
  return requestPOST(UPDATE_USER_INFO, data)
}

export const getBirthdayBenefit = function (data) {
  return requestPOST(GET_BIRTHDAT_BENEFIT, data)
}

export const memberBuyFree = function (data) {
  return requestPOST(MEMBER_BUY_FREE, data)
}

export const getOldMembershipDetail = function (data) {
  return requestPOST(GET_OLD_MEMBERSHIP_DETAIL, data)
}
export const benefitUpgrade = function (data) {
  return requestPOST(BENEFIT_UPGRADE, data)
}

export const sendMembershipStoreCouponByShopID = function (data) {
  return requestPOST(SEND_MEMBERSHIP_STORE_COUPON_BY_SHOP_ID, data)
}

// 会员取消原因接口
export const getUnsubscribeInfoDetail = function (data) {
  return requestPOST(GET_UNSUBSCRIBEINFON, data)
}
export const getCheckBuyMember = function (data) {
  return requestPOST(CHECK_BUY_MEMBER, data)
}
// 暂停订阅页面接口
export const getPausePageInfoDetail = function (data) {
  return requestPOST(GET_PAUSE_PAGE_INFO, data)
}
// 订阅暂停接口
export const pauseMembershipSubscription = function (data) {
  return requestPOST(PAUSE_MEMBERSHIP_SUBSCRIPTION, data)
}
// 恢复订阅
export const resumeMembershipSubscription = function (data) {
  return requestPOST(RESUME_MEMBERSHIP_SUBSCRIPTION, data)
}

async function requestPOST(url, data) {
  const globalParams = (await SodaBridge('getGlobalParams', 'badcase')) || {}
  const paramsData = globalParams.data || {}
  return didiaxios
    .request({
      url,
      method: 'POST',
      emulateJSON: true,
      needEncrypt: true,
      params: '',
      body: { ...paramsData, ...data },
    })
    .then(res => res.data)
    .catch(err => console.log(err))
}
