<template>
    <div class="retryPage">
        <img src="../../../../assets/images/empty_icon.png" alt="">
        <div class="desc">{{$t('FoodC_homepage_Unable_to_ybHC')}}</div>
       <div class="btn"><rich-button :outline="true" size="medium" @click="retry">{{gettext('重试')}}</rich-button></div>
    </div>
</template>

<script>
export default {
    methods:{
        retry() {
            this.$emit('retry')
        }
    }
}
</script>

<style lang="scss" scoped>
.retryPage{
    display: flex;
    justify-content: center;
    flex-direction: column;
    img {
        width: 300px;
        height: 300px;
        margin: 300px auto 0;
    }
    .desc {
        padding-top: 10px;
font-family: DiDiSans-Pro-Bold;
font-size: 32px;
color: #000000;
text-align: center;
line-height: 32px;
    }
    .btn {
         padding-top: 40px;
        width: 400px;
        margin: 0 auto;
    }
}
</style>