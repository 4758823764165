export function getGlobalParams() {
  try {
    return SodaBridge('getGlobalParams', 'badcase').then(resp => {
      return getBridgeData(resp)
    })
  } catch (e) {
    console.log(e)
  }
}

// 用户信息
export function getUserInfo() {
  return SodaBridge('getUserInfo', {}).then(resp => {
    return getBridgeData(resp)
  })
}

// 商铺信息
export function getShopInfo() {
  return SodaBridge('merchant.getShopInfo', 'badcase').then(resp => getBridgeData(resp))
}

// 骑手信息
export function getRiderInfo() {
  return SodaBridge('delivery.getRiderInfo', 'badcase').then(resp => getBridgeData(resp))
}

// 骑手端：展示端上功能 style 的值为2 表示菜单 1表示返回键
export function getRiderBackBtn(style = 1) {
  const Soda = window.Soda || {}
  Soda.rider.updateMainPageLeftItemStyle({
    style,
  })
}

export function getContractorInfo() {
  return SodaBridge('merchant.getContractorInfo', 'badcase').then(resp => getBridgeData(resp))
}

export function openPage() {
  return SodaBridge('merchant.openPage', 'badcase').then(resp => getBridgeData(resp))
}

export function setTitle(naviTitle, timeout) {
  // 延时设置标题，处理ios设置不成功问题
  setTimeout(() => {
    SodaBridge('setTitle', { navi_title: naviTitle })
  }, timeout || 300)
}
export function setNavigationBarStyle(navInfo, timeout) {
  // 延时设置标题，处理ios设置不成功问题
  setTimeout(() => {
    SodaBridge('setNavigationBarStyle', navInfo)
  }, timeout || 300)
}

export function closePage() {
  return SodaBridge('closePage').then(resp => {
    getBridgeData(resp)
  })
}
export function getLocationInfo() {
  return SodaBridge('getLocationInfo', {}).then(resp => {
    getBridgeData(resp)
  })
}

function getBridgeData(resp = {}) {
  const { errno = 0 } = resp
  if (errno.toString() === '0') {
    return resp.data || resp
  }
}

export function showNavigationBarRightItem(text, callback) {
  return SodaBridge('rider.showNavigationBarRightItem', { text }).then(resp => {
    callback()
  })
}

// 拦截客户端返回
export function setBackPressListener(isAlive, callback) {
  return SodaBridge('setBackPressListener', isAlive).then(resp => {
    console.log()
    callback && callback()
  })
}
// 金融bridge参考文档：https://cooper.didichuxing.com/knowledge/2199743584444/2199975558254
// 调用金融的bridge
export function pullUpPayment(params, callback) {
  try {
    window.Fusion.loadModule('payment').universalPay(params, callback)
  } catch {}
}

// 获取金融sdk版本号
export function getPaymentSDKVersion(params, callback) {
  try {
    window.Fusion.loadModule('payment').getSDKVersion(params, callback)
  } catch {}
}

// 更改支付方式
export function selectPayMethod(params, callback) {
  try {
    window.Fusion.loadModule('payment').selectPayMethod(params, callback)
  } catch {}
}

// 跳转至乘客端首页
export function switchToRider(params, callback = function () {}) {
  try {
    window.Fusion.loadModule('Customer').switchToRider(params, callback)
  } catch {}
}
// 嵌入端 版本，临时内存
export function autoSelectAddress(params, callback = function () {}) {
  try {
    window.Fusion.loadModule('Customer').autoSelectAddress(params, callback)
  } catch {}
}

// 集成收银台
export function doPayV2(params, callback = function () {}) {
  try {
    window.Fusion.loadModule('Customer').doPayV2(params, callback)
  } catch {}
}
