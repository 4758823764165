import * as brandText from './list/brand-text'
import * as brandBg from './list/brand-bg'
import * as bannerBgConfig from './list/banner-bg-config'
import * as deepLinkUrl from './list/deeplink-url'
import * as appLinkUrl from './list/applink-url'
import * as middleLink from './list/middle-link'
import * as customContant from './list/custom-contact'
import * as couponBg from './list/coupon-bg'

function configMixin (targetConfig = {}) {
  const defaultCountry = 'MX'
  const finalConfig = {}
  Object.keys(targetConfig).map(key => {
    // target 可以是国家也可以是品牌
    finalConfig[key] = (target) => {
      return targetConfig[key][target] || targetConfig[key][defaultCountry]
    }
  })
  return finalConfig
}

export default {
  brandText: configMixin(brandText),
  brandBg: brandBg, // 品牌配置
  bannerBgConfig: configMixin(bannerBgConfig),
  deepLinkUrl: configMixin(deepLinkUrl),
  middleLink: configMixin(middleLink),
  customContant: configMixin(customContant),
  couponBg: configMixin(couponBg),
  appLinkUrl: configMixin(appLinkUrl)
}
