<template>
  <div class="coupon-history-box">
    <div class="main">
      <template v-if="benefits.length">
        <van-list v-model="loading" :finished="finished" @load="initData">
          <HistoryList
            v-for="(item, index) in benefits"
            :key="index"
            :list="item"
          />
          <div slot="loading"></div>
          <div slot="finished">
            <div class="finished">
              {{ $t("soda-d-i18n---没有更多了") }}
            </div>
          </div>
        </van-list>
      </template>
      <div v-else-if="loading"></div>
      <template v-else>
        <div class="no-list">
          <img
            class="no-list-icon"
            src="../../../../assets/images/couponCenter/no_result.png"
            alt=""
          />
          <!-- todo 待翻译 -->
          <div class="no-list-text">{{$t('FoodC_C_No_purchase_cMRq')}}</div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { List } from 'vant'
import { isEmpty } from 'lodash-es'
import { getBenefitPurchasingRecords } from '@/api/couponCenter/index.js'
import HistoryList from './list.vue'

Vue.use(List)

export default {
  components: {
    HistoryList
  },
  data () {
    return {
      loading: false,
      finished: false,
      pageNo: 1,
      pageSize: 20,
      benefits: []
    }
  },
  mounted () {
    this.asyncSetTitle({
      navi_title: this.$t('FoodC_subscription_Wlpe_bBkf'),
      timeout: 300
    })
    this.initData()
  },
  methods: {
    async initData () {
      this.loading = true
      const res = await getBenefitPurchasingRecords({
        pageNo: this.pageNo,
        pageSize: this.pageSize,
      })
      this.loading = false
      const data = res.data
      if (!isEmpty(data)) {
        data.benefits.map((item) => {
          this.benefits.push(item)
        })
        if (data.next == 1) { // eslint-disable-line
          this.pageNo++
        } else {
          this.finished = true
        }
      } else {
        this.finished = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.coupon-history-box {
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #fafafa;
}
.main {
  flex: 1;
  overflow: auto;
  padding: 24px 32px;
  background: #fafafa;
}
.finished {
  font-family: "Aspira Regular";
  font-size: 24px;
  color: #999999;
  font-weight: 400;
  text-align: center;
  margin-bottom: 30px;
}
.no-list {
  padding-top: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 288px;
    height: 288px;
    margin: 0 auto;
  }
  .no-list-text {
    padding: 0 30px;
    font-family: "Aspira Medium";
    font-size: 36px;
    color: #000000;
    text-align: center;
    font-weight: 500;
    // word-break: break-all;
    word-wrap: break-word;
  }
}
</style>
