<template>
  <div class="join-rules-page">
    <div class="rules-wrapper" :style="{ 'margin-top': headerHeight + 'px' }">
      <div v-for="(item, index) in rules" :key="index" class="rule-list">
        <div v-if="item.title" class="rule-title">{{ item.title }}</div>
        <div v-for="(desc, idx) in item.content" :key="idx" class="rule-desc">{{ desc }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { getMembershipDetail } from '@/api/member/index.js'
export default {
  components: {},
  data() {
    return {
      titleStyle: {
        color: '#000000',
        alpha: 1,
        queryData: {},
      },
      headerHeight: 0,
      membership: {},
    }
  },
  computed: {
    rules() {
      return this.membership.subscriptionRules
    },
    getNoSubscribe() {
      const noSubscribe = this.params?.noSubscribe || 0
      return noSubscribe * 1
    },
  },
  created() {
    this.getInit()
  },
  mounted() {
    this.queryData = this.$route.query
    this.asyncSetTitle({
      navi_title: this.$t('FoodC_subscription_VPMP_seXT'),
      timeout: 300,
    })
  },
  methods: {
    async getInit() {
      const res = await getMembershipDetail({
        noSubscribe: this.getNoSubscribe,
      })
      if (res?.errno === 0) {
        this.pageInfo = res?.data
        this.membership = this.pageInfo?.membership || {}
      } else {
        this.createErrorToast(res?.errmsg || '')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.join-rules-page {
  background-color: #ffffff;
  min-height: 100%;
  display: flex;
  flex-direction: column;
}
.rules-wrapper {
  width: 100%;
  box-sizing: border-box;
  flex: 1;
  padding: 40px;
}
.rule-list {
  .rule-title {
    padding: 40px 0;
    font-family: DiDiSans-Pro-Bold;
    font-size: 36px;
    color: #000000;
    line-height: 36px;
  }
  .rule-desc {
    opacity: 0.6;
    font-family: DiDiSans-Pro-Regular;
    font-size: 28px;
    color: #000000;
    line-height: 34px;
    margin-bottom: 32px;
  }
}
</style>
