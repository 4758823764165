import { urlParse, getIsApp } from '@/common/js/utils'
import { requireParams } from '@/common/js/api-require-param'
import { GET_URL_COUNTRY, GET_BRIDGE_COUNTRY, GET_BRIDGE_POSITION, GET_BROWSER_POSITION, GET_API_PARSED_COUNTRY, GET_FINAL_COUNTRY } from './getCountryTrack'
import Api from '@/api'
import Vue from 'vue'

const host = location.host
const is99App = !!host.match('99app.com')
const COUNTRY = is99App ? 'BR' : 'MX'
const brand = is99App ? '99' : 'didi'

const state = {
  country: COUNTRY,
  brand,
  openedCityList: []
}

const getters = {}

const actions = {
  /**
   * 异步获取country，用于 DiDi Food 多国差异化处理
   * 第一步：获取 url 中的 poiCountryCode（收货地址反解）若没有则获取 countryCode（真实定位反解）
   * 第二步：通过 getGlobalParams 获取 poiCountryCode（收货地址反解）若没有则获取 countryCode（真实定位反解）
   * 第三步：
   *   - 通过 bridge（独立端用getGlobalParams、嵌入端用Fusion.getLocationInfo） 获取真实定位 {lat, lng}
   *   - 请求 c-api common/config 接口反解 country
   * 若以上步骤均失败，默认取墨西哥 country: MX
  */
  async GET_COUNTRY ({ commit }, params) {
    let country = COUNTRY
    if (country === 'BR') {
      return 'BR'
    }
    const QUERY_PARAMS = urlParse()
    // 第一步：先从 URL query 参数中获取 country 参数
    const COUNTRY_FROM_URL = QUERY_PARAMS['poiCountryCode'] || QUERY_PARAMS['countryCode'] // 端上确定后，换成最终的参数名
    GET_URL_COUNTRY({ poi_country_code: QUERY_PARAMS['poiCountryCode'], country_code: QUERY_PARAMS['countryCode'] })
    // 第二步：如果从URL获取失败，则通过bridge获取
    if (COUNTRY_FROM_URL) {
      country = COUNTRY_FROM_URL
    } else {
      let globalParams = {}
      try {
        // 只在独立端内可以通过bridge获取country
        const RES = await SodaBridge('getGlobalParams', 'badcase') || {}
        globalParams = RES.data
      } catch (e) {}
      // D、C两端的公参不同  所有需要区分端
      let COUNTRY_FROM_BRIDGE = ''
      
      // 获取是否在 B、D app 内
      const  { isInDApp } = getIsApp()
      // alert(`globalParams['country']: ` + globalParams['country'] + "，isInDApp: " + isInDApp + "，isInBApp: " + isInBApp)
      if (isInDApp || Vue.isInBApp) { // TODO: 兼容B端bridge
        COUNTRY_FROM_BRIDGE = globalParams['country']
      } else {
        COUNTRY_FROM_BRIDGE = globalParams['poiCountryCode'] || globalParams['countryCode']
      }
      GET_BRIDGE_COUNTRY({
        country: globalParams['country'],
        poi_country_code: globalParams['poiCountryCode'],
        countryCode: globalParams['countryCode']
      })
      // 第三步：如果bridge获取country也失败，则通过 bridge 获取定位，请求接口反解得到country
      if (COUNTRY_FROM_BRIDGE) {
        country = COUNTRY_FROM_BRIDGE
      } else {
        let position = { lat: '', lng: '' }
        // 独立端先获取poiLat及poiLng
        let { poiLat, poiLng, lat, lng } = globalParams
        GET_BRIDGE_POSITION({
          poi_lat: poiLat,
          poi_lng: poiLng,
          lat,
          lng
        })
        if (poiLat && poiLng) {
          position.lat = poiLat
          position.lng = poiLng
        } else if (lat && lng) {
          position.lat = lat
          position.lng = lng
        } else {
          // 再通过fusion bridge 获取一次定位（比如在嵌入端容器内）
          try {
            const LOCATION_INFO = await new Promise((resolve, reject) => {
              Fusion?.getLocationInfo({}, function (response) {
                resolve(response)
              })
            })
            position.lat = LOCATION_INFO.lat
            position.lng = LOCATION_INFO.lng
          } catch (e) {}
          // bridge获取不到时，尝试使用H5自身的能力获取定位
          if (!position.lat && !position.lng) {
            if (navigator.geolocation) {
              try {
                const COORDS = await new Promise((resolve) => {
                  // 关于getCurrentPosition Api的文档
                  // https://developer.mozilla.org/zh-CN/docs/Web/API/Geolocation/getCurrentPosition
                  // 方法三个参数分别为： 正常拿到定位信息的回调，异常回调，方法参数(主要使用超时时间)
                  navigator.geolocation.getCurrentPosition((pos) => {
                    // 正常返回时
                    let { coords } = pos
                    resolve(coords)
                  }, () => {
                    // 处理异常返回
                    resolve({})
                  }, { timeout: 3000 })
                })
                position.lat = COORDS.latitude
                position.lng = COORDS.longitude
                GET_BROWSER_POSITION({
                  ...position
                })
              } catch (e) {}
            }
          }
        }
        // 访问capi接口进行反解
        try {
          if (position.lat && position.lng) {
            const ADDRESS_INFO = await Api.getAddress({ ...requireParams, poiLat: position.lat, poiLng: position.lng }) // 接口定义经度入参为poiLng，维度入参为poiLat
            country = (ADDRESS_INFO['data'] && ADDRESS_INFO['data']['countryCode']) || country
            GET_API_PARSED_COUNTRY({api_data_counrty: ADDRESS_INFO['data'] && ADDRESS_INFO['data']['countryCode']})
          }
        } catch (e) {}
      }
    }
    // 如果反解出国家码是BR，则重置为MX，避免出现didi品牌下出现99相关的样式和信息
    if (country === 'BR') {
      country = COUNTRY
    }
    GET_FINAL_COUNTRY({country})
    commit('UPDATE_COUNTRY', country)
  },
  // 获取已开城城市列表
  async GET_CITY_LIST ({ commit }, params) {
    await Api.feedUnOpened({}).then(res => {
      if (res?.errno === 0) {
        commit('UPDATE_OPENED_CITY_LIST', res.data && res.data.cities)
      }
    })
  }
}

const mutations = {
  /**
   * 更新国家参数
   */
  UPDATE_COUNTRY (state, country) {
    if (state.country !== 'BR') {
      state.country = country
    }
  },
  UPDATE_OPENED_CITY_LIST (state, payload) {
    state.openedCityList = payload
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
