export default {
  globalParams: {},
  acquisitionInfo: {},
  participateInfo: {},
  payInfo: {},
  selectedCard: {},
  extraInfoList: [],
  membership:{},
  brandName:'DiDi Pack',
  unSubscribeInfoData:{}
}
