<template>
  <div class="selected-main">
    <ImmersionHeader
      ref="ImmersionHeader"
      :isShowBackBtn="true"
      :blackTheme="true"
      @BackCK="BackCK"
    />
    <div
      v-if="!loading"
      class="refund-reason-container"
      :style="{ 'margin-top': headerHeight + 'px' }"
    >
      <div class="refund-reason-title">{{ $t('FoodC_unsubscription_xxOV_zoQL') }}</div>
      <div class="refund-reason-sub-title">{{ $t('FoodC_unsubscription_moTV_YOeR') }}</div>
      <div class="page-line"></div>
      <div class="reason-box">
        <div v-for="(reason, index) in reasonList" :key="index" class="reason-item">
          <div class="reason-header">
            <label class="reason-label">
              <span class="reason-label-text">{{ reason.label }}</span>
              <!-- 自定义的选中与未选中的样式 -->
              <span
                class="reason-radio-new"
                :class="{ 'reason-radio-new-check': selectedReason === reason.value }"
              ></span>
              <input
                v-model="selectedReason"
                type="radio"
                name="refundReason"
                :value="reason.value"
                class="reason-radio"
              />
            </label>
          </div>
          <rich-textarea-box
            v-if="selectedReason === reason.value"
            v-model="reason.detail"
            class="reason-textarea"
            maxlength="500"
            :placeholder="$t('FoodC_unsubscription_oSsY_xaBg')"
          ></rich-textarea-box>
        </div>
      </div>
      <div class="btn-container">
        <rich-button class="btn-goback-member" @click="goBackMember">
          {{ $t('FoodC_unsubscription_EyKD_bpPY', { brand: brandName }) }}
        </rich-button>
        <rich-button outline="true" @click="goNext">
          {{ $t('FoodC_unsubscription_tnHb_xMuO') }}
        </rich-button>
      </div>
    </div>
    <LoadingMask v-else />
  </div>
</template>

<script>
import ImmersionHeader from '@/components/immersionHeader/index.vue'
import LoadingMask from '@/components/loading/loading-mask.vue'
import { getUnsubscribeInfoDetail } from '@/api/member/index.js'
import { membershipSubscribeReasonSw, membershipSubscribeReasonCk } from '../../trackEvent.js'
import { mapState, mapMutations } from 'vuex'
import { shuffle } from 'lodash-es'
export default {
  components: {
    ImmersionHeader,
    LoadingMask,
  },
  data() {
    return {
      loading: false,
      headerHeight: 0,
      reasonList: [], // 退款原因
      selectedReason: '', // 取消原因说明
    }
  },
  computed: {
    ...mapState(['brandName']),
  },
  created() {
    this.getUnsubscribeInfo()
  },
  mounted() {
    this.headerHeight = this.$refs?.ImmersionHeader?.$el?.offsetHeight || 0
    membershipSubscribeReasonSw()
  },
  methods: {
    ...mapMutations(['SET_UNSUBSCRIBEINFO_DATA']),
    async getUnsubscribeInfo() {
      this.loading = true
      const res = await getUnsubscribeInfoDetail()
      this.SET_UNSUBSCRIBEINFO_DATA(res?.data) // 将数据存在store中
      const reasonArr = res?.data?.reasonList
      let lastItem = {}
      if (reasonArr?.length > 0) {
        lastItem = reasonArr.pop()
        this.reasonList = shuffle(reasonArr) // 打乱数组
        this.reasonList.push(lastItem) // 将最后一位插入回数组
      }
      this.loading = false
    },
    BackCK() {
      window.history.go(-1)
    },
    // 进入下一页
    goNext() {
      const selected = this.reasonList.find(reason => reason.value === this.selectedReason)
      if (selected) {
        const refundReason = {
          value: selected.value,
          reason: selected.label,
          detail: selected.detail,
        }
        this.membershipSubscribeReasonCkFn(1, refundReason.value, refundReason.detail)
        // 命中暂停实验(isPauseFlowVersion=1) & value = 4 跳转到暂停时间选择页面
        if (+this.params?.isPauseFlowVersion === 1 && +refundReason.value === 4) {
          setTimeout(() => {
            this.$router?.push({
              path: '/pauseSubscribe',
              query: {
                nativeWebUIType: 1,
                pauseSubscribeFrom: 'selectedReasonPage',
              },
            })
          }, 300)
          return
        }
        setTimeout(() => {
          this.$router?.push({
            path: '/retainMember',
            query: {
              nativeWebUIType: 1,
            },
          })
        }, 300)
      } else {
        const toast = this.$createToast({
          txt: this.$t('FoodC_unsubscription_tNxS_UdhI'),
          time: 2000,
          type: 'warn',
          mask: false,
        })
        toast.show()
      }
    },
    // 回到会员页面 「会员页 -> 管理页面 -> 取消原因选择页」
    goBackMember() {
      this.membershipSubscribeReasonCkFn(2)
      setTimeout(() => {
        this.$router.go(-2)
      }, 300)
    },
    // ck埋点上报
    membershipSubscribeReasonCkFn(type, reason = '', reason_write = '') {
      membershipSubscribeReasonCk({
        click_type: type,
        reason,
        reason_write,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.selected-main {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
}
.refund-reason-container {
  width: 100%;
  box-sizing: border-box;
  flex: 1;
  min-height: calc(100% - 348px);
  padding: 24px 32px;
  padding-bottom: 348px;
}
.refund-reason-title {
  overflow: hidden;
  line-height: 56px;
  font-family: DiDiSans-Pro-Bold;
  font-size: 48px;
  color: #000000;
  margin-bottom: 24px;
}
.refund-reason-sub-title {
  overflow: hidden;
  line-height: 33px;
  font-family: DiDiSans-Pro-Regular;
  font-size: 28px;
  color: #000000;
  letter-spacing: 0;
  margin-bottom: 64px;
}
.page-line {
  width: 100%;
  height: 1px;
  background: rgba(0, 0, 0, 0.08);
}
.reason-item {
  width: 100%;
  min-height: 110px;
  box-sizing: border-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}

.reason-header {
  display: flex;
  align-items: center;
}

.reason-label {
  height: 66px;
  margin: 32px 0;
  display: flex;
  align-items: center;
  flex-grow: 1;
}
.reason-label-text {
  line-height: 33px;
  font-family: DiDiSans-Pro-Regular;
  font-size: 28px;
  color: #000000;
  letter-spacing: 0;
}

.reason-radio {
  margin-left: auto;
}
.reason-textarea {
  margin: 12px 0 40px 0px;
}

/* 按钮样式 */
.btn-container {
  background-color: #ffffff;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 8;
  width: 100%;
  box-sizing: border-box;
  padding: 0 32px 45px;
  border-radius: 40px 40px 0 0;
  box-shadow: 0px -20px 40px 0px rgba(0, 0, 0, 0.04);
}
.btn-goback-member {
  margin: 24px 0;
}

/* 自定义radio的样式 */
.reason-label input[type='radio'] {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

.reason-radio-new {
  flex-shrink: 0;
  margin-left: auto;
  display: inline-block;
  width: 48px; /* 根据需要调整 */
  height: 48px; /* 根据需要调整 */
  background: url('../../../../assets/images/member/un-radio.png') no-repeat;
  background-size: contain; /* 按比例压缩显示，确保图片完整 */
  vertical-align: middle;
  margin-right: 5px; /* 根据需要调整 */
}

/* 当radio按钮被选中时，改变自定义radio按钮的外观 */
.reason-radio-new-check {
  background: url('../../../../assets/images/member/check-radio.png') no-repeat;
  background-size: contain; /* 按比例压缩显示，确保图片完整 */
}
</style>
